import React from "react"
import { Helmet } from "react-helmet"

const Facebook = ({ url, name, type, title, desc, children, locale }) => (
  <>
    <Helmet>
      {name && <meta property="og:site_name" content={name} />}
      <meta property="og:locale" content={locale} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      {children}
    </Helmet>
  </>
)

export default Facebook
