import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledGridParagraph = styled.section`
  display: grid;
  ${props =>
    props.gridPhone &&
    `grid-template-areas: ${
      props.flip || props.flipPhone
        ? `"${props.gridPhone
            .replace(/"/g, ``)
            .split(' ')
            .reverse()
            .join(' ')}"`
        : props.gridPhone
    };`};

  ${props => props.theme.media.tablet_portrait_up`
    ${props.gridTabletPortrait &&
      `grid-template-areas: ${
        props.flip || props.flipTabletPortrait
          ? `"${props.gridTabletPortrait
              .replace(/"/g, ``)
              .split(' ')
              .reverse()
              .join(' ')}"`
          : props.gridTabletPortrait
      };
      ${
        props.useFractions
          ? `grid-template-columns: repeat(${
              props.gridTabletPortrait.split(' ').length
            }, 1fr);`
          : ''
      }`};
  `}

  ${props => props.theme.media.tablet_landscape_up`
    ${props.gridTabletLandscape &&
      `grid-template-areas: ${
        props.flip || props.flipTabletLandscape
          ? `"${props.gridTabletLandscape
              .replace(/"/g, ``)
              .split(' ')
              .reverse()
              .join(' ')}"`
          : props.gridTabletLandscape
      };
      ${
        props.useFractions
          ? `grid-template-columns: repeat(${
              props.gridTabletLandscape.split(' ').length
            }, 1fr);`
          : ''
      }`};
  `}

  ${props => props.theme.media.desktop_up`
    ${props.gridDesktop &&
      `grid-template-areas: ${
        props.flip || props.flipDesktop
          ? `"${props.gridDesktop
              .replace(/"/g, ``)
              .split(' ')
              .reverse()
              .join(' ')}"`
          : props.gridDesktop
      };
      ${
        props.useFractions
          ? `grid-template-columns: repeat(${
              props.gridDesktop.split(' ').length
            }, 1fr);`
          : ''
      }
    `};
  `}

  ${props => props.theme.media.big_desktop_up`
    ${props.gridBigDesktop &&
      `grid-template-areas: ${
        props.flip || props.flipBigDesktop
          ? `"${props.gridBigDesktop
              .replace(/"/g, ``)
              .split(' ')
              .reverse()
              .join(' ')}"`
          : props.gridBigDesktop
      };
    ${
      props.useFractions
        ? `grid-template-columns: repeat(${
            props.gridBigDesktop.split(' ').length
          }, 1fr);`
        : ''
    }`};
  `}
`

function GridParagraph({ children, ...props }) {
  function anyGridIsInvalid(componentProps) {
    const {
      gridPhone,
      gridTabletPortrait,
      gridTabletLandscape,
      gridDesktop,
      gridBigDesktop,
    } = componentProps
    const grids = [
      gridPhone,
      gridTabletPortrait,
      gridTabletLandscape,
      gridDesktop,
      gridBigDesktop,
    ]
      .filter(Boolean) // Filter nulls
      .map(grid => {
        return (!grid.startsWith(`"`) || !grid.endsWith(`"`)) && grid
      })
      .filter(Boolean)
    if (grids.length > 0) {
      console.error(
        'Error: Some GridParagraph grid templates are invalid.',
        grids
      )
      return true
    }
    return false
  }

  // TODO: Write a flipResolver, that resolves whether or not the grid should flip, and return the correct grid, to simplify the logic of the Styled Component. Currently, passing "flip" causes gridPhone to act out, as that's usually multi-dimensional.

  if (anyGridIsInvalid(props)) return null
  return <StyledGridParagraph {...props}>{children}</StyledGridParagraph>
}

export default GridParagraph

GridParagraph.defaultProps = {
  gridPhone: null,
  gridTabletPortrait: null,
  gridTabletLandscape: null,
  gridDesktop: null,
  gridBigDesktop: null,
  useFractions: false,
  flip: false,
  flipPhone: false,
  flipTabletPortrait: false,
  flipTabletLandscape: false,
  flipDesktop: false,
  flipBigDesktop: false,
}
GridParagraph.propTypes = {
  gridPhone: PropTypes.string,
  gridTabletPortrait: PropTypes.string,
  gridTabletLandscape: PropTypes.string,
  gridDesktop: PropTypes.string,
  gridBigDesktop: PropTypes.string,
  useFractions: PropTypes.bool,
  flip: PropTypes.bool,
  flipPhone: PropTypes.bool,
  flipTabletPortrait: PropTypes.bool,
  flipTabletLandscape: PropTypes.bool,
  flipDesktop: PropTypes.bool,
  flipBigDesktop: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
