// Utility function to get TRBL shorthand declaration for paddings and margins.
export const getShorthand = (property, unit) => {
  let [t, r, b, l] = property

  if (property.length === 3) {
    // Not all sides are defined, vertical borders are the same
    l = r
  } else if (property.length === 2) {
    // Vertical and horizontal borders are the same
    l = r
    b = t
  } else if (property.length === 1) {
    // All borders are the same
    r = t
    b = t
    l = t
  }

  if (!unit) return [t, r, b, l]

  return `${t}${unit} ${r}${unit} ${b}${unit} ${l}${unit}`

}

// Function to consistently allow adding margins, borders and padding, without breaking vertical rhythm.
export const getBoxModel = (padding, border, margin) => {

  const style = []

  let subtractTop = 0, subtractBottom = 0

  if (border) {
    // Get borders from border argument (which is an array)
    // Follows the top, right, bottom, left css shorthand convention
    let [t, r, b, l] = getShorthand(border)
    style.push(`border-top: ${t}; border-right: ${r}; border-bottom: ${b}; border-left: ${l};`)
    // Calculate the amount of pixels to subtract from the margin, to adjust for borders.
    subtractTop = (parseInt(t.split(" ")[0].match(/-?\d+\.?\d*/)[0]) / 10).toFixed(2)
    subtractBottom = (parseInt(b.split(" ")[0].match(/-?\d+\.?\d*/)[0]) / 10).toFixed(2)
  }

  if (margin) {
    const [mT, mR, mB, mL] = getShorthand(margin) // Same as border
    style.push(`margin: ${mT - subtractTop}rem ${mR}rem ${mB - subtractBottom}rem ${mL}rem;`)
  }

  if (padding) {
    // Same as border
    let [pT, pR, pB, pL] = getShorthand(padding)
    style.push(`padding: ${pT}rem ${pR}rem ${pB}rem ${pL}rem;`)
  }

  return style.join("")
}
