import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Icon from "lib/Icon"
import Content from "components/Content"
import { htmlFooterSerializer } from "./helper"
import Rhombus from "components/Rhombus"
import { htmlSerializer } from "components/HeroSection"

const StyledUSP = styled.article`
  & + & {
    margin-top: 2rem;
  }

  h2 {
    font-size: 2.1rem;
    line-height: 3.2rem;
    margin: 0 0 1rem;
    font-family: ${(props) => props.theme.fontStacks.subheading};
    font-weight: 500;
  }

  p {
    margin: 0 0 1rem;
    font-size: 2.1rem;
    line-height: 3.2rem;
    font-family: ${(props) => props.theme.fontStacks.light};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    margin-top: 0;

    & + & {
      margin-top: 0;
    }
  `}
`

const StyledUSPFooter = styled.div`
  margin-top: 2rem;
  padding: 2rem 0;
  border-top: 1px solid ${(props) => props.theme.colors.grayLighter};

  h3 {
    font-size: 1.9rem;
    margin: 0 0 1rem;
    font-family: ${(props) => props.theme.fontStacks.subheading};
    font-weight: 500;
  }

  p {
    font-size: 1.7rem;
    line-height: 2.8rem;
  }
`

function USP({
  icon,
  title,
  usp_title,
  content,
  content_footer,
  children,
  ...props
}) {
  const renderTitle = title ?? usp_title
  return (
    <StyledUSP {...props}>
      {icon && (
        <Rhombus>
          <Icon icon={icon} />
        </Rhombus>
      )}
      {!!renderTitle?.richText.length && (
        <Content content={renderTitle.richText} serializer={htmlSerializer} />
      )}
      {!!content?.richText.length && (
        <Content content={content.richText} serializer={htmlSerializer} />
      )}
      {!!content_footer?.richText.length &&
        content_footer?.richText.some((x) => x.text !== "") && (
          <StyledUSPFooter>
            <Content
              content={content_footer.richText}
              serializer={htmlFooterSerializer}
            />
          </StyledUSPFooter>
        )}
      {children}
    </StyledUSP>
  )
}

export default USP

USP.defaultProps = {}
USP.propTypes = {}

export const query = graphql`
  fragment USPFragment on PrismicUsp {
    id
    data {
      title {
        richText
      }
      content {
        richText
      }
      content_footer {
        richText
      }
    }
  }
`
