import React, { useState } from "react"
import Marquee from "react-fast-marquee"
import styled from "styled-components"
import tw from "twin.macro"
import { TextLoop } from "@pr0gramm/react-text-loop"
import Content from "components/Content"
import ResponsiveImage from "components/ResponsiveImage"
import { Responsive, Tooltip } from "@clevertrack/shared"

const StyledPartnerRepeater = styled.div`
  padding: 2rem 0 4rem;
  text-align: center;
  overflow: hidden;

  h2 {
    display: inline;

    ${tw`text-4xl lg:(text-6xl)`}
  }

  p {
    max-width: 35rem;
    margin: auto;
  }

  .rfm-marquee-container {
    overflow-x: visible;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 8rem 0 8rem;
  `}
`

const LogoContainer = styled.div`
  margin-top: 2rem;
  height: 8rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    // width: 100%;
  `}
`

const StyledLogo = styled.div`
  transition: 200ms;
  padding: 2rem 4rem;
  transform: scale(0.95);
  filter: grayscale(1);
  height: 100%;

  &:hover {
    transform: scale(1);
    filter: grayscale(0);
    // transition: 200ms;
  }

  img {
    width: auto;
    height: 6rem;
  }
`

const StyledTooltip = styled(Tooltip)`
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
  ${tw`p-4 px-6 bg-white text-black text-xl`}
  left: 50%;
  bottom: auto;
  width: auto;
  transform: translate3d(-50%, -100%, 0);
  white-space: nowrap;

  p {
    ${tw`m-0`}
  }

  &:after {
    ${tw`border-t-white right-0 left-auto lg:(left-1/2)`}
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    left: 50%;
    max-width: none;
    transform: translate3d(-50%, -100%, 0);
  `}
`

function PartnerRepeater({ content, items, ...props }) {
  const {
    partners_title: title,
    shuffle_text,
    content: partner_content,
  } = content

  const [showTooltipID, setShowTooltipID] = useState(null)

  const onEnterTooltipHandler = (e, key) => {
    setShowTooltipID(key)
  }

  const onLeaveTooltipHandler = (e) => {
    setShowTooltipID(null)
  }

  const shuffleItems = shuffle_text ? shuffle_text.split(",") : []

  return (
    <StyledPartnerRepeater {...props}>
      <div tw="flex flex-col items-center text-center w-full">
        {title && title.text && <Content content={title.richText} />}
        {shuffleItems.length > 0 && (
          <h2 tw="text-brand-500 -mt-8 lg:(ml-4)">
            <Responsive
              phone={
                <TextLoop mask={true} noWrap={true}>
                  {shuffleItems.map((x) => (
                    <span key={x.split(" ").join("_")}>{x}</span>
                  ))}
                </TextLoop>
              }
              tabletLandscape={
                <TextLoop mask={true} noWrap={true}>
                  {shuffleItems.map((x) => (
                    <span key={x.split(" ").join("_")}>{x}</span>
                  ))}
                </TextLoop>
              }
            />
          </h2>
        )}
      </div>
      <Content content={partner_content.richText} />
      <LogoContainer>
        <Marquee autoFill>
          {items.map((item, i) => {
            const { partner_name, partner_link, partner_logo_image } = item
            return (
              <StyledLogo
                key={partner_name}
                onMouseEnter={(e) => onEnterTooltipHandler(e, `image_${i}`)}
                onMouseLeave={onLeaveTooltipHandler}
                tw="relative"
              >
                <StyledTooltip toggled={showTooltipID === `image_${i}`}>
                  {partner_name}
                </StyledTooltip>
                <a
                  href={partner_link?.url}
                  target="_blank"
                  tw="cursor-pointer"
                  rel="noreferrer noopener"
                >
                  <ResponsiveImage img={partner_logo_image} />
                </a>
              </StyledLogo>
            )
          })}
        </Marquee>
      </LogoContainer>
    </StyledPartnerRepeater>
  )
}

export default PartnerRepeater

PartnerRepeater.defaultProps = {}
PartnerRepeater.propTypes = {}
