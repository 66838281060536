import { Card } from "@clevertrack/shared"
import Container from "components/Container"
import Content from "components/Content"
import { htmlSerializer } from "components/HeroSection"
import Image from "components/Image"
import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

const StyledClientQuotations = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 8rem;
  padding: 4rem 2rem;

  ${(props) => props.theme.media.tablet_portrait_up`
    padding: 4rem 4rem;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 3rem;
    row-gap: 6rem;
    justify-content: center;

    > article {
      flex: 0 1 47%;
    }
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    display: grid;
    padding: 4rem 4rem;
    grid-template-columns: 1fr 1fr 1fr;

    row-gap: 3rem;
  `}

  ${(props) => props.theme.media.desktop_up`
    padding: 4rem 0;
  `}
`

const StyledClientQuotationItem = styled(Card)`
  position: relative;
  padding-top: 4.8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${tw`border border-0 border-solid border-b-4 border-brand-500`}
  .logo {
    width: 9.6rem;
    height: 9.6rem;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, -61%, 0);
    ${tw`p-6 rounded-full overflow-hidden shadow-mapButton bg-white`}

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .photo {
    width: 10rem;
    height: 10rem;
    display: block;
    ${tw`rounded-full overflow-hidden shadow-mapButton`}

    img {
      width: 100%;
      height: 100%;
      object-fit: contain !important;
    }
  }

  .header {
    ${tw`text-center text-2xl pb-8 relative`}
    font-family: ${(props) => props.theme.fontStacks.heading};

    &:after {
      position: absolute;
      width: 4rem;
      height: 4px;
      content: "";
      left: 50%;
      bottom: 0;
      transform: translate3d(-50%, 0, 0);
      ${tw`block absolute bg-brand-500`}
    }
  }

  .quote {
    ${tw`py-8 text-4xl leading-relaxed italic`}
    &:before {
      content: "“";
    }

    &:after {
      content: "”";
    }
  }

  .footer {
    display: flex;
    align-items: center;
    ${tw`text-2xl pt-8 relative`}

    /* &:before {
      position: absolute;
      width: 4rem;
      height: 2px;
      content: "";
      left: 50%;
      top: 0;
      transform: translate3d(-50%, 0, 0);
      ${tw`block absolute bg-brand-500`}
    } */

    .photo {
      margin-right: 2rem;
    }

    .name {
      font-family: ${(props) => props.theme.fontStacks.body};
      ${tw`text-3xl`}
    }

    .position {
    }
  }
`

export const ClientQuotation: React.FC = ({ items, headline, ...props }) => {
  const renderItem = ({
    client_company,
    client_logo,
    client_photo,
    client_name,
    client_quotation,
    client_position,
  }) => {
    return (
      <StyledClientQuotationItem size="sm">
        <div className="logo">
          <img src={client_logo.fixed.src} />
        </div>
        <div className="header">
          <div className="company">{client_company}</div>
        </div>
        <div className="quote">{client_quotation}</div>
        <div className="footer">
          <div className="photo">
            <img src={client_photo.fixed.src} />
          </div>
          <div>
            <div className="name">{client_name}</div>
            <div className="position">{client_position}</div>
          </div>
        </div>
      </StyledClientQuotationItem>
    )
  }
  return (
    <Container>
      <div tw="text-center mt-8 mb-16">
        <Content content={headline.richText} htmlSerializer={htmlSerializer} />
      </div>
      <StyledClientQuotations>
        {items.map(
          ({
            client_quotation: {
              document: { id, data },
            },
          }) => {
            return <article key={id}>{renderItem(data)}</article>
          }
        )}
      </StyledClientQuotations>
    </Container>
  )
}

export const query = graphql`
  fragment ClientQuotationFragment on PrismicClientQuotation {
    id
    data {
      client_company
      client_logo {
        fixed(width: 200) {
          src
        }
      }
      client_name
      client_photo {
        fixed(width: 500) {
          src
        }
      }
      client_position
      client_quotation
    }
  }
`
