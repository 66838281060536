import React from "react"
import styled from "styled-components"
import { siteTheme as theme } from "theme"

const StyledTag = styled.small<any>`
  display: flex;
  align-items: center;
  padding: 0.3rem 0.6rem;
  font-size: 1rem;
  line-height: 1.2;
  margin-left: 0.4rem;
  font-weight: bold;
  background: ${(props) => theme.tags[props.variant || "default"].background};
  color: ${(props) => theme.tags[props.variant || "default"].color};

  svg {
    margin-left: 0.5rem;
  }
`

function Tag({ show, title, type, children, ...props }) {
  if (!show) return null
  return (
    <StyledTag {...props}>
      {title}
      {children}
    </StyledTag>
  )
}

export { Tag }

/* Tag.defaultProps = {
  show: false,
  variant: "default",
}

Tag.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(["danger", "success", "info", "warning", "default"]),
} */
