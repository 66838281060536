import React from "react"
import PropTypes from "prop-types"
import Hubspot from "./Hubspot"

function SliceZone({ ...props }) {
  const { slices, meta } = props
  if (!slices || slices === null) return null
  const slice = slices.map((s, index) => {
    switch (s.slice_type) {
      case "hubspot":
        return (
          <Hubspot
            key={`${s.slice_type}_${index}`}
            meta={meta}
            {...props}
            {...s.primary}
          />
        )
      default:
        console.warn(`No support for slice type ${s.slice_type}`)
        return null
    }
  })
  return <>{slice}</>
}

SliceZone.propTypes = {
  slices: PropTypes.array.isRequired,
}

export default SliceZone
