import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from "lib/Button"
import Icon from "lib/Icon"

const StyledNavToggle = styled(Button)`
  background: none;
  padding: 1rem;
  color: ${(props) => props.theme.baseBodyColor};
  border: 1px solid currentColor;
`

function NavigationToggle({
  children,
  withIcon,
  withText,
  onToggle,
  ...props
}) {
  function renderInnerNavToggle() {
    if (withIcon !== null && withText !== null)
      return (
        <>
          <span>{withText}</span>
          <Icon icon={withIcon} />
        </>
      )
    else if (withIcon === null && withText !== null)
      return <span>{withText}</span>
    else if (withIcon !== null && withText === null)
      return <Icon icon={withIcon} />
    else return <Icon icon="menu" />
  }

  return (
    <StyledNavToggle
      type="button"
      icon={props.icon || (withIcon === null && withText === null)}
      onClick={onToggle}
      {...props}
    >
      {children ? children : renderInnerNavToggle()}
    </StyledNavToggle>
  )
}

export default NavigationToggle

NavigationToggle.defaultProps = {
  withIcon: null,
  withText: null,
  children: null,
}

NavigationToggle.propTypes = {
  withIcon: PropTypes.string,
  withText: PropTypes.string,
  children: PropTypes.node,
  onToggle: PropTypes.func.isRequired,
}
