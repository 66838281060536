import styled, { keyframes } from "styled-components"

const Pulse = keyframes`
  to {
    transform: scale(2.24);
    background: transparent;
    border-color: transparent;
  }
`

export const Radar = styled.div`
  transform: translateY(-50%);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;

  &:before {
    background: currentColor;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    transform: scale(0.8);
    border: 1px solid currentColor;
    content: "";
    animation: 1.7s ${Pulse} infinite cubic-bezier(0, 0, 0.14, 1);
  }

  &:after {
    background: currentColor;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    transform: scale(0.6);
    content: "";
  }
`
