import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"
import { Elements } from "prismic-reactjs"
import { default as DefaultGridParagraph } from "lib/Paragraphs/GridParagraph"
import Content from "components/Content"
import Container from "components/Container"
import CTARepeater from "components/CTA/Repeater"
import ResponsiveImage from "components/ResponsiveImage"
import Label from "components/Label"
import { getIconFromUrl } from "utils/iconResolver"
import { linkResolver } from "utils/linkResolver"
import { Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"
import { Link } from "gatsby"
import { colorThemes } from "theme/colors"
import { HeroV2Section } from "./HeroV2"

const StyledHeroSection = styled.section`
  min-height: 80vh;
  padding: 0 0 4rem;
  margin-top: 3rem;
  position: relative;
  ${(props) => props.theme.media.tablet_landscape_up`
    margin: 2rem 2rem;
    padding: 4rem 2rem 8rem;
    min-height: 50vh;
    z-index: 50;
  `}
  ${(props) => props.theme.media.desktop_up`
    padding: 4rem 2rem 8rem;
  `};
`

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  ${(props) => props.theme.media.tablet_landscape_up`
    flex-direction: row;
    align-items: center;
  `}
`

const StyledHeadline = styled.h1`
  // margin-top: 0;

  span {
    ${tw`text-brand-500`}
  }
  ${(props) => props.theme.media.tablet_landscape_up`
    line-height: 1.3;
  `}
`

export const StyledHyperlink = styled.a`
  ${tw`text-brand-500`}
  p & {
    ${tw`inline-flex items-center`}
  }

  svg {
    ${tw`ml-4`}
  }
`

const StyledImageContainer = styled.div`
  position: relative;
  z-index: 10;
  ${(props) => props.theme.media.tablet_landscape_up`
    flex: 0 1 50%;
  `}
`

const StyledResponsiveImage = styled(ResponsiveImage)`
  transform-origin: 50%;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);

  ${(props) => props.theme.media.tablet_landscape_up`
    transform-origin: top left;
    // transform: scale3d(0.95, 0.95, 0.95) translate3d(-3rem, 3rem, 0);
    opacity: 1;
  `}
`

const StyledImage = styled.div`
  position: relative;
  z-index: 10;
`

const StyledBullet = styled.li`
  // margin: 0 0 0.5rem;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  line-height: 1.6;

  span {
    width: 2.2rem;
    height: 2.2rem;
    position: relative;
    border-radius: 50%;
    text-align: center;
    margin-right: 1rem;
    margin-top: 0.3rem;
    flex: 0 0 auto;
    align-self: flex-start;
    svg {
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      position: absolute;
      z-index: 20;
      fill: currentColor;
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`

  `}
`

const StyledContentContainer = styled.div`
  align-self: center;
  position: relative;
  z-index: 50;
  ${tw`shadow-card bg-white`}
  padding: 2rem;
  margin: -80% 2rem 0;
  z-index: 20;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 4rem;
    width: 60%;
    margin-top: 0;
    margin-right: -4rem;
    margin-left: 0;
  `}

  p {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-family: ${(props) => props.theme.fontStacks.light};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    &:only-child {
      margin: 0 auto;
      text-align: center;
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    margin-left: -4rem;
  `};
`

export const htmlSerializer = (type, element, content, children) => {
  let childrenMap
  switch (type) {
    case Elements.heading1:
      childrenMap = children
        .flatMap((x) => x)
        .map(
          (x) =>
            typeof x === "string" &&
            x.replace("*", "<span>").replace("*", "</span>")
        )
        .filter(Boolean)
        .join(" ")
      return (
        <StyledHeadline
          key="h1"
          dangerouslySetInnerHTML={{ __html: childrenMap }}
        />
      )
    case Elements.heading2:
      childrenMap = children
        .flatMap((x) => x)
        .map(
          (x) =>
            typeof x === "string" &&
            x.replace("*", "<span>").replace("*", "</span>")
        )
        .filter(Boolean)
        .join(" ")
      return (
        <StyledHeadline
          key="h2"
          as="h2"
          dangerouslySetInnerHTML={{ __html: childrenMap }}
        />
      )
    case Elements.heading3:
      childrenMap = children
        .flatMap((x) => x)
        .map(
          (x) =>
            typeof x === "string" &&
            x.replace("*", "<span>").replace("*", "</span>")
        )
        .filter(Boolean)
        .join(" ")
      return (
        <StyledHeadline
          key="h1"
          as="h3"
          dangerouslySetInnerHTML={{ __html: childrenMap }}
        />
      )
    case Elements.hyperlink:
      if (element.data?.type === "page") {
        const url = linkResolver(element.data)
        return (
          <StyledHyperlink as={Link} to={url}>
            {content}
            <Icon icon="long-arrow-right" />
          </StyledHyperlink>
        )
      }
      return (
        <StyledHyperlink
          href={element.data?.url}
          rel="noopener"
          target="_blank"
        >
          {content}
        </StyledHyperlink>
      )
    default:
      return null
  }
}

export const bulletsSerializer = (
  type,
  element,
  content,
  children,
  color,
  bgColor,
  icon
) => {
  switch (type) {
    case Elements.list:
      return <ul tw="list-none px-2 md:(px-8)">{children}</ul>
    case Elements.listItem:
      return (
        <StyledBullet>
          <span css={{ "background-color": bgColor, color }}>
            <Icon icon={icon} size={IconSizeEnum.SM} />
          </span>
          {children}
        </StyledBullet>
      )
    default:
      return htmlSerializer(type, element, content, children)
  }
}

const gridPhone = `"content"`
const gridTabletPortrait = `"content content content . image image image"`
const gridFull = `". content content content content content ."`

function HeroSection({ isHome, ctas, ...props }) {
  const {
    theme,
    content,
    bullets,
    bullet_color,
    bullet_background_color,
    bullet_icon_alt,
    headline,
    image,
    pre_headline,
    background_image,
    background_video,
  } = props
  const icon = getIconFromUrl(bullet_icon_alt?.url)
  return (
    <HeroV2Section
      content={content}
      {...{
        pre_headline,
        headline,
        bullets,
        bullet_color,
        bullet_background_color,
        background_image,
        image,
        ctas,
        theme,
        icon,
        background_video,
      }}
    />
  )
  return (
    <StyledHeroSection
      gridPhone={gridPhone}
      gridTabletLandscape={!image ? gridFull : gridTabletPortrait}
      useFractions
      css={colorThemes[theme]}
    >
      <Container>
        <StyledContainer>
          <StyledContentContainer>
            <Label>
              <span>{pre_headline}</span>
            </Label>
            <Content content={headline.richText} serializer={htmlSerializer} />
            <Content content={content.richText} serializer={htmlSerializer} />
            <Content
              content={bullets.richText}
              serializer={(type, element, c, children) =>
                bulletsSerializer(
                  type,
                  element,
                  c,
                  children,
                  bullet_color,
                  bullet_background_color,
                  icon
                )
              }
            />
            <CTARepeater start items={ctas} />
          </StyledContentContainer>
          {image && (
            <StyledImageContainer>
              <StyledImage>
                <StyledResponsiveImage img={image} />
              </StyledImage>
            </StyledImageContainer>
          )}
        </StyledContainer>
      </Container>
    </StyledHeroSection>
  )
}

export default HeroSection

HeroSection.defaultProps = {}
HeroSection.propTypes = {
  children: PropTypes.node,
}
