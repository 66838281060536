import { useEffect, useState, useRef } from "react"

export default function useChildrenInFocus(ref) {
  const [childHasFocus, setChildHasFocus] = useState(false)
  const childElementListeners = useRef([])

  const onChildElementFocusHandler = (e) => {
    // If the documents active element is a child of the passed parent Ref, set the state to true
    setChildHasFocus(
      ref.current && ref.current.contains(document.activeElement)
    )
  }

  useEffect(() => {
    // Ref is a parent element containing focusable inputs.
    if (ref.current) {
      // Select all input children of parent ref.
      const inputs = ref.current.querySelectorAll("input, textarea")
      // Assign an eventlistener to each input, and add the listener to an array of tuples containing a reference to the input on the first position, and the listener on the second position.
      for (const input of inputs) {
        childElementListeners.current.push([
          input,
          input.addEventListener("focus", onChildElementFocusHandler),
          input.addEventListener("blur", onChildElementFocusHandler),
        ])
      }
    }

    return () => {
      // Unsubscribe all listeners on unmount
      for (const [input, eventListener] of childElementListeners.current) {
        input.removeEventListener("focus", eventListener)
        input.removeEventListener("blur", eventListener)
      }
    }
  }, [ref])

  // Return the state, and the setState function
  return [childHasFocus, setChildHasFocus]
}
