import React from "react"
import styled from "styled-components"
import { lighten, desaturate } from "polished"
import tw from "twin.macro"
import Content from "components/Content"
import Image from "components/Image"
import Container from "components/Container"
import { Elements } from "prismic-reactjs"

const bgc = (c) => desaturate(0.3, lighten(0.09, c))

const StyledPost = styled.section`
  position: relative;
  height: 100%;
  overflow: hidden;
  // margin-top: 2rem;

  .heroimg {
    left: 0;
    position: absolute;
    z-index: 5;
    width: 100%;
    // height: 32rem;
    background: black;
    ${(props) => props.theme.media.tablet_landscape_up`
      height: 32rem;
      width: 100%;
      // max-height: 40rem;
    `}
  }
`

const StyledHeroImage = styled(Image)`
  height: 100%;
`

const HeroContent = styled(Container)`
  position: relative;
  /* background: rgba(255, 255, 0, 0.2); */
  padding: 2rem;
  z-index: 10;
  // display: grid;
  // align-items: center;
  color: white;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 2rem;

    .inner {
      display: flex;
      margin-top: 8rem;
      .sidebar {
        order: 0;
        flex: 0 0 30rem;
      }
      .content {
        order: 1;
        flex: 1 0 auto;
        padding-left: 2rem;
      }
    }
  `}

  .hero-title-area {
    max-width: 80rem;
    .pub-date {
      font-family: ${(props) => props.theme.fontStacks.bold};
      padding-bottom: 2rem;
      font-size: ${(props) => props.theme.fontSizes.h6};
    }
    .hero-title {
      max-width: 70rem;
      padding-right: 2rem;
      > * {
        font-family: ${(props) => props.theme.fontStacks.bold};
        font-size: 2.4rem;
        line-height: 1.5;
        ${(props) => props.theme.media.tablet_landscape_up`
          font-size: 4.5rem;
          line-height: 5rem;
        `}
      }
    }
    .tags {
      /* background: rgba(0, 0, 255, 0.25); */
      display: inline-block;
      margin-top: 1rem;
      ${(props) => props.theme.media.tablet_landscape_up`
        margin-top: 2rem;
      `}
    }
  }
`

const SideGradient = styled.div`
  background-image: linear-gradient(
    90deg,
    ${(props) => bgc(props.theme.colors.primary)} 5%,
    ${(props) => bgc(props.theme.colors.primary) + (220).toString(16)} 40%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 7;
`

const NormalizedContent = styled.div`
  > * {
    margin: 0;
  }
`

function Post({ post, ...props }) {
  const { hero_image } = post.data

  const htmlSerializer = (type, element, content, children, key) => {
    switch (type) {
      case Elements.heading2:
        return <h1>{children}</h1>
      default:
        return null
    }
  }

  return (
    <StyledPost {...props}>
      <div className="heroimg">
        <StyledHeroImage fluid={hero_image?.fluid} />
      </div>
      <SideGradient />
      <HeroContent>
        <div className="sidebar" />
        <div className="content">
          <div className="hero-title-area">
            <NormalizedContent className="hero-title">
              <Content
                content={post.data.title.richText}
                serializer={htmlSerializer}
              />
            </NormalizedContent>
          </div>
        </div>
      </HeroContent>
    </StyledPost>
  )
}

export default Post

Post.defaultProps = {}
Post.propTypes = {}
