import React, { useContext, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { scrollElementIntoView } from "components/CTA/helper"
import Tag from "../Tag"
import ResponsiveImage from "components/ResponsiveImage"
import { getPubDate } from "../util"
import { AppContext } from "context"
import { Link } from "gatsby"
import { Icon, Responsive } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"

const StyledSidebar = styled.div`
  /* background: rgba(0, 255, 0, 0.1); */
  position: relative;
  padding-right: 4rem;

  .sticky {
    /* background: rgba(0, 0, 255, 0.1); */
    position: sticky;
    top: 14rem;
  }

  .author {
    .author_img {
      border-radius: 50%;
      overflow: hidden;
      width: 6.4rem;
      height: 6.4rem;
      img {
        filter: grayscale(1);
      }
    }
  }

  .tags {
  }
`

function Sidebar({ toc, post, readingTime, ...props }) {
  const {
    state: { currentBlogArticleSectionID },
  } = useContext(AppContext)
  const handleClick = (e, entry) => {
    scrollElementIntoView(e, `#${entry}`, 200)
  }

  let author

  if (post && post?.data.author) {
    author = post.data.author?.document.data
  }

  return (
    <StyledSidebar {...props}>
      <div className="sticky">
        <Link
          to="/viden"
          tw="flex items-center text-xl mb-8 hover:text-brand-500 transition-all"
        >
          <Icon icon="chevron-left" size={IconSizeEnum.SM} tw="-mt-px" />
          <span tw="ml-2">Tilbage</span>
        </Link>
        {author && (
          <div tw="flex items-center mb-16" className="author">
            <div className="author_img" tw="flex-grow-0 flex-shrink-0 mr-8">
              <ResponsiveImage img={author.image} />
            </div>
            <div tw="m-0">
              <span tw="block text-lg">Skrevet af</span>
              <span tw="mr-2 font-bold">{`${author.first_name} ${author.last_name}`}</span>
            </div>
          </div>
        )}
        {post?.data && (
          <div
            tw="my-8 py-8 border border-solid border-brand-gray-light border-l-0 border-r-0"
            className="tags"
          >
            {readingTime && (
              <span tw="block text-lg mb-2">
                <Icon icon="clock" tw="mr-2 w-2 h-2" size={IconSizeEnum.SM} />
                {`Læsetid ca. ${Math.ceil(readingTime.minutes).toFixed(
                  0
                )} min.`}
              </span>
            )}
            <span tw="block text-lg mb-4">
              {`Udgivet ${
                post.data.publication_date &&
                getPubDate(post.data.publication_date)
              } i`}
            </span>
            {post.tags.map((tag, i) => {
              return (
                <Tag
                  tw="text-lg bg-brand-500 text-white p-1 px-2 mb-1"
                  key={`post_tags_${i}`}
                  filled
                >
                  #{tag}
                </Tag>
              )
            })}
          </div>
        )}
        {toc && toc.length > 0 && (
          <div tw="border-solid border-0 border-b border-brand-gray-light pb-4 mb-4 md:(border-none)">
            <Responsive
              phone={
                readingTime && (
                  <span tw="block text-lg mb-2">
                    <Icon
                      icon="clock"
                      tw="mr-2 w-2 h-2"
                      size={IconSizeEnum.SM}
                    />
                    {`Læsetid ca. ${Math.ceil(readingTime.minutes).toFixed(
                      0
                    )} min.`}
                  </span>
                )
              }
              tabletLandscape={<></>}
            />
            <h4 tw="m-0">I denne artikel</h4>
            <ol tw="m-0 p-0 list-inside text-xl">
              {toc.map((x, i) => (
                <li
                  key={x.entry}
                  tw="py-2 transition-all cursor-pointer hover:(text-brand-500)"
                  onClick={(e) => handleClick(e, x.entry)}
                  css={
                    currentBlogArticleSectionID === x.entry
                      ? [tw`text-brand-500`]
                      : []
                  }
                >
                  {x.text}
                </li>
              ))}
            </ol>
          </div>
        )}
      </div>
    </StyledSidebar>
  )
}

export default Sidebar

Sidebar.propTypes = {}
