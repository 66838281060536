import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"
import Container from "components/Container"
import FAQ from "./index"

const StyledFaqRepeater = styled.div`
  padding-top: 4rem;
  padding-bottom: 8rem;
  margin: auto 2rem;
  ${(props) => props.theme.media.tablet_landscape_up`
    margin: auto 2rem;
  `}
`

const StyledParagraph = styled.article`
  font-family: ${(props) => props.theme.fontStacks.light};
  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: 85%;
  `}
`

const FAQList = styled.section``

function FaqRepeater({ content, items, ...props }) {
  const { faq_repeater_title, faq_repeater_content } = content
  const [toggled, setToggled] = useState(null)

  return (
    <Container>
      <StyledFaqRepeater {...props}>
        <Content content={faq_repeater_title.richText} />
        {!!faq_repeater_content?.richText && (
          <StyledParagraph>
            <Content content={faq_repeater_content.richText} />
          </StyledParagraph>
        )}
        <FAQList>
          {items.map((faq, i) => {
            return (
              <FAQ
                data={faq.faq_link}
                toggled={toggled === i}
                onClick={() => setToggled(toggled !== i ? i : null)}
                key={faq.faq_link.document.id}
              />
            )
          })}
        </FAQList>
      </StyledFaqRepeater>
    </Container>
  )
}

export default FaqRepeater

FaqRepeater.defaultProps = {}
FaqRepeater.propTypes = {}
