import React from "react"
import tw from "twin.macro"
import styled, { css } from "styled-components"
import DefaultInput from "lib/Input"
import { siteTheme as theme } from "theme"

const StyledInput = styled(DefaultInput)<IInputProps>`
  box-shadow: none;
  transition: all ease-out 0.15s;
  &:focus {
    outline: 0;
    ${(props) =>
      props.focusShadow &&
      `
      box-shadow: ${theme.inputFocusShadow};
    `};
  }

  ${(props) => {
    switch (props.variant) {
      case InputVariantEnum.PREPEND:
        return css`
          ${tw`border-r-0 pr-0 bg-white`}

          &[readOnly] {
            ${tw`bg-white`}
          }

          ~ input {
            ${tw`border-l-0 pl-0`}
          }
        `
      case InputVariantEnum.APPEND:
        return css`
          ${tw`border-l-0 bg-white pl-0`}

          &[readOnly] {
            ${tw`bg-white`}
          }

          ~ input {
            ${tw`border-l-0 pl-0`}
          }
        `
      default:
        return css``
    }
  }}
`

export enum InputVariantEnum {
  PREPEND = "prefix",
  APPEND = "append",
}

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: InputVariantEnum
  focusShadow?: boolean
}

const Input: React.FC<IInputProps> = ({ ...props }) => {
  return props.type === "password" ? (
    <StyledInput {...props} />
  ) : (
    <StyledInput className={[props.className, "data-hj-allow"]} {...props} />
  )
}

export { Input }
