import React from "react"
import { graphql } from "gatsby"
import NavList from "./NavList"

function NavigationList({ nav }) {
  const { navigation_items } = nav.document.data
  return <NavList top_level items={navigation_items} />
}

export default NavigationList

export const query = graphql`
  fragment SiteNavigation on PrismicSiteSettings {
    data {
      main_navigation {
        document {
          ... on PrismicNavigation {
            data {
              navigation_items {
                navigation_children {
                  link_type
                  document {
                    ... on PrismicNavigation {
                      prismicId
                      id
                      lang
                      type
                      data {
                        navigtion_title {
                          richText
                        }
                        navigation_items {
                          navigation_link {
                            document {
                              ... on PrismicPage {
                                uid
                              }
                            }
                          }
                          tag
                        }
                      }
                    }
                  }
                }
                navigation_link {
                  document {
                    ... on PrismicPage {
                      data {
                        title {
                          richText
                          text
                        }
                      }
                      prismicId
                      id
                      lang
                      uid
                      type
                    }
                  }
                }
                navigation_link_text
                tag
              }
            }
          }
        }
      }
    }
  }
`

// vvvvvvvvvvvv
// LEGACY QUERY --------------------------------
// vvvvvvvvvvvv
//
// export const query = graphql`
//   fragment SiteNavigation on PRISMIC_Site_settings {
//     main_navigation {
//       ... on PRISMIC_Navigation {
//         navigation_items {
//           navigation_children {
//             _linkType
//             ... on PRISMIC_Navigation {
//               navigtion_title
//               _meta {
//                 id
//                 lang
//                 type
//               }
//               navigation_items {
//                 navigation_link {
//                   ... on PRISMIC_Page {
//                     _meta {
//                       uid
//                     }
//                   }
//                 }
//               }
//             }
//           }
//           navigation_link {
//             ... on PRISMIC_Page {
//               title
//               _meta {
//                 id
//                 lang
//                 uid
//                 type
//               }
//             }
//           }
//           navigation_link_text
//         }
//       }
//     }
//   }
// `
