import { graphql } from "gatsby"
import React, {
  RefObject,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import tw from "twin.macro"
import styled, { keyframes } from "styled-components"
import { Desktop } from "./Devices/desktop"
import { Tablet } from "./Devices/tablet"
import { Phone } from "./Devices/phone"
import { htmlSerializer } from "components/HeroSection"
import Content from "components/Content"
import { useScrollPosition } from "hooks/useScrollPosition"
import { animated, useSpring } from "@react-spring/web"
import { AppContext } from "context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { Icon } from "@clevertrack/shared"

const StyledProductPresentation = styled.section`
  background: #fff;
  position: relative;
  // z-index: 10;
  display: grid;
  grid-template-rows: 6rem 8rem 1fr;
  min-height: 50vh;

  ${(props) => props.theme.media.tablet_landscape_up`
    grid-template-rows: 8rem 6rem 1fr;
    row-gap: 2rem;
    min-height: 0;
    margin-bottom: 4rem;

    // min-height: 100vh;
  `}

  .header {
    text-align: center;

    h2 {
      font-size: 2.4rem;
      margin-top: 2rem;
      ${(props) => props.theme.media.tablet_landscape_up`
        font-size: 3.6rem;
        // margin-top: 2rem;
      `}
      ${(props) => props.theme.media.desktop_up`
        font-size: 4.8rem;
      `}
    }
  }

  .inner {
    height: 100%;
    width: 100%;
    align-self: center;
    padding-bottom: 8rem;
    min-height: 70vh;
    &.horizontal {
      max-width: 90%;
      margin: 0 auto;
      padding-bottom: 26rem;
      // transform: translate3d(0, -50%, 0);

      ${(props) => props.theme.media.tablet_portrait_up`
        transform: translate3d(0, 0, 0);
        padding-bottom: 20rem;
      `}

      @media (min-width: 600px) and (orientation: landscape) {
        padding-bottom: 6rem;
      }

      ${(props) => props.theme.media.tablet_landscape_up`
        padding-bottom: 6rem;
      `}

      > div {
        max-width: 100%;

        ${(props) => props.theme.media.tablet_landscape_up`
          max-width: 65%;
        `}

        ${(props) => props.theme.media.desktop_up`
          max-width: 50%;
        `}
      }
    }

    > div {
      max-width: 75%;
      margin: 0 auto;

      ${(props) => props.theme.media.tablet_portrait_up`
        max-width: 50%;
      `}

      ${(props) => props.theme.media.tablet_landscape_up`
        max-width: 28rem;
      `}

      ${(props) => props.theme.media.desktop_up`
        max-width: 15vw;
      `}
    }
  }

  img {
    margin: 0 auto;
    max-width: 100%;
    ${(props) => props.theme.media.tablet_landscape_up`
      max-width: 100%;
      transform: none;
    `}

    &.desktop {
      width: 460px;
      ${(props) => props.theme.media.tablet_portrait_up`
      `}

      ${(props) => props.theme.media.tablet_landscape_up`
        width: auto;
        height: 100%;
      `}
    }

    &.tablet {
      width: 460px;
      ${(props) => props.theme.media.tablet_portrait_up`
      `}

      ${(props) => props.theme.media.tablet_landscape_up`
        width: auto;
        height: 100%;
      `}
    }

    &.phone {
      width: 220px;
      ${(props) => props.theme.media.tablet_portrait_up`
        margin-top: 0;
        width: auto;
        height: 100%;
      `}

      ${(props) => props.theme.media.tablet_landscape_up`
        width: auto;
        height: 100%;
      `}
    }
  }
`

const EnterScene = keyframes`
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`

const StyledDeviceSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${tw`space-x-8 py-8 mb-8`}

  ${(props) => props.theme.media.tablet_landscape_up`

  `}

  ${(props) => props.theme.media.desktop_up`

  `}

  &.visible span {
    animation: ${EnterScene} cubic-bezier(0.03, 0.9, 0.41, 1) 400ms forwards;
  }

  span {
    width: 4rem;
    height: 4rem;
    position: relative;
    display: block;
    cursor: pointer;
    opacity: 0;
    transform: translate3d(0, -20%, 0);

    &:nth-child(1) {
      animation-delay: 400ms;
    }

    &:nth-child(2) {
      animation-delay: 600ms;
    }
    &:nth-child(3) {
      animation-delay: 800ms;
    }

    &:after {
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      content: "";
      opacity: 0;
      display: block;
      top: 5rem;
      left: 50%;
      transition: all ease-out 200ms;
      transform: translate3d(-50%, 0, 0);
      ${tw`bg-brand-500`}
    }

    &:hover,
    &.active {
      &:after {
        opacity: 1;
      }
    }

    svg {
      width: 4rem;
      height: 4rem;
    }
  }
`

type ProductPresentationProps = {
  paragraph_title: any
}

export const ProductPresentation: React.FC<ProductPresentationProps> = ({
  paragraph_title,
  ...props
}) => {
  const [currentDevice, setCurrentDevice] = useState(0)
  const containerRef = useRef<RefObject<Element>>()
  const scrollPosition = useScrollPosition()
  const [isInView, setIsInView] = useState(false)
  const { dispatch } = useContext(AppContext)

  const onSetDeviceHandler = useCallback(
    (device) => {
      setCurrentDevice(device)
    },
    [scrollPosition]
  )

  useLayoutEffect(() => {
    if (typeof window !== "undefined" && isInView) {
      onSetDeviceHandler(1)
    }

    dispatch(GlobalAppActions(GlobalAppTypes.DisableMenuPin, !isInView))
  }, [isInView])

  useLayoutEffect(() => {
    // Once the container is mounted, initiate a document scroll listener, that observes when
    const wrapperOffset = containerRef?.current.getBoundingClientRect().top
    if (scrollPosition >= wrapperOffset) {
      setIsInView(true)
    }
  }, [containerRef.current, scrollPosition, setIsInView])

  const renderDevice = () => {
    if (currentDevice === 1)
      return (
        <div className="inner horizontal">
          <Desktop className="desktop" />
        </div>
      )
    if (currentDevice === 2)
      return (
        <div className="inner horizontal">
          <Tablet className="tablet" />
        </div>
      )
    if (currentDevice === 3)
      return (
        <div className="inner">
          <Phone className="phone" />
        </div>
      )
    return null
  }

  const styleProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: isInView ? 1 : 0 },
  })

  return (
    <StyledProductPresentation
      ref={containerRef}
      className={isInView ? "sticky" : ""}
    >
      <animated.div className="header" style={styleProps}>
        <Content
          content={paragraph_title.richText}
          htmlSerializer={htmlSerializer}
        />
      </animated.div>
      <StyledDeviceSelector className={isInView ? "visible" : ""}>
        <span
          className={currentDevice === 1 ? `active` : ``}
          onClick={() => onSetDeviceHandler(1)}
        >
          <Icon icon="desktop-lofi" />
        </span>
        <span
          className={currentDevice === 2 ? `active` : ``}
          onClick={() => onSetDeviceHandler(2)}
        >
          <Icon icon="tablet" />
        </span>
        <span
          className={currentDevice === 3 ? `active` : ``}
          onClick={() => onSetDeviceHandler(3)}
        >
          <Icon icon="mobile" />
        </span>
      </StyledDeviceSelector>
      {renderDevice()}
    </StyledProductPresentation>
  )
}

export const query = graphql`
  fragment ProductPresentationFragment on PrismicIndexDataBodyProductPresentation {
    id
    slice_type
    primary {
      paragraph_content {
        richText
      }
      paragraph_title {
        richText
      }
    }
  }
  fragment ProductPresentationPageFragment on PrismicPageDataBodyProductPresentation {
    id
    slice_type
    primary {
      paragraph_content {
        richText
      }
      paragraph_title {
        richText
      }
    }
  }
`
