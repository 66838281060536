import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"

const Layout = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${(props) => props.theme.fontStacks.bold};
    ${tw`mt-0`}
    line-height: 1;
    margin-bottom: 0.5em;
    font-size: 2rem;
  }
  p {
    margin: 0;
    margin-bottom: 1rem;
  }

  // layout styles --------------------------------
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    /* background: rgba(0, 255, 0, 0.2); */
    padding-left: 5px;
    margin: 0 2rem;
    margin-bottom: 2rem;
  }

  ${(props) => {
    if (props.flipped) {
      return `
      > *:not(:first-child) {
        margin-top: 2rem;
        margin-bottom: 0;
        order: 1;
      }
      > *:first-child {
        order: 2;
      }`
    }
  }}

  ${(props) => props.theme.media.tablet_portrait_up`
    flex-direction: row;
    > *:first-child {
      flex: 2;
      order: 1;
    }
    > *:not(:first-child) {
      flex: 1;
      order: 2;
      margin: 0;
      margin-right: 2rem;
      margin-bottom: 2rem;
      ${(props) => props.theme.media.tablet_landscape_up`
        margin-bottom: 0rem;
      `}
      ${(props) => props.theme.media.desktop_up`
        margin-bottom: 0;
        margin-left: 3rem;
      `}
    }
  `}
  ${(props) => props.theme.media.tablet_landscape_up`
    > *:first-child {
      flex: 3;
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    > *:first-child {
      flex: 4;
    }
  `}
`

function SidebarLayout({ children, flippedOnPhone, ...props }) {
  return (
    <Layout {...props} flipped={flippedOnPhone}>
      {children}
    </Layout>
  )
}

export default SidebarLayout

SidebarLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
