/* Action types */
import { ActionMap, createActions } from "../../lib/reducer.types"

export enum FormTypes {
  AddFieldError = "Form/AddFieldError",
  RemoveFieldError = "Form/RemoveFieldError",
  UpdateFieldError = "Form/UpdateFieldError",
  ResetFields = "Form/ResetFields",
}

export type FormInputErrorType = { error: boolean; errorMessage: string }
export type FormValidationType = { fieldId: string; error?: FormInputErrorType }

type Actions = {
  [FormTypes.AddFieldError]: FormValidationType
  [FormTypes.RemoveFieldError]: FormValidationType
  [FormTypes.UpdateFieldError]: FormValidationType
  [FormTypes.ResetFields]: null
}

export type FormActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const FormActions = createActions<Actions>()
