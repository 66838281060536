import { useState, useLayoutEffect } from "react"

export enum ViewportEnum {
  Phone = "PHONE_ONLY",
  TabletPortrait = "TABLET_PORTRAIT_UP",
  TabletLandscape = "TABLET_LANDSCAPE_UP",
  Desktop = "DESKTOP",
  LargeDesktop = "LARGE_DESKTOP",
}

export default function useViewport() {
  const [viewport, setViewport] = useState<ViewportEnum | null>(null)

  function handleViewportChange() {
    const vp = window.innerWidth
    if (vp < 599) {
      setViewport(ViewportEnum.Phone)
    } else if (vp >= 600 && vp < 900) {
      setViewport(ViewportEnum.TabletPortrait)
    } else if (vp >= 900 && vp < 1200) {
      setViewport(ViewportEnum.TabletLandscape)
    } else if (vp >= 1200 && vp < 1500) {
      setViewport(ViewportEnum.Desktop)
    } else {
      setViewport(ViewportEnum.LargeDesktop)
    }
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      handleViewportChange()
      window.addEventListener("resize", handleViewportChange, false)
      window.addEventListener("orientationchange", handleViewportChange, false)
    }
    return () => {
      window.removeEventListener("resize", handleViewportChange, false)
      window.removeEventListener(
        "orientationchange",
        handleViewportChange,
        false
      )
    }
  })

  return viewport
}
