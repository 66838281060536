import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import { paginationResolver } from "utils/paginationResolver"
import tw from "twin.macro"

const StyledPaginationBar = styled.div`
  margin-top: 4rem;
  display: flex;
  font-family: ${(props) => props.theme.fontStacks.bold};
  > * {
    margin-right: 1rem;
    text-align: center;
    width: 5rem;
    font-size: 3rem;
    ${(props) => props.theme.media.tablet_landscape_up`
      width: 3rem;
      font-size: 2rem;
    `}
    .active {
      ${tw`bg-brand-500`}
      color: white;
      display: block;
    }
  }
`

function PaginationBar({ currentPage, numPages, ...props }) {
  if (numPages < 2) return null
  const { path } = props
  const { urlBase } = paginationResolver(path)

  const rangeArray = Array.from(Array(numPages + 1).keys()).slice(1)
  return (
    <StyledPaginationBar>
      {rangeArray.map((n) => {
        return (
          <Link to={n === 1 ? `/${urlBase}` : `/${urlBase}/side/${n}`}>
            <span className={n === currentPage && "active"}>{n}</span>
          </Link>
        )
      })}
    </StyledPaginationBar>
  )
}

export default PaginationBar

PaginationBar.propTypes = {
  children: PropTypes.node.isRequired,
}
