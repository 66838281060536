import React, { useRef, useState } from "react"
import { Spring, animated, config, useSpring } from "@react-spring/web"
import styled from "styled-components"
import tw from "twin.macro"
import Content from "components/Content"
import { htmlSerializer } from "components/HeroSection"
import { useIntersectionObserver } from "hooks/useIntersectionObserver"

const StyledCounter = styled.article<{ center: boolean }>`
  .counter {
    font-size: 6rem;
    line-height: 1;
    ${tw`font-bold`}

    &.money > :last-child {
      ${tw`mr-4`}
    }
  }

  ${(props) => (props.center ? `text-align: center` : ``)}
`

export const USPCounter: React.FC = ({
  content,
  unit,
  count_to,
  unit_money,
  center_content,
}) => {
  const elementRef = useRef(null)
  const [completedAnimation, setCompletedAnimation] = useState(false)
  const isInViewport = useIntersectionObserver(elementRef, {
    threshold: 0.5,
  })

  const [{ num }] = useSpring(
    () => ({
      from: { num: 0 },
      to: {
        num: isInViewport?.isIntersecting || completedAnimation ? count_to : 0,
      },
      config: config.default,
      delay: 1000,
      onRest: () => setCompletedAnimation(true),
    }),
    [isInViewport, completedAnimation]
  )

  const renderValue = (x) => {
    return unit_money
      ? x.toLocaleString("da", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : x.toFixed(0)
  }

  return (
    <StyledCounter center={center_content}>
      <div
        className={unit_money ? "counter money" : "counter"}
        ref={elementRef}
      >
        {completedAnimation ? (
          <>
            <span>{renderValue(count_to)}</span>
            {unit}
          </>
        ) : (
          <>
            <animated.span>{num.to(renderValue)}</animated.span>
            {unit}
          </>
        )}
      </div>
      {!!content?.richText.length && (
        <Content content={content.richText} serializer={htmlSerializer} />
      )}
    </StyledCounter>
  )
}
