import React, { useRef, useState } from "react"
import { Link, graphql } from "gatsby"
import { Elements } from "prismic-reactjs"
import { valuable } from "utils/worthy"
import styled from "styled-components"
import tw from "twin.macro"
import Content from "components/Content"
import { linkResolver } from "utils/linkResolver"
import ProductPrice from "./Price"
import ResponsiveImage from "components/ResponsiveImage"
import Card from "lib/Card"
import Icon, { IconSizeEnum } from "lib/Icon"
import { StyledHyperlink } from "components/HeroSection"
import { Responsive, Tooltip } from "@clevertrack/shared"
import useOnClickOutside from "hooks/useOnClickOutside"

const StyledProduct = styled(Card)`
  & + & {
    margin-top: 0;
  }

  ${(props) =>
    props.columns === 3
      ? `padding: 20rem 2rem 2rem; align-self: center;`
      : `padding: 20rem 2rem 6rem;`}

  position: relative;
  max-width: 50rem;
  ${tw`text-center justify-self-center`}

  .product-content {
    h3 {
      margin-top: 0;
      ${(props) =>
        props.columns === 3
          ? `font-size: 2.1rem; margin-top: 0.5em; margin-bottom: 0.25em;`
          : `font-size: 2.4rem;`}
    }
    p,
    a {
      ${(props) =>
        props.columns === 3
          ? `font-size: 1.4rem; margin-top: 0;`
          : `font-size: 1.6rem;`}
    }
  }

  .product-image {
    // max-width: 50%;

    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);

    img {
      height: 18rem;
      width: 18rem;
      object-fit: contain;
    }
  }

  .product-features {
    ul {
      ${(props) =>
        props.columns === 3 ? `font-size: 1.4rem;` : `font-size: 1.6rem;`}

      ${(props) => props.theme.media.tablet_landscape_up`
        ${props.columns === 3 ? `width: 100%;` : `width: 75%;`}
      `}

      ${tw`text-left m-0 mx-auto px-0 py-8 list-none space-y-4 border-solid border-0 border-t border-brand-gray-light`}
      li {
        display: grid;
        grid-template-columns: 2.4rem 1fr 1.6rem;
        column-gap: 1rem;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        position: relative;

        ${(props) => props.theme.media.tablet_landscape_up`
          grid-template-columns: 2.4rem 1fr 1.6rem;
        `}

        svg {
          ${tw`text-brand-500 mr-4 self-start mt-2`}
        }

        &.has-tooltip {
          ${tw`cursor-pointer`}
        }

        &.unincluded {
          .description,
          svg {
            ${tw`text-black opacity-40`}
          }
        }
      }
    }
  }
`

const StyledTooltip = styled(Tooltip)`
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
  ${tw`p-4 px-6 bg-white text-black text-xl`}
  left: auto;
  right: 0;
  bottom: 4rem;
  width: 30rem;
  transform: translate3d(0, 0, 0);

  p {
    ${tw`m-0`}
  }

  &:after {
    ${tw`border-t-white right-0 left-auto lg:(left-1/2)`}
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    left: 50%;
    right: auto;
    max-width: auto;
    transform: translate3d(-50%, 0, 0);
  `}
`

const StyledProductPrice = styled(ProductPrice)`
  position: absolute;
  bottom: 0;
  height: 6rem;
  width: 100%;
  left: 0;
`

const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case Elements.heading1:
      return <h3 key={key}>{children}</h3>
    default:
      return null
  }
}

function Product({ product, data, image, columns, ...props }) {
  const [showTooltipID, setShowTooltipID] = useState<string | null>(null)
  const {
    title,
    description,
    currency,
    current_price,
    product_color,
    text_after_price,
    text_before_price,
    cta_link,
    cta_link_text,
    quote_link,
  } = product.data
  const featuresRef = useRef(null)
  const { body } = data.content.document.data
  useOnClickOutside(featuresRef, () => {
    setShowTooltipID(null)
  })

  const onClickTooltipHandler = (e, key) => {
    featuresRef.current = e.target
    setShowTooltipID(key)
  }

  const items = body.flatMap((x) => x.items)

  const link =
    cta_link.link_type === "Web"
      ? cta_link.url
      : linkResolver(cta_link.document)

  const renderListItem = (x, key) => {
    return (
      <>
        <Icon icon={x.unincluded ? "close" : "check"} size={IconSizeEnum.MD} />
        <span className="description">{x.feature_description.text}</span>
        {x.feature_tooltip.text && (
          <span tw="relative">
            <Icon
              icon="info-circle"
              size={IconSizeEnum.SM}
              onClick={(e) => onClickTooltipHandler(e, `feature_${key}`)}
            />
            <StyledTooltip toggled={showTooltipID === `feature_${key}`}>
              <Content content={x.feature_tooltip.richText} />
            </StyledTooltip>
          </span>
        )}
      </>
    )
  }

  return (
    <StyledProduct
      size="sm"
      style={{ borderBottom: `5px solid ${product_color}` }}
      columns={columns}
    >
      <div className="product-image">
        <ResponsiveImage img={image} />
      </div>
      <div className="product-content">
        {valuable(title) && (
          <Content content={title.richText} serializer={htmlSerializer} />
        )}
        {valuable(description) && <Content content={description.richText} />}
        <StyledHyperlink
          tw="flex justify-center items-center pb-8"
          as={Link}
          to={link}
          target={cta_link.link_type === "Web" ? "_blank" : "_self"}
        >
          {cta_link_text}
          <Icon icon="long-arrow-right" />
        </StyledHyperlink>
      </div>
      {items.length > 0 && (
        <div className="product-features">
          <ul>
            {items.map((x, key) => {
              return (
                <li
                  key={`feature_${key}`}
                  className={`${x.unincluded ? "unincluded" : ""}${
                    x.feature_tooltip.text ? " has-tooltip" : ""
                  }`}
                  onMouseEnter={() => setShowTooltipID(`feature_${key}`)}
                  onMouseLeave={() => setShowTooltipID(null)}
                >
                  {renderListItem(x, key)}
                </li>
              )
            })}
          </ul>
        </div>
      )}
      {current_price && currency && (
        <StyledProductPrice
          text_before_price={text_before_price}
          text_after_price={text_after_price}
          currency={currency}
          current_price={current_price}
          product_color={product_color}
          quote_link={quote_link}
        />
      )}
    </StyledProduct>
  )
}

export default Product

Product.defaultProps = {}
Product.propTypes = {}

export const query = graphql`
  fragment ProductFragment on PrismicProduct {
    type
    id
    data {
      title {
        richText
        text
      }
      description {
        richText
      }
      currency
      current_price
      product_color
      quote_link {
        url
        type: link_type
        document {
          ... on PrismicPage {
            id
            uid
            type
            lang
          }
        }
      }
      cta_link {
        url
        link_type
        document {
          ... on PrismicPage {
            id
            uid
            type
            lang
          }
        }
      }
      cta_link_text
      text_after_price
      text_before_price
      product_tooltip {
        richText
      }
      body {
        ... on PrismicProductDataBodyFeatureList {
          id
          items {
            feature_description {
              text
            }
            feature_tooltip {
              richText
              text
            }
            unincluded
          }
        }
      }
    }
  }
`
