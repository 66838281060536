import React, { useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { default as DefaultGridParagraph } from "lib/Paragraphs/GridParagraph"
import USP from "./"
import Container from "components/Container"
import CTARepeater from "components/CTA/Repeater"
import ResponsiveImage from "components/ResponsiveImage"
import { getIconFromUrl } from "utils/iconResolver"
import SVG from "react-inlinesvg"
import { useOnScreen } from "hooks/useOnScreen"

const StyledIllustrated = styled(DefaultGridParagraph)`
  padding: 2.2rem;
  ${(props) => props.theme.media.tablet_landscape_up`
    grid-column-gap: 8rem;
    padding: 8rem 0;
    margin: 0 2rem;

    &.isHome {
      display: grid !important;
      grid-template-columns: repeat(7, 1fr);
      grid-template-areas: ${(props) =>
        props.flipDesktop
          ? `"usp usp usp image image image image"`
          : `"image image image image usp usp usp"`};
    }
  `}

  &.isHome {
    display: block;
  }
`

const StyledUSP = styled(USP)`
  grid-area: usp;
  align-self: center;
  padding-top: 4rem;
  padding-bottom: 2rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding-top: 0;
  `}
`

const StyledImageContainer = styled.div`
  grid-area: image;
  box-shadow: 20px 20px 40px rgba(50, 50, 93, 0.25);
  ${(props) => !props.isHome && "max-width: 80vw;"}
  margin: 0 auto;
  align-self: center;
  line-height: 0;
  /* box-shadow: 27.1px 62.5px 125px -25px rgba(50, 50, 93, 0.5),
    16.2px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6); */

  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: none;
    margin: 0;
    ${
      props.flip
        ? `
          box-shadow: 20px 20px 40px rgba(50, 50, 93, 0.25);
        `
        : ``
    };

  `}

  transition: 0.9s;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
  &.hide {
    opacity: 0;
    transform: translateY(25px);
  }
`

const StyledImage = styled(ResponsiveImage)``

const gridPhone = `"image" "usp"`
const gridTabletPortrait = `"image image image usp usp usp"`

function Illustrated({ children, ctas, isHome, ...props }) {
  const { illustration_position: image_position } = props
  const { illustration: image } = props
  const { usp: uspObj, svg_graphics } = props
  const { data: usp } = uspObj.document

  const illustrationRef = useRef(null)
  const onScreen = useOnScreen(illustrationRef, "-150px")

  return (
    <Container>
      <StyledIllustrated
        flipTabletPortrait={image_position}
        flipTabletLandscape={image_position}
        flipDesktop={image_position}
        flipBigDesktop={image_position}
        gridPhone={gridPhone}
        gridTabletLandscape={gridTabletPortrait}
        className={isHome && "isHome"}
        useFractions
      >
        <StyledUSP {...usp}>
          <CTARepeater items={ctas} />
        </StyledUSP>
        <StyledImageContainer
          flip={image_position}
          isHome={isHome}
          ref={illustrationRef}
          className={onScreen ? "show" : "hide"}
        >
          {!!svg_graphics ? (
            <SVG
              src={`./illustrations/${getIconFromUrl(image.url)}.svg`}
              title={getIconFromUrl(image.url)}
            />
          ) : (
            <StyledImage img={image} />
          )}
        </StyledImageContainer>
      </StyledIllustrated>
    </Container>
  )
}

export default Illustrated

Illustrated.defaultProps = {}
Illustrated.propTypes = {
  children: PropTypes.node,
}
