import React, { useContext, useRef, useEffect } from "react"
import styled from "styled-components"
import DefaultFormField from "lib/FormField"
import { FormContext } from "../Form/context"
import useChildrenInFocus from "hooks/useChildrenInFocus"
import { siteTheme as theme } from "theme"

const StyledFormField = styled(DefaultFormField)`
  box-shadow: none;
  transition: all ease-out 0.15s;
  border: 1px solid rgba(0, 0, 0, 0.2);

  input {
    border: none;
  }

  textarea {
    border: none;
    height: 100%;
  }

  ${(props) =>
    props.hasFocus ? `box-shadow: ${theme.inputFocusShadow};` : ``} {
  }

  ${(props) => (!props.valid ? `border-color: ${theme.colors.danger};` : ``)} {
  }
`

function FormField({ validationKey, children, ...props }) {
  const {
    state: { fields },
  } = useContext(FormContext)

  const formFieldRef = useRef(null)
  const [hasFocus, _] = useChildrenInFocus(formFieldRef)

  const errorObj = fields.find((field) => field.id === validationKey)

  return (
    <StyledFormField
      valid={!errorObj?.error}
      errorMsg={errorObj && errorObj?.errorMessage}
      forwardRef={formFieldRef}
      hasFocus={hasFocus}
      {...props}
    >
      {children}
    </StyledFormField>
  )
}

export { FormField }
