import React from "react"
import styled from "styled-components"
import "twin.macro"
import Icon from "lib/Icon"
import Inner from "components/Container/Inner"
import Content from "components/Content"
import Container from "components/Container"
import Person from "./"
import CTALink from "components/CTA/Link"
import { Responsive } from "@clevertrack/shared"
import { mapPersonCTAs } from "./helper"

const StyledPersonCTA = styled(Inner)`
  padding-top: 0;
  margin-top: 4rem;
  // padding: 2rem;

  .person_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .details {
    h4 {
      margin: 0;
      font-size: 1.8rem;
    }

    > p {
      margin: 0;
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    // padding: 2rem;

    .ctas {
      display: flex;
      align-items: flex-start;
    }

    .person_info {
      flex-direction: column;
      text-align: center;
      position: relative;
      z-index: 10;
      color: ${props.theme.colors.white};
      transform: none;
    }

    .details {
      transform: none;
      h4 {
        margin: 0;
        font-size: 2.4rem;
      }

      p {
        margin: 0;
        font-size: 1.8rem;
        line-height: 3.2rem;
      }
    }
  `}
`

const StyledPerson = styled(Person)`
  flex: 0 0 12rem;
  margin-right: 2rem;
  .headshot {
    width: 12rem;
    height: 12rem;
    padding: 0;
    border-radius: 50%;
    overflow: hidden;
    clip-path: none;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    position: relative;
    z-index: 100;
    transform: none;
    flex: 0 0 16rem;
    margin-right: 0;
    margin-bottom: 2rem;
    .headshot {
      width: 16rem;
      height: 16rem;
      padding: 0;
      border-radius: 50%;
      overflow: hidden;
      clip-path: none;
    }
  `}
`

const StyledQuote = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary};

  .inner {
    position: relative;
    z-index: 10;
    padding: 4rem 2rem 8rem;
    color: ${(props) => props.theme.colors.white};

    blockquote {
      padding: 0;
      margin: 0;

      cite {
        text-align: right;
        display: block;
      }
    }

    svg {
      opacity: 0.15;
      position: absolute;
      z-index: -1;
      width: 8rem;
      height: 8rem;
      right: 0;
      transform: translate3d(-1.5rem, 0, 0);
    }

    h2 {
      max-width: 80%;
    }

    blockquote p {
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    .inner {
      display: grid;
      grid-template-columns: 1.5fr 4fr;
      /* display: flex;
      flex-direction: column;
      justify-content: center; */
      padding: 4rem 4rem 4rem 2rem;
      // min-height: 38rem;
      // transform: translate3d(0, -2rem, 0);

      svg {
        width: 12rem;
        height: 12rem;
        transform: translate3d(-1.5rem, -6rem, 0);
        top: 3rem;
      }
    }
  `}
`

function PersonCTA({
  person: personObj,
  ctas,
  person_cta_content,
  person_cta_title,
  ...props
}) {
  const { data: person } = personObj.document
  const person_ctas = mapPersonCTAs(person, ctas)

  function renderPerson() {
    return (
      <div className="person_info">
        <StyledPerson image={person.imageSharp || person.image} />
        <div className="details">
          <h4>{`${person.first_name} ${person.last_name}`}</h4>
          <p>{`${person.role}`}</p>
          {person.areas_of_work && <p>{`${person.areas_of_work}`}</p>}
        </div>
      </div>
    )
  }

  return (
    <Container>
      <StyledPersonCTA borderless>
        <StyledQuote className="content">
          <div className="inner">
            {renderPerson()}
            <div tw="md:(pl-12)">
              <Icon icon="quote" />
              <blockquote>
                <Content content={person_cta_title.richText} />
                <Content content={person_cta_content.richText} />
              </blockquote>
              <div className="ctas">
                {person_ctas.map((cta, i) => {
                  return (
                    <CTALink
                      key={`cta_${i}`}
                      primary={cta.primary}
                      bg={cta.primary ? "secondary" : null}
                      border={cta.primary ? "secondary" : null}
                      blank
                      to={cta.value}
                    >
                      {cta.text}
                    </CTALink>
                  )
                })}
              </div>
            </div>
          </div>
        </StyledQuote>
      </StyledPersonCTA>
    </Container>
  )
}

export default PersonCTA

PersonCTA.defaultProps = {}
PersonCTA.propTypes = {}
