import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import tw from "twin.macro"

const StyledTag = styled.span<ITag>`
  ${tw`text-brand-500`}
  position: relative;
  padding: 4px;
  margin-right: 4px;
  font-family: ${(props) => props.theme.fontStacks.bold};
  display: inline-block;
  font-size: ${(props) => props.theme.fontSizes.h6};

  ${(props) => {
    if (props.filled) {
      const common = `
        margin-right: 4px;
        left: -2px;
      `
      if (props.inverted) {
        return `
          ${tw`bg-brand-500 text-white`}
          ${common}
        `
      } else {
        return `
          ${common}
          ${tw`bg-white`}
        `
      }
    }
  }}
`

type TagSize = "lg" | null
export interface ITag {
  filled?: boolean
  size?: TagSize
  inverted?: boolean
  children: React.ReactNode
}

const Tag: React.FC<ITag> = ({ children, ...props }) => {
  if (!children) return null
  const tag_value = children[1]
  return (
    <StyledTag {...props}>
      <Link to={`/viden/${tag_value}`}>{children}</Link>
    </StyledTag>
  )
}

export default Tag

Tag.defaultProps = {}
Tag.propTypes = {
  children: PropTypes.node.isRequired,
}
