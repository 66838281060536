/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Prismic Related
require("intersection-observer")
const React = require("react")
const { AppProvider } = require("./src/context")
const Hotjar = require("@hotjar/browser").default

const {
  PrismicPreviewProvider,
  componentResolverFromMap,
} = require("gatsby-plugin-prismic-previews")
const { linkResolver } = require("./src/utils/linkResolver")

const { Home } = require("./src/templates/home.jsx")
const { Page } = require("./src/templates/page.jsx")
const { Article } = require("./src/templates/article.jsx")

const siteId = 2804378
const hotjarVersion = 6
Hotjar.init(siteId, hotjarVersion)

exports.wrapRootElement = ({ element }) => {
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: "clevertrack",
          linkResolver,
          componentResolver: componentResolverFromMap({
            index: Home,
            page: Page,
            blog_post: Article,
          }),
        },
      ]}
    >
      <AppProvider>{element}</AppProvider>
    </PrismicPreviewProvider>
  )
}

/* exports.onClientEntry = () => {
  if (process.env.NODE_ENV !== "production") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render")
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    })
  }
} */
