import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import FeedView from "../Previews/FeedView"
import CaseView from "../Previews/CaseView"
import PaginationBar from "./Pagination"
import sortBy from "lodash-es/sortBy"
import { orderBy } from "lodash"

const PhoneGrid = styled.div`
  margin-top: 2rem;
`

function BlogFeed({
  items,
  currentPage,
  numPages,
  featuredSlice,
  isCategoryFeed,
  ...props
}) {
  const featuredUids = featuredSlice.items.reduce(
    (acc, cur) => {
      return [...acc, cur.featured_extras.uid]
    },
    [featuredSlice.primary.hero_post.uid]
  )

  const sortedItems = orderBy(
    items,
    ({ data }) => data.publication_date,
    "desc"
  )

  const filteredItems = isCategoryFeed
    ? sortedItems.filter(({ data }) => data.public_publish)
    : sortedItems
        .filter(({ data }) => data.public_publish)
        .filter((x) => !featuredUids.some((uid) => uid === x.uid))

  return (
    <>
      <PhoneGrid>
        {filteredItems.map((post) => {
          const { post_type } = post.data
          const isCasePost = post_type.toLowerCase() === "case post"
          if (isCasePost) {
            return <CaseView key={`case_${post.uid}`} post={post} />
          } else {
            return <FeedView key={post.uid} post={post} />
          }
        })}
      </PhoneGrid>
      <PaginationBar currentPage={currentPage} numPages={numPages} {...props} />
    </>
  )
}

export default BlogFeed

BlogFeed.propTypes = {
  children: PropTypes.node.isRequired,
}
