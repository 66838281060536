import React, { useEffect, useContext } from "react"
import { FormProvider, FormContext } from "./context"
import { FormTypes, FormActions } from "./actions"

interface IFormProps {
  children: React.ReactNode
  onSubmit: (e: React.FormEvent<HTMLFormElement>, ...args: any) => void
  forwardRef: React.RefObject<HTMLFormElement>
}

const FormComponent: React.FC<IFormProps> = ({
  children,
  onSubmit,
  forwardRef,
  ...props
}) => {
  const {
    state: { fields },
    dispatch,
  } = useContext(FormContext)

  function onSubmitHandler(e) {
    e.preventDefault()
    onSubmit(e, fields)
  }

  useEffect(() => {
    dispatch(FormActions(FormTypes.ResetFields))
    return () => dispatch(FormActions(FormTypes.ResetFields))
  }, [])

  return (
    <form ref={forwardRef} onSubmit={onSubmitHandler} {...props}>
      {children}
    </form>
  )
}

export const Form: React.FC<IFormProps> = ({ ...props }) => {
  return (
    <FormProvider>
      <FormComponent {...props} />
    </FormProvider>
  )
}
