import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Container from "components/Container"
import Paragraph from "components/Paragraph"
import Person from "./index"
import Icon from "lib/Icon"
import { getIconFromUrl } from "utils/iconResolver"
import tw from "twin.macro"

const StyledPersonRepeater = styled.section`
  margin: 4rem 2rem;
  margin-bottom: 0;

  h2 {
    ${tw`text-left sm:text-center`}
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    margin: auto;
    column-gap: 2rem;
    row-gap: 4rem;
  }
`

const StyledParagraph = styled(Paragraph)`
  .wrapper {
    max-width: 100%;
  }
  ${(props) => props.theme.media.tablet_portrait_up`
    text-align: center;

    .wrapper {
      max-width: 90%;
    }
  `}
`

const StyledPerson = styled(Person)`
  .headshot {
    height: auto;
  }

  .details {
    margin-top: 3rem;
    font-family: ${(props) => props.theme.fontStacks.bold};
    // transform: scale(0.9);
    /* opacity: 0.9; */

    > * {
      margin-top: 0.9rem;
    }

    * {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
`

function PersonRepeater({ persons_content, persons_title, items, ...props }) {
  return (
    <Container>
      <StyledPersonRepeater>
        <StyledParagraph
          paragraph_title={persons_title}
          paragraph_content={persons_content}
        />
        <div className="grid">
          {items.map((person_) => {
            const { data: person } = person_.person.document
            return (
              <StyledPerson {...person}>
                <div className="details">
                  {person.phone && (
                    <span>
                      <Icon icon="phone-alt" />
                      <span tw="ml-4">{person.phone}</span>
                    </span>
                  )}
                  {person.email && (
                    <a href={`mailto:${person.email}`}>
                      <Icon icon="envelope" />
                      <span tw="ml-4">{person.email}</span>
                    </a>
                  )}
                  {person.social_media_links.map((soMe) => {
                    return (
                      <a
                        href={soMe.social_media_profile_url.url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Icon icon={getIconFromUrl(soMe.icon.url)} />
                        <span tw="ml-4">{soMe.social_media_site}</span>
                      </a>
                    )
                  })}
                </div>
              </StyledPerson>
            )
          })}
        </div>
      </StyledPersonRepeater>
    </Container>
  )
}

export default PersonRepeater

PersonRepeater.defaultProps = {}
PersonRepeater.propTypes = {}
