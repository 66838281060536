import React, { useState, useContext } from "react"
import styled from "styled-components"
import Container from "components/Container"
import Icon from "lib/Icon"
import Content from "components/Content"
import ReactTooltip from "components/ReactTooltip"
import { columnsFromProducts } from "./helper"
import { Responsive } from "@clevertrack/shared"
import htmlSerializer from "./htmlSerializer"
import Accordion from "lib/Accordion"

//render rich text as html string dependencies
import { RichText } from "prismic-reactjs"
import { linkResolver } from "utils/linkResolver"
import { renderToString } from "react-dom/server"
import { ThemeContext } from "styled-components"
import tw from "twin.macro"

const StyledFeatureComparison = styled.div`
  margin: 4rem 2rem;
  margin-bottom: 15rem;
  font-size: 95%;

  .feature-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .partial {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};

    > span {
      display: inline-block;
      font-family: ${(props) => props.theme.fontStacks.bold};
      padding: 0 0.2em;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    font-size: 100%;
  `}
`

const Table = styled.div`
  margin-top: 6rem;
  background: ${(props) => props.theme.colors.grayLight};
  border: 1px solid ${(props) => props.theme.colors.grayLight};

  .row {
    display: grid;
    grid-gap: ${(props) => props.thickness}px;
    padding: ${(props) => props.thickness}px;
    background: ${(props) => props.theme.colors.white};
    grid-template-columns: repeat(${(props) => props.columns - 1}, 1fr);

    &:not(:first-child) {
      padding-top: 0;
      border-top: 1px solid ${(props) => props.theme.colors.grayLight};
    }

    &.clickable {
      cursor: pointer;
      &:hover {
        background-color: ${(props) => props.theme.colors.grayLighter};
      }
    }

    &.sticky {
      position: sticky;
      top: 0;
      z-index: 1000;
      background: ${(props) => props.theme.colors.white};
    }

    > * {
      padding: 2rem 1rem;
      text-align: center;

      ${(props) => props.theme.media.tablet_landscape_up`
        padding: 1.4rem;
      `}

      &.clickable {
        transition: padding 0.5s;

        &.toggled {
          padding-top: 3rem;
          transition: padding 0.3s;
        }

        &:active > * > svg {
          transform: scale(1.2) rotate(15deg);
          transition: transform 0.08s;
        }
      }

      &.category {
        grid-column: span ${(props) => props.columns - 1};
        text-align: left;
      }

      &.data-cell {
        border-top: 1px solid ${(props) => props.theme.colors.grayLight};
        border-right: 1px solid ${(props) => props.theme.colors.grayLight};

        &:last-child {
          border-right: none;
        }
      }
    }

    ${(props) => props.theme.media.tablet_landscape_up`
      grid-template-columns: repeat(${(props) => props.columns}, 1fr);
      > .heading {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 105%;
        padding: 2rem 1rem;
      }

      > * {
        text-align: center;
        &.category {
          grid-column: span 1;
          text-align: center;
        }

        &.data-cell {
        border-top: none;
        border-right: none;
        border-left: 1px solid ${(props) => props.theme.colors.grayLight};

          &:last-child {
            // border-right: none;
          }
        }
      }
    `}
  }
`

const StyledIcon = styled(Icon)`
  padding: 0.4rem;
  border-radius: 50%;

  &.circle {
    color: white;
    background: ${(props) => props.theme.colors.primary};
    transform: scale(0.9);
    transition: transform 0.15s;
  }

  &.info {
    transform: scale(1.25);
    cursor: help;
  }
`

const ColoredIcon = styled(Icon)`
  color: ${(props) => props.theme.colors[props.color]};
`

const IconCell = styled.div`
  display: flex;
  align-items: center;
  span {
    flex: 1;
    padding: 0 1rem;
  }
  &.noSelect {
    user-select: none;
  }
  &.heading {
    font-size: 90%;
    word-break: break-word;
    padding: 5.5rem 0;
    padding-bottom: 3rem;

    &:not(:last-of-type) {
      border-right: 1px solid ${(props) => props.theme.colors.grayLight};
    }

    ${(props) => props.theme.media.tablet_landscape_up`
      font-size: 105%;
      padding: 2rem 1rem;
      border-left: 1px solid ${(props) => props.theme.colors.grayLight};

      &:not(:last-of-type) {
        border-right: none;
      }
    `}
  }
`

const StyledData = styled.div`
  padding-top: 3rem;
  &,
  * {
    font-size: 1.3rem;
    line-height: 2rem;
  }

  ul li {
    text-align: left;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  p + p {
    margin-top: 1em;
  }
`

const StyledTooltip = styled.div`
  max-width: 20rem;
  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: 40rem;
  `}
`

const OffsetAccordion = styled(Accordion)`
  position: ${(props) => (props.toggled ? "relative" : "static")};
  bottom: 1.1rem;
`

const Header = styled.div`
  ${(props) => props.theme.media.tablet_landscape_up`
    & {
      text-align: center;
      margin: 0 auto;
    }
  `}
`

function FeatureComparison({ content, items, ...props }) {
  const features = items.map((item) => item.product_feature)
  const columns = columnsFromProducts(features)
  const themeContext = useContext(ThemeContext)

  const [toggled, setToggled] = useState(null)

  const expandRow = (ID) => {
    setToggled(toggled !== ID ? ID : null)
  }

  const {
    product_features_title,
    feature_column_title,
    feature_not_in_product,
  } = content

  return (
    <Container>
      <Responsive tabletLandscape={<ReactTooltip />} />
      <StyledFeatureComparison {...props}>
        <Header>
          <Content content={product_features_title.richText} />
        </Header>
        <Table columns={Object.keys(columns).length + 1} thickness={1}>
          <div className="row sticky">
            <Responsive
              tabletLandscape={
                <span className="heading">
                  <b>{feature_column_title}</b>
                </span>
              }
            />
            {Object.values(columns).map((product) => {
              const tooltipHtml = renderToString(
                <StyledTooltip theme={themeContext}>
                  {RichText.render(
                    product.product_tooltip.richText,
                    linkResolver
                  )}
                </StyledTooltip>
              )
              return (
                <IconCell className="heading">
                  <Responsive
                    tabletLandscape={
                      <StyledIcon
                        className="info"
                        icon="info-circle"
                        data-tip={tooltipHtml}
                      />
                    }
                  />
                  <span className="heading" key={`heading_${product.id}`}>
                    <b>{product.title.text}</b>
                  </span>
                </IconCell>
              )
            })}
          </div>
          {features.map((featureObj, i) => {
            const featureID = featureObj.document.id
            const {
              document: { data: feature },
            } = featureObj
            const thisToggled = toggled === featureID
            return (
              <div
                className="row clickable"
                key={`${featureID}_${i}`}
                onClick={() => expandRow(featureID)}
              >
                <span className={`category ${thisToggled && "toggled"}`}>
                  <IconCell className="noSelect">
                    <Responsive
                      phone={
                        <StyledIcon
                          className="info"
                          icon={thisToggled ? "close" : "info-circle"}
                        />
                      }
                      tabletLandscape={
                        <StyledIcon
                          className="circle"
                          icon={thisToggled ? "minus" : "plus"}
                        />
                      }
                    />
                    <span>
                      {feature.title}
                      {<>  </>}
                    </span>
                  </IconCell>
                  <OffsetAccordion toggled={thisToggled}>
                    <StyledData>
                      <Content
                        content={feature.description.richText}
                        serializer={htmlSerializer}
                      />
                    </StyledData>
                  </OffsetAccordion>
                </span>
                {Object.keys(columns).map((product, j) => {
                  const productsInFeature = feature.products
                    .map((item) => item.product)
                    .filter(Boolean)
                  const descriptions = feature.products.map((item) => {
                    return {
                      desc: item.product_feature_description,
                      partial: item.supported,
                    }
                  })
                  let productFeatureDescriptionIndex = null
                  const findProduct = productsInFeature.find((p, i) => {
                    if (p.document.id === product) {
                      productFeatureDescriptionIndex = i
                      return true
                    } else {
                      return false
                    }
                  })
                  const hasFeature = !!findProduct
                  return (
                    <span className="data-cell">
                      <span className="feature-icon">
                        {hasFeature ? (
                          descriptions[productFeatureDescriptionIndex]
                            .partial ? (
                            <span className="partial">
                              <span>(</span>
                              <ColoredIcon icon="check" color="primary" />
                              <span>)</span>
                            </span>
                          ) : (
                            <ColoredIcon icon="check" color="primary" />
                          )
                        ) : (
                          <ColoredIcon
                            icon="minus"
                            color="gray"
                            style={{ transform: "scale(0.7)" }}
                          />
                        )}
                      </span>
                      <Responsive
                        tabletLandscape={
                          <Accordion toggled={thisToggled}>
                            <StyledData>
                              {hasFeature ? (
                                <Content
                                  content={
                                    descriptions[productFeatureDescriptionIndex]
                                      .desc.richText
                                  }
                                  serializer={htmlSerializer}
                                />
                              ) : (
                                <Content
                                  content={feature_not_in_product.richText}
                                  serializer={htmlSerializer}
                                />
                              )}
                            </StyledData>
                          </Accordion>
                        }
                      />
                    </span>
                  )
                })}
              </div>
            )
          })}
        </Table>
      </StyledFeatureComparison>
    </Container>
  )
}

export default FeatureComparison

FeatureComparison.defaultProps = {}
FeatureComparison.propTypes = {}
