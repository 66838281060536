/* Action types */
import { ActionMap, createActions } from "../../lib/reducer.types"
import { MenuTypeEnum } from "./app.types"

export enum GlobalAppTypes {
  ToggleMenu = "Global/ToggleMenu",
  ToggleSidebar = "Global/ToggleSidebar",
  ToggleSettings = "Global/ToggleSettings",
  ToggleNav = "Global/ToggleNav",
  VisibleSection = "Blog/VisibleSection",
  DisableMenuPin = "Global/DisableMenuPin",
  Reset = "Global/Reset",
}

type Actions = {
  [GlobalAppTypes.ToggleMenu]: { key: MenuTypeEnum }
  [GlobalAppTypes.ToggleSidebar]: { toggled?: boolean }
  [GlobalAppTypes.ToggleSettings]: { toggled?: boolean }
  [GlobalAppTypes.ToggleNav]: { toggled?: boolean }
  [GlobalAppTypes.VisibleSection]: { sectionID: string }
  [GlobalAppTypes.DisableMenuPin]: boolean
  [GlobalAppTypes.Reset]: null
}

export type GlobalAppActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const GlobalAppActions = createActions<Actions>()
