import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"
import Icon from "lib/Icon"
import Accordion from "lib/Accordion"

const StyledFAQ = styled.article`
  .content {
    & > *:not(svg) {
      margin-left: 2rem;
    }
  }
`

const Question = styled.div`
  cursor: pointer;
  user-select: none;
  display: grid;
  grid-template-columns: 2.4rem auto;
  align-items: center;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

const Answer = styled.div`
  font-family: ${(props) => props.theme.fontStacks.light};
  position: relative;
  padding-left: 2.4rem;
  > * {
    margin-top: 0.2rem;
    max-width: 70rem;
    img {
      width: auto;
      max-width: 100%;
    }
  }

  p {
    margin-bottom: 0;
  }

  p:not(:first-of-type) {
    margin-top: 1em;
  }
`

const Line = styled.div`
  background: ${(props) => props.theme.colors.grayLight};
  position: absolute;
  top: 0;
  left: -1rem;
  bottom: 0;
  width: 2px;
`

const StyledIcon = styled(Icon)`
  transform: rotate(${(props) => (props.toggled ? "45deg" : "0deg")});
  transition: 0.2s;
`

function FAQ({ data, toggled, onClick, ...props }) {
  const { question, answer } = data.document.data
  return (
    <StyledFAQ {...props}>
      <Question className="content" onClick={onClick}>
        <StyledIcon icon="plus" toggled={toggled} />
        <Content content={question.richText} />
      </Question>
      <Accordion toggled={toggled}>
        <Answer className="content">
          <Line />
          <Content content={answer.richText} />
        </Answer>
      </Accordion>
    </StyledFAQ>
  )
}

export default FAQ

FAQ.defaultProps = {}
FAQ.propTypes = {}
