import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledNavLink = styled((props) => <Link {...props} />)`
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 1.6rem;
  height: 100%;
  color: ${(props) => props.theme.colors.gray};
  transition: all ease-out 0.15s;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  padding: 1rem 1rem 1rem 3rem;

  &:before {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: currentColor;
    position: absolute;
    display: block;
    opacity: 0;
    transition: all ease-out 0.15s;
    left: 0;
    top: 50%;
    content: "";
    transform: translate3d(0, -50%, 0);
  }

  &.active,
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    padding-left: 3rem;

    &:before {
      left: 1.5rem;
      opacity: 1;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    color: ${props.theme.colors.gray};
    padding: 0.5rem 1rem 0.5rem 2rem;

    &.active,
    &:hover {
      padding-left: 2rem;
      color: ${props.theme.colors.primary};

      &:before {
        left: 0.5rem;
        opacity: 1;
      }
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    padding: 1rem 1rem 1rem 3rem;

    &.active,
    &:hover {
      color: ${props.theme.colors.primary};
      padding-left: 3rem;

      &:before {
        left: 1.5rem;
        opacity: 1;
      }
    }
  `}
`

function NavLink({ snappy, children, ...props }) {
  function mouseDownHandler(e) {
    if (snappy && typeof e.target.click === "function") e.target.click()
  }

  if (!props.to) {
    return (
      <StyledNavLink
        as="a"
        onMouseDown={mouseDownHandler}
        draggable="false"
        {...props}
      >
        {children}
      </StyledNavLink>
    )
  }

  return (
    <StyledNavLink onMouseDown={mouseDownHandler} draggable="false" {...props}>
      {children}
    </StyledNavLink>
  )
}

export default NavLink

NavLink.defaultProps = {
  snappy: false,
}
NavLink.propTypes = {
  snappy: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
