import { minimumFontSize, verticalRhythm } from '../typography'

export const input = {
  boxShadow: `0 .2rem .2rem rgba(0, 0, 0, 0.2)`,
  borderRadius: `0`,
  sm: {
    padding: [(verticalRhythm * .4).toFixed(2), 1],
    margin: [(verticalRhythm * .6).toFixed(2), 0],
    border: [`.1rem solid rgba(0, 0, 0, 0.2)`],
    fontSize: (minimumFontSize).toFixed(2),
    lineHeight: verticalRhythm
  },
  md: {
    padding: [(verticalRhythm * .6).toFixed(2), 1],
    margin: [(verticalRhythm * .4).toFixed(2), 0],
    border: [`.1rem solid rgba(0, 0, 0, 0.2)`],
    fontSize: (minimumFontSize * 1.2).toFixed(2),
    lineHeight: verticalRhythm
  },
  lg: {
    padding: [(verticalRhythm * .8).toFixed(2), 1],
    margin: [(verticalRhythm * .2).toFixed(2), 0],
    border: [`.1rem solid rgba(0, 0, 0, 0.2)`],
    fontSize: (minimumFontSize * 1.4).toFixed(2),
    lineHeight: verticalRhythm
  },
}
