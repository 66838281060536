import React from "react"
import styled from "styled-components"
import { Icon } from "../Icon"

const StyledIconFlip = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledIcon = styled(Icon)<any>`
  border: none;
  margin: 0;
  position: absolute;
  height: 100%;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: all 0.2s ease-out;
  transform: ${(props) => (props.show ? "rotateZ(0)" : "rotateZ(-90deg)")};
  height: 2rem;
  width: 2rem;
`

const IconFlip = ({ toggled, iconOn, iconOff, ...props }) => {
  return (
    <StyledIconFlip {...props}>
      <StyledIcon show={toggled} icon={iconOn} />
      <StyledIcon show={!toggled} icon={iconOff} />
    </StyledIconFlip>
  )
}

export { IconFlip }
