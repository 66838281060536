export const list = {
  group: {
    spacing: '2rem',
    title: {
      fontSize: '1.4rem',
    }
  },
  item: {
    border: 'none',
    padding: '.25em',
    bg: 'transparent',
    bgHover: '#dddddd',
    transition: 'background ease-out .2s'
  }
}
