import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Link } from "gatsby"
import "twin.macro"
import Layout from "components/Layout"
import Seo from "components/SEO"
import Icon from "lib/Icon"
import Container from "components/Container"
import { linkResolver } from "utils/linkResolver"
import { paginationResolver } from "utils/paginationResolver"
import BlogPostFeed from "components/Blog/Feed"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

const StyledContainer = styled(Container)`
  padding: 0 2rem;
`

const Header = styled.div`
  h4 {
    font-size: 2rem;
  }

  h3 {
    margin: 0;
    font-size: 3rem;
    color: ${(props) => props.theme.colors.primary};
    span {
      color: ${(props) => props.theme.colors.gray};
      opacity: 0.5;
    }
  }
`

function Feed({ data, ...props }) {
  if (!data) return null
  const { prismicSiteSettings } = data
  const { data: site_settings } = prismicSiteSettings

  const { prismicPage: page } = data
  const { meta_title, meta_description, meta_images } = page.data
  const uri = linkResolver(page)

  const { nodes: pagePosts } = data.allPrismicBlogPost
  const { currentPage, numPages } = props.pageContext

  const { urlBase, isTagFeed } = paginationResolver(props.path)

  const featuredSlice = page.data.body.find(({ slice_type }) => {
    return slice_type === "featured_blog_posts"
  })

  return (
    <>
      <Seo
        lang={page.lang}
        slug={uri}
        title={meta_title}
        description={meta_description}
        images={meta_images}
      />
      <Layout site_settings={site_settings} lang={"da-dk"}>
        <StyledContainer>
          <Header>
            <Link to="/viden">
              <h4 tw="mt-8 mb-8">
                <Icon icon="long-arrow-left" />
                {` `}Viden forside
              </h4>
            </Link>
            {!isTagFeed ? (
              <h3>
                <span>Side {currentPage}</span>
              </h3>
            ) : (
              <h3>
                <span>Kategori: </span>#{decodeURI(urlBase.split("/")[1])}
                <span>{currentPage > 1 && `, side ${currentPage}`}</span>
              </h3>
            )}
          </Header>
          <BlogPostFeed
            items={pagePosts}
            currentPage={currentPage}
            numPages={numPages}
            featuredSlice={featuredSlice}
            isCategoryFeed
            {...props}
          />
        </StyledContainer>
      </Layout>
    </>
  )
}

export { Feed }
export default withPrismicPreview(Feed)

export const query = graphql`
  query Feed(
    $skip: Int!
    $limit: Int!
    $tags: [String] # $order: [SortOrderEnum]
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicSiteSettings {
      _previewable
      ...SiteNavigation
      ...FooterInformation
      ...FooterNavigation
      ...SocialMediaLinks
      ...UtilityNavigation
    }
    allPrismicBlogPost(
      sort: { fields: data___publication_date, order: DESC }
      limit: $limit
      skip: $skip
      filter: { tags: { in: $tags } }
    ) {
      nodes {
        ...BlogPostFragment
        ...BlogPostBodyFragment
      }
    }
    # harcoded for meta data
    prismicPage(lang: { eq: "da-dk" }, uid: { eq: "viden" }) {
      _previewable
      type
      lang
      uid
      ...FeaturedBlogposts
      data {
        meta_title
        meta_description
        meta_images {
          image {
            dimensions {
              width
              height
            }
            alt
            copyright
            url
            localFile {
              childImageSharp {
                id
                main: fixed(width: 1200) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        show_in_sitemap
      }
    }
  }
`
