import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import styled from "styled-components"
import FormLabel from "../FormLabel"

const StyledFormField = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.white};
  ${tw`text-25xl`}

  & + & {
    margin-top: 2rem;
  }

  input {
    line-height: 1;
    padding-top: ${(props) =>
      `${(props.theme.input[props.size].padding[0] * 2).toFixed(2)}rem`};
    padding-bottom: ${(props) =>
      `${(props.theme.input[props.size].padding[0] * 0.75).toFixed(2)}rem`};
    margin: 0;
    ${tw`text-brand-black-light`}

    &:focus {
      outline: 0;
    }
  }

  textarea {
    resize: vertical;
    ${tw`text-brand-black-light`}
    min-height: min-content;
    &:focus {
      outline: 0;
    }
  }

  select {
    line-height: 1;
    padding-top: ${(props) =>
      `${(props.theme.input[props.size].padding[0] * 2).toFixed(2)}rem`};
    padding-bottom: ${(props) =>
      `${(props.theme.input[props.size].padding[0] * 0.75).toFixed(2)}rem`};
    margin: 0;
    &:focus {
      outline: 0;
    }
  }
`

const StyledFormLabel = styled(FormLabel)`
  position: absolute;
  padding: ${(props) =>
    `${props.theme.input[props.size].padding[0] * 0.3}rem ${
      props.theme.input[props.size].padding[1]
    }rem`};
  pointer-events: none;
  z-index: 50;
`

function FormField({
  children,
  valid,
  errorMsg,
  size,
  label,
  forwardRef,
  ...props
}) {
  return (
    <StyledFormField valid={valid} size={size} ref={forwardRef} {...props}>
      <StyledFormLabel
        size={size}
        error={valid !== null ? !valid : null}
        errorMessage={errorMsg}
      >
        {label}
      </StyledFormLabel>
      {children}
    </StyledFormField>
  )
}

export default FormField

FormField.defaultProps = {
  children: null,
  size: "md",
  valid: null,
  errorMessage: null,
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  valid: PropTypes.bool,
  errorMsg: PropTypes.string,
}
