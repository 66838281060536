import React, { useContext } from "react"
import styled from "styled-components"
import DefaultReactTooltip from "react-tooltip"
import { ThemeContext } from "styled-components"

const StyledReactTooltip = styled(DefaultReactTooltip)`
  z-index: 100000;

  &.styledTooltip {
    background: ${(props) => props.theme.colors[props.color]};
    border-radius: 0;
  }
`

function ReactTooltip({ ...props }) {
  const color = "grayDark"
  const themeContext = useContext(ThemeContext)
  const options = {
    offset: {
      top: 2,
    },
    effect: "solid",
    html: true,
    className: "styledTooltip",
    arrowColor: themeContext.colors[color],
  }

  return <StyledReactTooltip color={color} {...options} {...props} />
}

export default ReactTooltip
