import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"
import Container from "components/Container"
import tw from "twin.macro"
import CTARepeater from "components/CTA/Repeater"
import { htmlSerializer } from "components/HeroSection"

export const colorThemes = {
  White: tw`bg-brand-white pt-8 pb-8 md:(pt-32 pb-20)`,
  Gray: tw`bg-brand-gray-lighter pt-20 pb-12`,
  Red: tw`bg-brand-red-300 text-white`,
  Yellow: tw`bg-brand-yellow-300`,
  Blue: tw`bg-brand-500 text-white`,
  Black: tw`bg-brand-black-base text-white`,
  Brown: tw`bg-brand-brown-400 text-white`,
  Green: tw`bg-brand-green-200 text-white`,
}

const paragraphThemes = {
  White: tw``,
  Gray: tw`text-2xl`,
  Red: tw``,
  Yellow: tw``,
  Blue: tw``,
  Black: tw``,
  Brown: tw``,
  Green: tw``,
}

const StyledWrapper = styled.article`
  display: flex;
  flex-direction: column;

  max-width: 90%;
  margin: 0 auto;

  .title {
    margin-top: 0;
    // max-width: 55rem;

    ${(props) => props.theme.media.tablet_landscape_up`
      text-align: right;
      flex: 0 1 50%;
      margin: 0 auto;
    `}
  }

  &.column {
    .title {
      text-align: center;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    flex-direction: row;
    max-width: none;
    margin: 0 auto;

    &.column {
      display: block;
      margin: 0 auto;

      .title {
        text-align: center;
      }
    }
  `}

  .paragraph {
    p:first-of-type {
      margin-top: 0.6rem;
    }
    ${(props) => props.theme.media.tablet_landscape_up`
      display: flex;
      padding-left: 0;
      margin: 0 auto;

      .content {
        max-width: 60rem;
        flex: 0 1 60rem;
        padding: 0 0 0 4rem;
      }

      .title {
        // padding-right: 3.6rem;
      }
    `}

    ${(props) => props.theme.media.desktop_up`
    `}
  }

  &.home {
    text-align: center;
  }

  &.column {
    .paragraph {
      display: block;
      max-width: 60rem;
      margin: 0.6rem auto 0;

      .content {
        &.center {
          text-align: center;
        }
      }

      ${(props) => props.theme.media.tablet_landscape_up`
        .title {
          padding-right: 0;
        }

        .content {
          max-width: none;
        }
      `}

      ${(props) => props.theme.media.desktop_up`
        max-width: 80rem;
        padding-left: 0;
      `}
    }
  }
`

function Paragraph({
  title_position,
  paragraph_title,
  paragraph_content,
  center_paragraph,
  theme,
  children,
  ctas,
  isHome,
  ...props
}) {
  const wrapperClass: string[] = ["wrapper"]

  if (isHome) wrapperClass.push("home")
  if (title_position !== "Left") wrapperClass.push("column")
  return (
    <Container css={colorThemes[theme ?? "White"]} {...props}>
      <StyledWrapper className={wrapperClass.join(" ")}>
        <div className="paragraph" css={paragraphThemes[theme]}>
          <div className="title">
            <Content
              content={paragraph_title.richText}
              serializer={htmlSerializer}
            />
          </div>
          <div className={center_paragraph ? `content center` : `content`}>
            <Content
              content={paragraph_content.richText}
              serializer={htmlSerializer}
            />
            {ctas && ctas.length > 0 && <CTARepeater items={ctas} />}
          </div>
        </div>
      </StyledWrapper>
    </Container>
  )
}

export default Paragraph

Paragraph.defaultProps = {}
Paragraph.propTypes = {
  children: PropTypes.node,
}
