import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"
import Image from "components/Image"
import Container from "components/Container"

const StyledContentImage = styled.div`
  margin: 8rem 2rem;
  margin-top: 5rem;

  h2 {
    font-size: 170%;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    h2 {
      font-size: 250%;
    }
  `}
`

function ContentImage({ ...props }) {
  const { content, imageSharp } = props
  return (
    <Container>
      <StyledContentImage {...props}>
        <Content content={content} />
        <Image fluid={imageSharp.childImageSharp.fluid} />
      </StyledContentImage>
    </Container>
  )
}

export default ContentImage

ContentImage.defaultProps = {}
ContentImage.propTypes = {}
