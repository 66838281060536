import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Link from "./Link"
import { linkResolver } from "utils/linkResolver"
import { scrollElementIntoView } from "./helper"
import { Responsive } from "@clevertrack/shared"

const StyledCTA = styled(Link)`
  white-space: nowrap;
`

function CTA({ cta_link, cta_link_external, cta_link_text, ...props }) {
  if (cta_link) {
    if (["Document"].includes(cta_link.link_type) && cta_link.document) {
      const link = linkResolver(cta_link.document)
      if (cta_link.document.type === "lead_module") {
        return (
          <StyledCTA
            href={link}
            forwardAs="a"
            onClick={(e) => scrollElementIntoView(e, link)}
            {...props}
          >
            {cta_link_text}
          </StyledCTA>
        )
      }

      return (
        <StyledCTA to={link} {...props}>
          {cta_link_text}
        </StyledCTA>
      )
    } else if (cta_link.link_type === "Web") {
      return cta_link.url.includes("tel:") ? (
        <Responsive
          phone={
            <StyledCTA to={cta_link.url} {...props}>
              {cta_link_text}
            </StyledCTA>
          }
          tabletLandscape={<></>}
        />
      ) : (
        <StyledCTA to={cta_link.url} {...props}>
          {cta_link_text}
        </StyledCTA>
      )
    }
  }

  if (cta_link_external) {
    return (
      <StyledCTA
        to={cta_link_external.url}
        blank={cta_link_external.type === "Link.web"}
        {...props}
      >
        {cta_link_text}
      </StyledCTA>
    )
  }

  return null
}

export default CTA

CTA.defaultProps = {}
CTA.propTypes = {
  children: PropTypes.node,
}
