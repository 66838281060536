import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Headroom from "react-headroom"
import Icon from "lib/Icon"
import tw from "twin.macro"
import { AppContext } from "context"

const StyledHeader = styled.header`
  color: ${(props) => props.theme.colors.primary};
  transition: all ease-out 0.2s;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
  padding: 1rem 2rem;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  .headroom--unfixed & {
  }

  .headroom--scrolled & {
    background: ${(props) => props.theme.colors.white};
  }

  .headroom--unpinned & {
  }

  .headroom--pinned & {
    box-shadow: ${(props) => props.theme.mapButtonShadow};
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }

  &.home {
    background: transparent;
    background-image: linear-gradient(rgba(0, 0, 0, 0.04), transparent);

    .headroom--scrolled & {
      background: ${(props) => props.theme.colors.white};
    }

    .headroom--unfixed & {
      button {
        background: transparent;
        font-weight: 600;
      }

      button:active {
        box-shadow: none;
      }

      #loginLink {
        color: white;
        font-weight: 600;
      }

      #loginLink:hover,
      button:hover {
        color: ${(props) => props.theme.colors.grayDark};
      }
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0;
    // min-height: 12rem;
    display: grid;
    align-items: center;
    transition: min-height ease-out 0.2s;
    // padding-bottom: 0.75rem;

    .headroom--pinned &,
    .headroom--scrolled & {
      min-height: 8rem;
    }
  `};
`

const StyledHeaderInner = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  ${(props) => props.theme.media.tablet_landscape_up`
    width: 90%;
    margin: 0 auto;
    max-width: 120rem;
  `}
`

const StyledLogo = styled(Link)`
  display: flex;
  align-items: center;

  svg {
    width: 16.05rem;
    height: 4.2rem;
    fill: current;

    .headroom--scrolled & {
      fill: current;
    }

    ${tw`text-brand-500`}

    .home & {
      fill: ${(props) => props.theme.colors.white};

      /* .headroom--scrolled & {
        fill: ${(props) => props.theme.colors.secondary};
      } */

      &.fill-logo {
        opacity: 1;
      }
      &.color-logo {
        display: none;
      }
      .headroom--scrolled & {
        &.fill-logo {
          opacity: 0;
        }
        &.color-logo {
          display: initial;
        }
      }
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding-bottom: 0.8rem;
    svg {
      width: 24.05rem;
      height: 6.2rem;
      transition: width ease-out 0.2s;
    }

    .headroom--unpinned &,
    .headroom--pinned &,
    .headroom--scrolled & {
      svg {
        width: 18.05rem;
        height: 4.2rem;
        position: relative;
        bottom: 1rem;
        fill: ${props.theme.colors.secondary};
      }
    }
  `}
`

function Header({ lang, isHome, children, ...props }) {
  const {
    state: { disableMenuPin },
  } = useContext(AppContext)
  return (
    <Headroom style={{ zIndex: "1000" }} disable={disableMenuPin}>
      <StyledHeader className={isHome ? "home" : ""}>
        <StyledHeaderInner>
          <StyledLogo to={lang === "en-gb" ? "/en" : "/"}>
            <Icon icon="logo" title="Clevertrack Logo" className="color-logo" />
          </StyledLogo>
          {children}
        </StyledHeaderInner>
      </StyledHeader>
    </Headroom>
  )
}

export default Header
