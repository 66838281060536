import React from "react"
import styled from "styled-components"
import { Elements } from "prismic-reactjs"

const colorFromIndex = (index) => {
  const colors = ["green", "yellow", "red", "purple", "blue"]
  const length = colors.length - 1
  return index > length ? colors[length] : colors[index]
}

const StyledStatus = styled.div`
  background: ${(props) => props.theme.colors[colorFromIndex(props.index)]};
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
`

const StyledListItem = styled.li`
  list-style: none;
  text-align: left;
  position: relative;
  padding-left: 2rem;
`

export default function (type, element, content, children, key) {
  switch (type) {
    case Elements.oListItem:
      return (
        <StyledListItem key={`colorlist_${key}`}>
          <StyledStatus index={key} />
          {children}
        </StyledListItem>
      )
    default:
      return null
  }
}
