import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Container from "components/Container"

const StyledContainer = styled(Container)`
  padding: 2rem;
  max-width: 120rem;
  margin: auto;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0;
  `}
`

function BlogContent({ children, ...props }) {
  return <StyledContainer {...props}>{children}</StyledContainer>
}

export default BlogContent

BlogContent.propTypes = {
  children: PropTypes.node.isRequired,
}
