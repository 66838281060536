import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { linkResolver } from "utils/linkResolver"
import Content from "components/Content"
import Image from "components/Image"
import Tag from "components/Blog/Tag"
import { getTopTags, slicesToText } from "components/Blog/util"
import tw from "twin.macro"

const StyledHeroImage = styled(Image)`
  height: 40vw;
  ${(props) => props.theme.media.tablet_landscape_up`
    height: 20rem;
  `}
`

const StyledBoxPreview = styled.div`
  position: relative;
  .title {
    position: absolute;
    top: 0;
    color: ${(props) => props.theme.colors.white};
    padding: 1rem;
    padding-bottom: 0;
    > * {
      margin: 0;
      font-size: 2.2rem;
      line-height: 2.7rem;
      font-family: ${(props) => props.theme.fontStacks.bold};
    }
  }
  .thumbnail {
    position: relative;
    .tags {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1rem;
    }
  }
  .summary {
    white-space: pre-wrap; // preserve line breaks
    margin-top: 1rem;
    color: ${(props) => props.theme.colors.gray};
    line-height: 2rem;
    /* ------- multiline ellipsis ------- */
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    /* ---------------------------------- */
  }
  .readmore {
    font-family: ${(props) => props.theme.fontStacks.bold};
    /* text-align: center; */
    > * {
      display: inline-block;
      padding: 5px 0;
      padding-right: 2rem;
    }
  }
`

const Gradient = styled.div`
  opacity: 0.8;
  top: 0;
  background: linear-gradient(
    ${(props) => props.theme.colors.blackLight},
    ${(props) => props.theme.colors.blackLight},
    transparent,
    transparent,
    ${(props) => props.theme.colors.blackLight}
  );
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  transition: 100ms;
  &:hover {
    background: ${(props) => props.theme.colors.primary};
    transition: none;
  }
`

function BoxPreview({ post, ...props }) {
  const { hero_image } = post.data
  const { body: post_body } = post.data
  const postTags = getTopTags(post.tags)
  const bodyText = slicesToText(post_body)
  return (
    <StyledBoxPreview {...props}>
      <div className="thumbnail">
        <StyledHeroImage fluid={hero_image.fluid} />
        <Link to={linkResolver(post)}>
          <Gradient />
        </Link>
        <div className="tags">
          {postTags.map((tag, i) => {
            return (
              <Tag key={`post_tags_${i}`} filled>
                #{tag}
              </Tag>
            )
          })}
        </div>
      </div>
      <div className="title">
        <Content content={post.data.title.richText} />
      </div>
      <div className="summary">{bodyText}</div>
      <div className="readmore">
        <Link to={linkResolver(post)}>Læs artikel</Link>
      </div>
    </StyledBoxPreview>
  )
}

export default BoxPreview
