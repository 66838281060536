/**
 * Default validation keys
 */

const getInvalidReasons = ({
  badInput,
  customError,
  patternMismatch,
  rangeOverflow,
  rangeUnderflow,
  stepMismatch,
  tooLong,
  tooShort,
  typeMismatch,
  valueMissing,
}) => {
  return {
    badInput,
    customError,
    patternMismatch,
    rangeOverflow,
    rangeUnderflow,
    stepMismatch,
    tooLong,
    tooShort,
    typeMismatch,
    valueMissing,
  }
}

const handleInputValidation = (
  element,
  validationMessages,
  customValidationFunction
) => {
  const messages = validationMessages
  if (customValidationFunction && element.value.length > 0) {
    const valid = customValidationFunction(element.value)
    return {
      valid: valid,
      message: valid ? null : messages['customError'],
    }
  }
  // Otherwise default to default HTML5 client side validation
  const { validity } = element
  const invalidReasons = getInvalidReasons(validity)
  const returnValue = {
    valid: validity.valid,
    message: null,
  }
  // If not valid, figure out the reason why.
  if (!validity.valid) {
    const invalidReason = Object.keys(invalidReasons).filter(
      reason => invalidReasons[reason]
    )
    returnValue.message = messages[invalidReason[0]]
  }
  return returnValue
}

export { handleInputValidation }
