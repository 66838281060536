import { theme } from "lib/ui/theme"
import CustomGlobalStyles from "./global"
import AppGlobalStyles from "./app-global"
import { buttons } from "./buttons"
import { colors, statusColors } from "./colors"
import { fonts, fontStacks } from "./fonts"
import { tags } from "./tags"

export enum SizeEnum {
  SearchBar = 7,
  Tabs = 3,
  TapBarHeight = 6.7,
  MobileHeader = 3,
  DesktopMenu = 8,
  TrackerSearchTitleHeight = 2.1,
}

const siteTheme = {
  ...theme,
  verticalRhythm: 2.4,
  colors,
  fonts,
  fontStacks,
  buttonStyles: buttons,
  baseBodyColor: `#333333`,
  linkColor: colors.primary,
  mobileContentOffset: "8.8rem",
  statusColors,
  tags,
  mapButtonShadow: `0px 1px 4px -1px rgba(0, 0, 0, 0.3)`,
  inputFocusShadow: `0px 2px 4px -2px rgba(0, 0, 0, 0.3)`,
  fontSizes: {
    p: `1.6rem`,
    h1: `4.0`,
    h2: `3.4`,
    h3: `3.0`,
    h4: `2.6`,
    h5: `2.4`,
    h6: `1.8`,
  },
  checkbox: {
    ...theme.checkbox,
    bg: colors.white,
    bgActive: colors.secondary,
    iconColor: colors.white,
    border: `1px solid rgba(0, 0, 0, 0.2)`,
  },
  datepicker: {
    ...theme.datepicker,
    isDisabled: {
      color: "#aeaeae",
    },
  },
}

export { siteTheme, CustomGlobalStyles, AppGlobalStyles }
