import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import tw from "twin.macro"
import styled from "styled-components"
import ResponsiveImage from "components/ResponsiveImage"

const StyledPerson = styled.article`
  position: relative;
  z-index: 50;

  ${tw`md:px-8`}

  .info {
    // padding: 0 2rem;
    text-align: left;

    h3 {
      ${tw`text-3xl`}
      margin-bottom: 0;
    }

    p {
      ${tw`text-2xl`}
      margin: 0;
    }
  }

  .headshot {
    width: auto;
    height: 20rem;
    display: block;
    clip-path: circle(75%);
  }
`

const StyledResponsiveImage = styled(ResponsiveImage)`
  > img {
    /* polygon(0 0%, 100% 7%, 100% 100%, 0% 93%) */
  }
`

function Person({
  image,
  first_name,
  last_name,
  role,
  areas_of_work,
  children,
  ...props
}) {
  return (
    <StyledPerson {...props}>
      <div className="headshot">
        <StyledResponsiveImage img={image} />
      </div>
      {first_name && (
        <div className="info">
          {first_name && last_name && <h3>{`${first_name} ${last_name}`}</h3>}
          {
            <p>
              {!!role ? role : ""}
              {!!areas_of_work ? `, ${areas_of_work}` : ""}
            </p>
          }
          {children}
        </div>
      )}
    </StyledPerson>
  )
}

export default Person

Person.defaultProps = {}
Person.propTypes = {}

export const query = graphql`
  fragment PersonFragment on PrismicPerson {
    data {
      first_name
      last_name
      email
      areas_of_work
      phone
      role
      bio {
        richText
      }
      social_media_links {
        icon {
          url
        }
        social_media_profile_url {
          url
        }
        social_media_site
      }
      image {
        dimensions {
          width
          height
        }
        alt
        copyright
        url
        # localFile @include(if: $isProduction) {
        localFile {
          childImageSharp {
            id
            desktop: fixed(width: 480) {
              ...GatsbyImageSharpFixed
            }
            mobile: fixed(width: 480) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
