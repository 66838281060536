import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import Seo from "components/SEO"
import SliceZone from "components/SliceZone"
import { linkResolver } from "utils/linkResolver"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

function Page({ data, ...props }) {
  if (!data) return null
  const { site, prismicSiteSettings, prismicPage: page } = data
  const { data: site_settings } = prismicSiteSettings
  const { body } = page.data
  const { meta_title, meta_description, meta_images } = page.data
  const { siteUrl } = site.siteMetadata
  const uri = linkResolver(page)

  const { nodes: blogPosts } = data.allPrismicBlogPost
  const { numPages } = props.pageContext

  // console.log(props, data)

  return (
    <>
      <Seo
        lang={page.lang}
        slug={uri}
        title={meta_title}
        description={meta_description}
        images={meta_images}
      />
      <Layout site_settings={site_settings} lang={page.lang}>
        <SliceZone
          slices={body}
          pageInfo={{ uri, host: siteUrl, title: page.data.title.text }}
          firstBlogPosts={blogPosts}
          numPages={numPages}
          {...props}
        />
      </Layout>
    </>
  )
}

export { Page }
export default withPrismicPreview(Page)

export const query = graphql`
  # query PrismicPage($lang: String, $uid: String!, $isProduction: Boolean!) {
  # $limit is used for the first blog page only to limit the number of posts
  query PrismicPage($lang: String, $uid: String!, $limit: Int!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicSiteSettings {
      _previewable
      ...SiteNavigation
      ...FooterInformation
      ...FooterNavigation
      ...SocialMediaLinks
      ...UtilityNavigation
    }
    # for the first blog page only
    allPrismicBlogPost(
      sort: { fields: data___publication_date, order: DESC }
      limit: $limit
      skip: 0
    ) {
      nodes {
        ...BlogPostFragment
        ...BlogPostBodyFragment
      }
    }
    # for any page (including first blog page)
    prismicPage(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      type
      lang
      uid
      ...FeaturedBlogposts
      data {
        title {
          richText
          text
        }
        meta_title
        meta_description
        meta_images {
          image {
            dimensions {
              width
              height
            }
            alt
            copyright
            url
            localFile {
              childImageSharp {
                id
                main: fixed(width: 1200) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        show_in_sitemap
        body {
          ... on PrismicPageDataBodyParagraph {
            slice_type
            primary {
              paragraph_title {
                richText
              }
              paragraph_content {
                richText
              }
              center_paragraph
              bullet_color
              bullet_background_color
              bullet_icon_alt {
                url
              }
            }
          }
          ... on PrismicPageDataBodyBlogposts {
            ...PageRelatedBlogposts
          }
          ... on PrismicPageDataBodyTestimonial {
            ...TestimonialPageFragment
          }
          ... on PrismicPageDataBodyParagraphImage {
            slice_type
            primary {
              theme
              image_position
              content {
                richText
              }
              bullet_color
              bullet_background_color
              bullet_icon_alt {
                url
              }
              paragraph_image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    id
                    desktop: gatsbyImageData(
                      layout: FIXED
                      width: 960
                      height: 960
                    )
                    mobile: gatsbyImageData(
                      layout: FIXED
                      width: 400
                      height: 400
                    )
                    wide: gatsbyImageData(
                      layout: FIXED
                      width: 1920
                      height: 1200
                    )
                    square: gatsbyImageData(
                      layout: FIXED
                      width: 960
                      height: 960
                    )
                  }
                }
              }
              paragraph_image_style
            }
            items {
              cta_link {
                link_type
                document {
                  ... on PrismicLeadModule {
                    id
                    type
                    uid
                    lang
                  }
                  ... on PrismicPage {
                    uid
                    type
                    lang
                    id
                  }
                }
                url
              }
              cta_link_text
            }
          }
          ... on PrismicPageDataBodyIllustratedUsp {
            slice_type
            primary {
              illustration_position
              illustration {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    id
                    desktop: gatsbyImageData(layout: FIXED, width: 600)
                    mobile: gatsbyImageData(layout: FIXED, width: 400)
                  }
                }
              }
              usp {
                document {
                  ... on PrismicUsp {
                    ...USPFragment
                  }
                }
              }
            }
            items {
              cta_link {
                link_type
                document {
                  ... on PrismicLeadModule {
                    id
                    type
                    uid
                    lang
                  }
                  ... on PrismicPage {
                    id
                    uid
                    type
                    lang
                  }
                }
              }
              cta_link_text
            }
          }
          ... on PrismicPageDataBodyUspRepeater {
            slice_type
            primary {
              theme
              paragraph_title {
                richText
              }
              paragraph_content {
                richText
              }
              paragraph_content_footer {
                richText
              }
              columns
              use_cards
            }
            items {
              use_counter
              unit
              unit_money
              count_to
              usp_title {
                richText
              }
              content {
                richText
              }
              content_footer {
                richText
              }
              usp_icon {
                url
              }
              center_content
            }
          }
          ... on PrismicPageDataBodyHeroSection {
            slice_type
            primary {
              theme
              bullets {
                richText
              }
              bullet_color
              bullet_background_color
              bullet_icon_alt {
                url
              }
              content {
                richText
              }
              headline {
                richText
              }
              pre_headline
              image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    id
                    desktop: gatsbyImageData(layout: FIXED, width: 960)
                    mobile: gatsbyImageData(layout: FIXED, width: 480)
                    wide: gatsbyImageData(
                      layout: FIXED
                      width: 1920
                      height: 1200
                    )
                    square: gatsbyImageData(
                      layout: FIXED
                      width: 960
                      height: 960
                    )
                  }
                }
              }
              background_image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    id
                    desktop: gatsbyImageData(layout: FIXED, width: 960)
                    mobile: gatsbyImageData(layout: FIXED, width: 480)
                    wide: gatsbyImageData(
                      layout: FIXED
                      width: 1920
                      height: 1200
                    )
                    square: gatsbyImageData(
                      layout: FIXED
                      width: 960
                      height: 960
                    )
                  }
                }
              }
              background_video {
                url
              }
            }
            items {
              cta_link {
                link_type
                document {
                  ... on PrismicLeadModule {
                    id
                    type
                    uid
                    lang
                  }
                  ... on PrismicPage {
                    id
                    uid
                    type
                    lang
                  }
                }
                url
              }
              cta_link_text
              cta_link_external {
                target
                url
                link_type
              }
            }
          }
          ... on PrismicPageDataBodyLeadCta {
            slice_type
            primary {
              use_theme
              theme
              layout
              lead_title {
                richText
              }
              lead_content {
                richText
              }
              lead_module {
                document {
                  ... on PrismicLeadModule {
                    ...LeadModule
                  }
                }
              }
              person {
                document {
                  ... on PrismicPerson {
                    ...PersonFragment
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyFeatureRepeater {
            slice_type
            primary {
              theme
              features_content {
                richText
              }
              features_title {
                richText
              }
            }
            items {
              feature_description {
                richText
              }
              feature_icon {
                url
              }
            }
          }
          ... on PrismicPageDataBodyFeatureComparison {
            slice_type
            primary {
              product_features_title {
                richText
              }
              feature_column_title
              feature_not_in_product {
                richText
              }
            }
            items {
              product_feature {
                document {
                  ... on PrismicProductFeature {
                    id
                    data {
                      title
                      description {
                        richText
                      }
                      products {
                        product {
                          document {
                            ... on PrismicProduct {
                              ...ProductFragment
                            }
                          }
                        }
                        product_feature_description {
                          richText
                        }
                        supported
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyPersonCta {
            slice_type
            primary {
              person {
                document {
                  ... on PrismicPerson {
                    ...PersonFragment
                  }
                }
              }
              person_cta_content {
                richText
              }
              person_cta_title {
                richText
              }
            }
            items {
              person_cta_action
              person_cta_link_text
            }
          }
          ... on PrismicPageDataBodyPersonRepeater {
            slice_type
            primary {
              persons_content {
                richText
              }
              persons_title {
                richText
              }
            }
            items {
              person {
                document {
                  ... on PrismicPerson {
                    ...PersonFragment
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyParagraph {
            slice_type
            primary {
              theme
              title_position
              paragraph_title {
                richText
              }
              paragraph_content {
                richText
              }
            }
            items {
              cta_link {
                link_type
                document {
                  ... on PrismicLeadModule {
                    id
                    type
                    uid
                    lang
                  }
                  ... on PrismicPage {
                    uid
                    type
                    lang
                    id
                  }
                }
                url
              }
              cta_link_text
            }
          }
          ... on PrismicPageDataBodyClientQuotations {
            id
            slice_type
            primary {
              headline: heading {
                richText
              }
            }
            items {
              client_quotation {
                document {
                  ... on PrismicClientQuotation {
                    ...ClientQuotationFragment
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyImageRepeater {
            slice_type
            primary {
              direction
              images_content {
                richText
              }
              images_title {
                text
                richText
              }
              shuffle_text
            }
            items {
              image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    id
                    desktop: gatsbyImageData(
                      layout: FIXED
                      width: 300
                      formats: [WEBP]
                    )
                    mobile: gatsbyImageData(
                      layout: FIXED
                      width: 300
                      formats: [WEBP]
                    )
                  }
                }
              }
              description {
                richText
                text
              }
            }
          }
          ... on PrismicPageDataBodyCardRepeater {
            slice_type
            primary {
              columns
              card_repeater_title {
                richText
              }
              card_repeater_content_after {
                richText
                text
              }
              card_repeater_content {
                richText
              }
            }
            items {
              content {
                document {
                  ... on PrismicProduct {
                    ...ProductFragment
                  }
                }
              }
              emphasize: emphasize_
              display_style
              cta_text
              background_image {
                alt
                copyright
                url
              }
            }
          }
          ... on PrismicPageDataBodyFaqRepeater {
            slice_type
            primary {
              faq_repeater_title {
                richText
              }
              faq_repeater_content {
                richText
              }
            }
            items {
              faq_link {
                document {
                  ... on PrismicFaq {
                    data {
                      question {
                        richText
                      }
                      answer {
                        richText
                      }
                    }
                    id
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyTitle {
            slice_type
            primary {
              content_title {
                richText
              }
              content {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyButton {
            slice_type
            primary {
              type
              button_text
              page_link {
                document {
                  ... on PrismicPage {
                    id
                    type
                    lang
                    uid
                  }
                  ... on PrismicIndex {
                    id
                    type
                    lang
                    uid
                  }
                  ... on PrismicLeadModule {
                    id
                    type
                    uid
                    lang
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyPartnerRepeater {
            slice_type
            primary {
              partners_title {
                richText
                text
              }
              content {
                richText
              }
              shuffle_text: shuffle_test
            }
            items {
              partner_name
              partner_link {
                url
                target
                link_type
              }
              partner_logo_image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    desktop: gatsbyImageData(
                      layout: FIXED
                      width: 412
                      formats: [WEBP]
                    )
                    mobile: gatsbyImageData(
                      layout: FIXED
                      width: 270
                      formats: [WEBP]
                    )
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyAddonProducts {
            slice_type
            primary {
              addons_title {
                richText
              }
              button_text
              legal_footer {
                richText
                text
              }
              cta_link {
                link_type
                document {
                  ... on PrismicLeadModule {
                    id
                    type
                    uid
                    lang
                  }
                  ... on PrismicPage {
                    id
                    uid
                    type
                    lang
                  }
                }
                url
              }
              cta_link_text
            }
            items {
              image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              icon {
                url
              }
              addon_title {
                richText
              }
              addon_benefits {
                richText
              }
              price
              payment_term
              link {
                document {
                  ... on PrismicPage {
                    id
                    type
                    lang
                    uid
                  }
                  ... on PrismicIndex {
                    id
                    type
                    lang
                    uid
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyFeaturedBlogPosts {
            slice_type
            primary {
              featured_tags
              hero_post {
                document {
                  ... on PrismicBlogPost {
                    ...BlogPostFragment
                  }
                }
              }
            }
            items {
              featured_extras {
                document {
                  ... on PrismicBlogPost {
                    ...BlogPostFragment
                    ...BlogPostBodyFragment
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyProductPresentation {
            ...ProductPresentationPageFragment
          }
          ... on PrismicPageDataBodyBlogPostFeed {
            slice_type
            primary {
              sidebar_content {
                richText
              }
            }
          }
        }
      }
    }
  }
`
