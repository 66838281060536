import { verticalRhythm } from '../typography'

export const card = {
  boxShadow: `0 .2rem .5rem rgba(0, 0, 0, 0.2)`,
  border: [`.1rem solid transparent`],
  borderRadius: `0`,
  sm: {
    padding: [(verticalRhythm).toFixed(2)]
  },
  md: {
    padding: [(verticalRhythm * 2).toFixed(2)]
  },
  lg: {
    padding: [(verticalRhythm * 3).toFixed(2)]
  },
}
