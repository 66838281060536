import React, { useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useOnScreen } from "hooks/useOnScreen"
import tw from "twin.macro"

const StyledButton = styled.button`
  border: none;
  display: inline-block;
  align-items: center;
  line-height: ${(props) => `${props.theme.button[props.size].lineHeight}rem`};
  ${tw`font-bold`}
  background: ${(props) => props.theme.button.bg};
  color: ${(props) => props.theme.button.color};
  cursor: pointer;
  text-transform: none;
  *::first-letter {
    text-transform: uppercase;
  }

  ${(props) => {
    switch (props.size) {
      case "sm":
        return tw`text-xl`
      case "lg":
        return tw`text-3xl`
      default:
        return tw`text-2xl`
    }
  }}

  ${(props) =>
    props.theme.utils.getBoxModel(
      props.theme.button[props.size].padding,
      props.theme.button[props.size].border,
      props.theme.button[props.size].margin
    )}

  ${(props) =>
    props.appearance === "link" &&
    `
      padding-left: 0;
      padding-right: 0;
      background: none;
      color: ${props.theme.button.bg};
    `}

  /* Link button styling */
  ${(props) =>
    props.as === "a" &&
    `
      // display: inline-block;
      text-decoration: none;

      &:hover {
        color: white;
      }
    `}

  /* Icon Link button styling */
  ${(props) =>
    props.as === "a" &&
    props.icon !== null &&
    `
      // display: inline-block;
    `}

  /* Icon button styling */
  ${(props) =>
    props.icon !== null &&
    `
    ${props.theme.utils.getBoxModel(
      props.theme.button.getSize(
        props.theme.button[props.size].padding,
        props.icon
      ),
      props.theme.button[props.size].border,
      props.theme.button[props.size].margin
    )};
  `}

  svg:first-child {
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  svg:last-child {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  svg:only-child {
    margin: 0;
  }
`

const StyledButtonInner = styled.span`
  display: flex;
  align-items: center;
`

function Button({ children, ...props }) {
  const btnRef = useRef<HTMLButtonElement>()
  const isOnScreen = useOnScreen(btnRef)
  return (
    <StyledButton ref={btnRef} tabIndex={!isOnScreen ? "-1" : ""} {...props}>
      <StyledButtonInner>{children}</StyledButtonInner>
    </StyledButton>
  )
}

export default Button

Button.defaultProps = {
  size: "md",
  icon: null,
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  appearance: PropTypes.oneOf(["link", "button"]),
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(["left", "right"]),
  ]),
}
