import React from "react"
import Icon, { IconSizeEnum } from "lib/Icon"
import tw from "twin.macro"
import styled from "styled-components"
import { Logo } from "../Logo"
import { siteTheme as theme } from "theme"

const StyledLoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${tw`bg-gray-200 bg-opacity-50`}
`

const StyledLoading = styled.div`
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg:last-of-type {
    // opacity: 0.5;
    transform: rotateZ(0deg);
    animation: rotate 0.25s linear infinite forwards;
  }

  p {
    font-family: ${theme.fontStacks.subheading};
    // opacity: 0.5;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1rem;
  }

  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }

    /* 25% {
      transform: rotateZ(180deg);
    } */

    50% {
      transform: rotateZ(180deg);
    }

    /* 75% {
      transform: rotateZ(360deg);
    } */

    100% {
      transform: rotateZ(360deg);
    }
  }
`

interface LoadingProps {
  children?: React.ReactNode
  withLogo?: boolean
  icon?: string
  loadingText?: string
}

const Loading: React.FC<LoadingProps> = ({
  children,
  withLogo = false,
  icon = "spinner",
  loadingText = "Indlæser",
  ...props
}) => {
  return (
    <StyledLoadingWrapper {...props}>
      {withLogo && <Logo colored />}
      <StyledLoading>
        <Icon tw="w-12 h-12" size={IconSizeEnum.LG} icon={icon} />
        <p>
          <small>{loadingText}</small>
        </p>
      </StyledLoading>
    </StyledLoadingWrapper>
  )
}

export { Loading }
