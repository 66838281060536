import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { linkResolver } from "utils/linkResolver"
import { graphql, Link } from "gatsby"
import { Button, Radar } from "@clevertrack/shared"
import "twin.macro"

const StyledUtilityBar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // padding-left: 0.5rem;
  // padding-bottom: 2rem;
  // margin-bottom: 0.5rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    justify-content: flex-end;
    padding-left: 3rem;
    border: 0;
    padding: 0;
    margin: 0;
    margin-top: 2rem;
  `};
`

const UtilityItem = css`
  margin: 0;
  margin-right: 1rem;
  padding: 0.4rem 1.75rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5;
`

const StyledButton = styled(Button)`
  ${UtilityItem}
  &.demo {
    padding-right: 3.5rem;
    position: relative;
    color: #fff;
  }
`

const StyledLink = styled(Link)`
  ${UtilityItem}
  color: ${(props) => props.theme.colors.grayDark};
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

const appLink = () => {
  switch (process.env.GATSBY_ACTIVE_ENV) {
    case "dev":
      return "localhost:8001"
    case "staging":
      return "app.staging.clevertrack.dk"
    case "production":
      return "app.clevertrack.dk"
  }
}

function UtilityBar({ nav, ...props }) {
  const { demo_button_text, demo_link } = nav.document.data

  return (
    <StyledUtilityBar {...props}>
      <StyledLink id="loginLink" draggable="false" to={`https://${appLink()}`}>
        Login
      </StyledLink>
      <StyledButton
        className="demo"
        tw="bg-brand-500 flex items-center"
        as={Link}
        to={linkResolver(demo_link.document)}
      >
        {demo_button_text}
        <span tw="absolute right-4 w-4 h-4">
          <Radar />
        </span>
      </StyledButton>
    </StyledUtilityBar>
  )
}

export default UtilityBar

export const query = graphql`
  fragment UtilityNavigation on PrismicSiteSettings {
    data {
      utility_navigation {
        document {
          ... on PrismicUtilityNavigation {
            data {
              demo_button_text
              demo_link {
                document {
                  ... on PrismicPage {
                    data {
                      title {
                        richText
                      }
                    }
                    id
                    lang
                    uid
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// vvvvvvvvvvvv
// LEGACY QUERY --------------------------------
// vvvvvvvvvvvv
//
// export const query = graphql`
//   fragment UtilityNavigation on PRISMIC_Site_settings {
//     utility_navigation {
//       ... on PRISMIC_Utility_navigation {
//         demo_button_text
//         demo_link {
//           ... on PRISMIC_Page {
//             title
//             _meta {
//               id
//               lang
//               uid
//               type
//             }
//           }
//         }
//       }
//     }
//   }
// `

UtilityBar.defaultProps = {}
UtilityBar.propTypes = {}
