import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import USP from "./"
import Container from "components/Container"
import { getIconFromUrl } from "utils/iconResolver"
import Content from "components/Content"
import { htmlSerializer } from "components/HeroSection"
import { colorThemes } from "components/Paragraph"
import { USPCounter } from "./Counter"

const StyledContainer = styled(Container)`
  padding: 4rem 2rem;

  h1,
  h2 {
    // max-width: 70rem;
    // margin: 0 auto;
  }

  ${(props) => props.theme.media.tablet_landscape_up`

    h1 {
      font-size: 4.8rem;
      max-width: none;
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    h1 {
      font-size: 6.0rem;
    }
  `}
`

const StyledUSPRepeater = styled.section`
  display: grid;
  padding: 4rem 0;
  grid-row-gap: 2rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    margin: 0 auto;
    max-width: 100%;
    padding: 6rem;
    grid-template-columns: repeat(${props.columns}, minmax(20%, 50%));
    grid-column-gap: 5%;
  `}
`

const StyledUSP = styled(USP)`
  h2 {
    font-size: 1.7rem;
    line-height: 2.8rem;
  }

  p {
    font-size: 1.7rem;
    line-height: 2.8rem;
  }

  div {
    margin-top: 2rem;
    padding: 2rem 0;
    border-top: 1px solid ${(props) => props.theme.colors.grayLighter};

    h3 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }
`

function USPRepeater({
  items,
  use_cards,
  columns,
  paragraph_title,
  paragraph_content,
  paragraph_content_footer,
  theme,
  ...props
}) {
  return (
    <StyledContainer css={colorThemes[theme ?? "White"]}>
      <div tw="md:(text-center max-w-4xl mx-auto) lg:(max-w-none)">
        <Content
          content={paragraph_title.richText}
          serializer={htmlSerializer}
        />
        <div tw="md:(w-5/6 mx-auto)">
          <Content content={paragraph_content.richText} />
        </div>
      </div>
      <StyledUSPRepeater columns={columns || 3}>
        {items.map(({ usp_icon, use_counter, ...usp }, index) => {
          const icon = getIconFromUrl(usp_icon?.url)
          return use_counter ? (
            <USPCounter key={`usp_${index}`} {...usp} />
          ) : (
            <StyledUSP key={`usp_${index}`} icon={icon} {...usp} />
          )
        })}
      </StyledUSPRepeater>
      <div tw="lg:(text-center)">
        <Content
          content={paragraph_content_footer.richText}
          serializer={htmlSerializer}
        />
      </div>
    </StyledContainer>
  )
}

export default USPRepeater

USPRepeater.defaultProps = {
  columns: 3,
}
USPRepeater.propTypes = {
  columns: PropTypes.number,
  children: PropTypes.node,
}
