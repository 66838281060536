import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { linkResolver } from "utils/linkResolver"
import Container from "components/Container"
import Content from "components/Content"
import Image from "components/Image"
import Tag from "../Tag"
import Featured from "components/Blog/Previews/Featured"
import { getTopTags } from "../util"
import tw from "twin.macro"
import { Button, Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"

const StyledFeaturedPosts = styled.div`
  ${tw`bg-white mt-8 md:(mt-0)`}
`

const StyledHeroImage = styled(Image)`
  // height: 40rem;
  ${(props) => props.theme.media.tablet_landscape_up`
    width: auto;
    height: 100%;
  `}
`

const Hero = styled.div`
  position: relative;

  ${tw`mt-8 mb-16`}
  ${(props) => props.theme.media.desktop_up`
    height: 60vh;
    max-height: 60rem;
    margin-bottom: 3rem;
  `}

  .image {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;

    > a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }
`

const HeroContent = styled.div`
  position: relative;
  z-index: 20;
  background: rgba(0, 0, 0, 0.25);
  padding: 2rem;
  pointer-events: none;
  ${tw`text-white w-full h-full flex items-center`}

  .summary {
    max-width: 60rem;
    ${tw`text-4xl`}
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 2rem 3rem;
  `}

  .hero-title-area {
    ${tw`flex flex-col`}
    .hero-title {
      padding-right: 2rem;
      > * {
        font-family: ${(props) => props.theme.fontStacks.bold};
        font-size: 3rem;
        line-height: 3.4rem;
        max-width: 70rem;
        ${(props) => props.theme.media.tablet_landscape_up`
          font-size: 4.2rem;
          line-height: 4.8rem;
        `}
      }
    }
    .tags {
      padding: 1rem 0;
      display: inline-block;
      pointer-events: all;
    }
  }
`

const HeroContentContainer = styled(Container)`
  ${(props) => props.theme.media.tablet_landscape_up`
      margin: 0 auto;
      width: 100%;
      > div {
        // width: 100%;
        // max-width: 60rem;
      }
  `}
`

const FeaturedText = styled.span`
  pointer-events: none;
  font-family: ${(props) => props.theme.fontStacks.bold};
  ${tw`bg-brand-500 py-1 px-4 text-white mb-8`}

  ${(props) => props.theme.media.tablet_landscape_up`
  `}
`

const NormalizedContent = styled.div`
  > * {
    margin: 0;
  }
`

const PreviewsLayout = styled.div`
  /* grid-template-columns: repeat(auto-fit, minmax(min(30rem, 100%), 1fr)); */
  padding: 0 2rem;
  > * {
    margin-bottom: 2rem;
  }
  ${(props) => props.theme.media.tablet_landscape_up`
    > * {
      margin-bottom: 0rem;
    }

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  `}
  ${(props) => props.theme.media.desktop_up`
    padding: 0;
    grid-gap: 3rem;
  `}
`

const Sidebar = styled.div``

function FeaturedPosts({ content, items, ...props }) {
  const {
    hero_post: { document: hero_post },
  } = props

  const { hero_image } = hero_post.data
  const heroTags = getTopTags(hero_post.tags)

  return (
    <StyledFeaturedPosts {...props}>
      <Hero>
        <div className="image">
          <Link to={linkResolver(hero_post)} />
          <StyledHeroImage fluid={hero_image.fluid} />
        </div>
        <HeroContent>
          <HeroContentContainer>
            <FeaturedText>Fremhævet</FeaturedText>
            <div className="hero-title-area">
              <div className="tags">
                {heroTags.map((tag, i) => {
                  return (
                    <Tag key={`hero_tag_${i}`} size="lg" filled>
                      #{tag}
                    </Tag>
                  )
                })}
              </div>
              <NormalizedContent className="hero-title">
                <Content content={hero_post.data.title.richText} />
              </NormalizedContent>
            </div>
            <p className="summary">{hero_post.data.summary}</p>
            <Button
              variant="primary"
              to={linkResolver(hero_post)}
              tw="flex items-center text-2xl font-bold pr-6"
            >
              <span tw="mr-2">Læs artikel</span>
              <Icon icon="chevron-right" size={IconSizeEnum.SM} />
            </Button>
          </HeroContentContainer>
        </HeroContent>
      </Hero>
      <Container>
        <div>
          <PreviewsLayout>
            {items
              .filter(
                ({
                  featured_extras: {
                    document: { data },
                  },
                }) => data.public_publish
              )
              .map((post, i) => (
                <Featured
                  key={`featured_post_${i}`}
                  post={post.featured_extras}
                />
              ))}
          </PreviewsLayout>
        </div>
      </Container>
    </StyledFeaturedPosts>
  )
}

export default FeaturedPosts
