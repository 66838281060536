import React from "react"
import PropTypes from "prop-types"
import "twin.macro"
import Paragraph from "./Paragraph"
import ParagraphImage from "./ParagraphImage"
import HeroSection from "./HeroSection"
import FrontpageHero from "./FrontpageHero"
import LeadModule from "./LeadModule"
import FeatureRepeater from "./Feature/Repeater"
import FeatureComparison from "./Feature/Comparison"
import ImageRepeater from "./Image/Repeater"
import USPRepeater from "./USP/Repeater"
import PersonRepeater from "./Person/Repeater"
import PersonCTA from "./Person/CTA"
import CardRepeater from "./CardRepeater"
import IllustratedUSP from "./USP/Illustrated"
import FaqRepeater from "./FAQ/Repeater"
import ContentImage from "./ContentImage"
import Devices from "./Devices"
import PartnerRepeater from "./PartnerRepeater"
import Title from "./Title"
import ButtonSlice from "./ButtonSlice"
import AddonProducts from "./AddonProducts"
import FeaturedPosts from "./Blog/FeaturedPosts"
import BlogPostFeed from "./Blog/Feed/Slice"
import { BlogPostGrid } from "./Blog/Previews/Grid"
import Container from "./Container"
import { PageTestimonial } from "./Testimonial/Page"
import { ProductPresentation } from "./ProductPresentation"
import { PageShortcuts } from "./PageShortcuts"
import { ClientQuotation } from "./ClientQuotation"

function SliceZone({ ...props }) {
  const { isHome, slices, pageInfo, firstBlogPosts, numPages } = props
  if (!slices || slices === null) return null
  const slice = slices.map((s, index) => {
    switch (s.slice_type) {
      case "paragraph":
        return (
          <Paragraph
            key={`${s.slice_type}_${index}`}
            isHome={isHome}
            ctas={s.items}
            {...s.primary}
          />
        )
      case "paragraph___image":
        return (
          <ParagraphImage
            key={`${s.slice_type}_${index}`}
            ctas={s.items}
            {...s.primary}
          />
        )
      case "illustrated_usp":
        return (
          <IllustratedUSP
            key={`${s.slice_type}_${index}`}
            ctas={s.items}
            isHome={isHome}
            {...s.primary}
          />
        )
      case "testimonial":
        return (
          <PageTestimonial
            key={`${s.slice_type}_${index}`}
            content={s.primary}
            items={s.fields}
          />
        )
      case "lead_cta":
        return (
          <LeadModule
            key={`${s.slice_type}_${index}`}
            meta={pageInfo}
            {...s.primary}
          />
        )
      case "blogposts":
        return (
          <Container
            key={`${s.slice_type}_${index}`}
            tw="bg-white py-24 px-8 text-center"
          >
            <BlogPostGrid content={s.primary} items={s.items} />
          </Container>
        )
      case "person_cta":
        return (
          <PersonCTA
            key={`${s.slice_type}_${index}`}
            ctas={s.items}
            {...s.primary}
          />
        )
      case "client_quotations":
        return (
          <ClientQuotation
            key={`${s.slice_type}_${index}`}
            items={s.items}
            {...s.primary}
          />
        )
      case "hero_section":
        return (
          <HeroSection
            key={`${s.slice_type}_${index}`}
            ctas={s.items}
            isHome={isHome}
            {...s.primary}
          />
        )
      case "frontpage_hero":
        return <FrontpageHero {...s.primary} ctas={s.items} />
      case "page_shortcuts":
        return <PageShortcuts {...s.primary} items={s.items} />
      case "feature_repeater":
        return (
          <FeatureRepeater
            key={`${s.slice_type}_${index}`}
            items={s.items}
            {...s.primary}
          />
        )
      case "feature_comparison":
        return (
          <FeatureComparison
            key={`${s.slice_type}_${index}`}
            content={s.primary}
            items={s.items}
            {...s.primary}
          />
        )
      case "image_repeater":
        return (
          <ImageRepeater
            key={`${s.slice_type}_${index}`}
            items={s.items}
            {...s.primary}
          />
        )
      case "person_repeater":
        return (
          <PersonRepeater
            key={`${s.slice_type}_${index}`}
            items={s.items}
            {...s.primary}
          />
        )
      case "usp_repeater":
        return (
          <USPRepeater
            key={`${s.slice_type}_${index}`}
            items={s.items}
            {...s.primary}
          />
        )
      case "card_repeater":
        return (
          <CardRepeater
            key={`${s.slice_type}_${index}`}
            items={s.items}
            {...s.primary}
          />
        )
      case "faq_repeater":
        return (
          <FaqRepeater
            key={`${s.slice_type}_${index}`}
            content={s.primary}
            items={s.items}
            {...s.primary}
          />
        )
      case "content_image":
        return <ContentImage key={`${s.slice_type}_${index}`} {...s.primary} />
      case "devices":
        return <Devices key={`${s.slice_type}_${index}`} {...s.primary} />
      case "partner_repeater":
        return (
          <PartnerRepeater
            key={`${s.slice_type}_${index}`}
            content={s.primary}
            items={s.items}
          />
        )
      case "title":
        return <Title key={`${s.slice_type}_${index}`} {...s.primary} />
      case "button":
        return <ButtonSlice key={`${s.slice_type}_${index}`} {...s.primary} />
      case "addon_products":
        return (
          <AddonProducts
            key={`${s.slice_type}_${index}`}
            content={s.primary}
            items={s.items}
            {...s.primary}
          />
        )
      case "featured_blog_posts":
        return (
          <FeaturedPosts
            key={`${s.slice_type}_${index}`}
            content={s.primary}
            items={s.items}
            {...s.primary}
          />
        )
      case "blog_post_feed":
        const featuredSlice = slices.find(({ slice_type }) => {
          return slice_type === "featured_blog_posts"
        })
        return (
          <BlogPostFeed
            key={`${s.slice_type}_${index}`}
            content={s.primary}
            items={firstBlogPosts}
            numPages={numPages}
            currentPage={1}
            featuredSlice={featuredSlice}
            {...props}
          />
        )
      case "product_presentation":
        return (
          <ProductPresentation
            key={`${s.slice_type}_${index}`}
            {...s.primary}
          />
        )
      default:
        console.warn(`No support for slice type ${s.slice_type}`)
        return null
    }
  })
  return <>{slice}</>
}

SliceZone.propTypes = {
  slices: PropTypes.array.isRequired,
}

export default SliceZone
