import React from "react"
import propTypes from "prop-types"
import styled from "styled-components"
import Icon from "lib/Icon"
import { graphql } from "gatsby"
import { getIconFromUrl } from "utils/iconResolver"

const StyledSoMe = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.colors.ice};
  p {
    margin: 0;
    font-size: 2rem;
    font-weight: lighter;
  }
`

const StyledIcons = styled.div`
  margin-top: 1.5rem;
  a {
    display: inline-block;
    color: ${(props) => props.theme.colors[props.color]};

    &:hover {
      color: ${(props) => props.theme.colors[props.hover]};
      opacity: 1;
    }

    svg {
      width: 2.4rem;
      height: 2.4rem;
      fill: #c5c5c5;
    }

    & + a {
      margin-left: 1rem;
    }
  }
`

function SoMe({ lang, links }) {
  return (
    <StyledSoMe>
      <h4>Find os på</h4>
      <StyledIcons>
        {links &&
          links.map((link) => {
            const icon = getIconFromUrl(link.icon.url)
            return (
              <a
                key={link.social_media_site}
                href={link.social_media_profile_url.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon size="auto" icon={icon} />
              </a>
            )
          })}
      </StyledIcons>
    </StyledSoMe>
  )
}

export default SoMe

SoMe.defaultProps = {
  color: "primary",
  hover: "secondary",
}

SoMe.propTypes = {
  color: propTypes.string,
  hover: propTypes.string,
}

export const query = graphql`
  fragment SocialMediaLinks on PrismicSiteSettings {
    data {
      social_media_links {
        icon {
          alt
          url
        }
        social_media_profile_url {
          link_type
          url
          target
        }
        social_media_site
      }
    }
  }
`

// vvvvvvvvvvvv
// LEGACY QUERY --------------------------------
// vvvvvvvvvvvv
//
// export const query = graphql`
//   fragment SocialMediaLinks on PRISMIC_Site_settings {
//     social_media_links {
//       icon
//       social_media_profile_url {
//         ... on PRISMIC__ExternalLink {
//           url
//         }
//       }
//       social_media_site
//     }
//   }
// `
