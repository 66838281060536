import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import { linkResolver } from "utils/linkResolver"
import Container from "components/Container"
import { Button } from "@clevertrack/shared"

const StyledButtonSlice = styled.div`
  margin: 15rem 4rem;
  margin-top: 0;
  text-align: center;
  ${(props) => props.theme.media.tablet_landscape_up`
    margin: 15rem 8rem;
    margin-top: 0;
  `}
`

function ButtonSlice({ ...props }) {
  const { type, button_text, page_link } = props
  const link = linkResolver(page_link.document)
  return (
    <Container>
      <StyledButtonSlice {...props}>
        <Link to={link}>
          <Button
            size="lg"
            variant={type === "Button" ? "primary" : "transparent"}
          >
            {button_text}
          </Button>
        </Link>
      </StyledButtonSlice>
    </Container>
  )
}

export default ButtonSlice

ButtonSlice.defaultProps = {}
ButtonSlice.propTypes = {}
