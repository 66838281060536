import React from "react"
import { Spring, animated, useSpring } from "@react-spring/web"
import "twin.macro"
import PropTypes from "prop-types"
import { useMeasure } from "react-use"

function Accordion(props) {
  const {
    children,
    toggled,
    onRest,
    onStart,
    forwardRef,
    maxHeight = "300px",
    transitionDuration = "0.4s",
    ...rest
  } = props

  const [ref, { height }] = useMeasure()

  const [{ size }] = useSpring(
    () => ({
      from: { size: 0 },
      size: toggled ? height : 0,
      config: {
        tension: 500,
        friction: 50,
      },
    }),
    [toggled]
  )

  return (
    <animated.div tw="overflow-hidden " style={{ height: size }} {...rest}>
      <div ref={ref}>{children}</div>
    </animated.div>
  )
}

export default Accordion

Accordion.defaultProps = {
  toggled: false,
}

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  toggled: PropTypes.bool,
}
