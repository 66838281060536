import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { linkResolver } from "utils/linkResolver"
import Content from "components/Content"
import Image from "components/Image"
import Tag from "components/Blog/Tag"
import { getTopTags, slicesToText } from "components/Blog/util"
import _ from "lodash"
import tw from "twin.macro"

const StyledHeroImage = styled(Image)`
  /* height: 60vw; */
  height: 60rem;
  ${(props) => props.theme.media.tablet_landscape_up`
    height: 30rem;
  `}
`

const StyledFeedView = styled.div`
  grid-column: 1 / -1; // full-width on phone
  ${(props) => props.theme.media.tablet_landscape_up`
    margin: 2rem 0;
  `}
  position: relative;
  .content-area {
    width: 100%;
    position: absolute;
    top: 0;
    pointer-events: none;
    padding: 2rem;
  }

  .thumbnail {
    position: relative;
    .tags {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 2rem;
    }
  }
  .title {
    ${(props) => props.theme.media.tablet_landscape_up`
      width: 45%;
    `}
    > * {
      margin: 0;
      font-size: 3.5rem;
      line-height: 3.8rem;
      font-family: ${(props) => props.theme.fontStacks.bold};
    }
    color: white;
  }
  .summary {
    ${(props) => props.theme.media.tablet_landscape_up`
      position: absolute;
      right: 4rem;
      left: 50%;
      // top: 50%;
      top: 3rem;
      margin: 0;
    `}
    color: white;
    font-family: ${(props) => props.theme.fontStacks.bold};
    white-space: pre-wrap; // preserve line breaks
    margin-top: 6rem;
    /* color: ${(props) => props.theme.colors.gray}; */
    line-height: 2.2rem;
    /* ------- multiline ellipsis ------- */
    -webkit-line-clamp: 10;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* ---------------------------------- */
    background: -webkit-linear-gradient(white, white, transparent);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .readmore {
    margin-top: 1rem;
    font-family: ${(props) => props.theme.fontStacks.bold};
    /* text-align: center; */
    > * {
      display: inline-block;
      width: 100%;
      padding: 5px 0;
      padding-right: 2rem;
    }
    display: none;
  }
`

const Gradient = styled.div`
  opacity: 0.7;
  top: 0;
  background: linear-gradient(
    ${(props) => props.theme.colors.blackLight},
    ${(props) => props.theme.colors.primary}
  );
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  transition: 100ms;
  &:hover {
    background: ${(props) => props.theme.colors.primary};
    transition: none;
  }
`

function FeedView({ post, ...props }) {
  const { childImageSharp: hero_img } = post.data.hero_image.localFile
  const { body: post_body } = post.data
  const postTags = getTopTags(post.tags)
  const bodyText = slicesToText(post_body)
  return (
    <StyledFeedView {...props}>
      <div className="thumbnail">
        <StyledHeroImage fluid={hero_img.fluid} />
        <Link to={linkResolver(post)}>
          <Gradient />
        </Link>
        <div className="tags">
          {postTags.map((tag, i) => {
            return (
              <Tag key={`post_tags_${i}`} filled>
                #{tag}
              </Tag>
            )
          })}
        </div>
      </div>
      <div className="content-area">
        <div className="title">
          <Content content={post.data.title.richText} />
        </div>
        <div className="summary">{bodyText}</div>
        <div className="readmore">
          <Link to={linkResolver(post)}>Læs artikel</Link>
        </div>
      </div>
    </StyledFeedView>
  )
}

export default FeedView
