import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Inner from "./Inner"

const StyledContainer = styled.section`
  position: relative;
`

function Container({ children, withBg, ...props }) {
  return (
    <StyledContainer {...props}>
      <Inner borderless className="inner">
        {children}
      </Inner>
    </StyledContainer>
  )
}

export default Container

Container.defaultProps = {}
Container.propTypes = {
  children: PropTypes.node.isRequired,
}
