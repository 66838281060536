import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledInner = styled.div`
  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: 120rem;
    margin: 0 auto;
    // padding: 0 4rem;
  `}
`

function Inner({ children, ...props }) {
  return <StyledInner {...props}>{children}</StyledInner>
}

export default Inner

Inner.defaultProps = {
  borderless: null,
}
Inner.propTypes = {
  borderless: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
