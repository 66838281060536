import { minimumFontSize } from "../typography"
import { colors } from '../colors'
import { lighten } from 'polished'

const datepicker = {
  days: {
    width: 4,
    height: 4,
  },
  isSelected: {
    bg: lighten(0.1, colors.primary),
    color: colors.white,
  },
  isStartOrEnd: {
    bg: colors.primary,
    color: colors.white,
  },
  isInHoverRange: {
    bg: lighten(0.2, colors.primary),
    color: colors.white,
  },
  isDisabled: {
    bg: colors.white,
    color: lighten(0.4, colors.black),
  },
  weeknumber: {
    color: lighten(0.3, colors.black),
    fontSize: (minimumFontSize * 0.8).toFixed(2),
    width: 4,
    height: 4,
  },
  daylabel: {
    color: lighten(0.3, colors.black),
    fontSize: (minimumFontSize * 0.8).toFixed(2),
    height: 4,
    width: 4,
  },
  border: `1px solid ${lighten(0.9, colors.black)}`
}

export { datepicker }
