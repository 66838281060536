import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { darken } from "polished"
import tw from "twin.macro"

const StyledRhombus = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  margin-bottom: 1.5rem;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    display: block;
    clip-path: circle(50%);
    ${tw`bg-brand-500`}
    z-index: 5;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  svg {
    position: relative;
    z-index: 10;
    ${tw`text-brand-secondary`}
    fill: currentColor;
    transform: translate3d(25%, -25%, 0);
    width: 8rem;
    height: 8rem;
  }
`

function Rhombus({ children, ...props }) {
  return <StyledRhombus {...props}>{children}</StyledRhombus>
}

export default Rhombus

Rhombus.defaultProps = {}
Rhombus.propTypes = {
  children: PropTypes.node,
}
