import FontFamilies from './global/fonts.css'
import GlobalStyles from './global/global.css'
import TypographyStyles from './global/typography.css'

import { media } from './breakpoints'
import { colors } from './colors'
import { fonts, fontStacks } from './fonts'
import { baseFontSize, lineHeight, fontSizes, minimumFontSize, paragraphRhythm, responsiveFontScale, verticalRhythm } from  './typography'
import { getBoxModel, getShorthand } from './utils/boxmodel'
import { getFontStyle } from './utils/font'

// Base theme for components
import { button } from './base/button'
import { card } from './base/card'
import { checkbox } from './base/checkbox'
import { datepicker } from './base/datepicker'
import { input } from './base/input'
import { list } from './base/list'

const theme = {
  baseBackgroundColor: '#fff',
  baseBodyColor: '#333',
  baseFontSize,
  colors,
  fonts,
  fontSizes,
  fontStacks,
  linkColor: colors.primary,
  media,
  minimumFontSize,
  paragraphRhythm,
  responsiveFontScale,
  verticalRhythm,
  lineHeight,
  utils: {
    getBoxModel,
    getShorthand,
    getFontStyle,
  },
  button,
  card,
  checkbox,
  datepicker,
  input,
  list
}

export { theme, GlobalStyles, TypographyStyles, FontFamilies }
