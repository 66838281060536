import React from "react"
import PropTypes from "prop-types"
import { graphql, Link as GatsbyLink } from "gatsby"
import styled from "styled-components"

const StyledLink = styled.a`
  display: block;
  padding: 0.6rem 2rem;
  font-size: 1.8rem;
  transition: all ease-out 0.15s;
  font-family: ${(props) => props.theme.fontStacks.subheading};

  & + & {
    margin-top: 2rem;
  }

  &:focus {
    outline: none;
    box-shadow: ${(props) => props.theme.inputFocusShadow};
  }

  ${(props) =>
    props.primary
      ? `
        background: ${props.theme.colors[props.bg]};
        border: 1px solid ${props.theme.colors[props.border]};
        color: ${props.theme.colors[props.color]};
      `
      : `
        border: 1px solid currentColor;
        color: currentColor;
      `}

  ${(props) => props.theme.media.tablet_landscape_up`
    & + & {
      margin-top: 0;
      margin-left: 2rem;
    }
  `}
`

const StyledLinkInner = styled.span`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  ${(props) =>
    props.inline &&
    `
      display: inline-flex;
    `}

  svg {
    margin: 0 0 0 0.4em;
  }
`

function Link({ children, blank, inline, to, forwardAs, ...props }) {
  if (blank)
    return (
      <StyledLink
        href={to}
        target="_blank"
        rel="noreferrer noopener"
        {...props}
      >
        <StyledLinkInner inline={inline}>{children}</StyledLinkInner>
      </StyledLink>
    )
  return (
    <StyledLink to={to} as={forwardAs} {...props}>
      <StyledLinkInner inline={inline}>{children}</StyledLinkInner>
    </StyledLink>
  )
}

export default Link

Link.defaultProps = {
  blank: null,
  inline: null,
  forwardAs: GatsbyLink,
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  blank: PropTypes.bool,
  inline: PropTypes.bool,
}

// export const query = graphql`
//   fragment WebLinkFragment on PRISMIC__ExternalLink {
//     target
//     type: _linkType
//     url
//   }
// `
