import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"
import Icon, { IconSizeEnum } from "../Icon"
import { IconFlip } from "@clevertrack/shared"

const StyledCheckbox = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  position: relative;
  grid-column-gap: ${(props) => props.theme.checkbox[props.size].marginRight};
  // font-size: ${(props) => props.theme.checkbox[props.size].fontSize}rem;

  ${(props) =>
    props.disabled ? tw`cursor-default opacity-50 pointer-events-none` : ``}

  & + & {
    ${(props) => (props.inline ? `margin-left: 2rem;` : `margin-top: 1rem;`)}
  }

  input {
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    width: 1px;
    height: 1px;
  }
`

const StyledCheckboxBox = styled.span`
  width: ${(props) => `${props.theme.checkbox[props.size].size}rem`};
  height: ${(props) => `${props.theme.checkbox[props.size].size}rem`};
  border: ${(props) => props.theme.checkbox.border};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.checked ? props.theme.checkbox.bgActive : props.theme.checkbox.bg};
  transition: ${(props) => props.theme.checkbox.bgTransition};
  cursor: pointer;

  ${(props) =>
    props.appearance === "radio"
      ? `
        border-radius: 50%;
        background: ${props.theme.checkbox.bg};
        position: relative;
        &:before {
          content: "";
          border-radius: 50%;
          display: block;
          transition: ${props.theme.checkbox.bgTransition};
          background: ${
            props.checked ? props.theme.checkbox.bgActive : "transparent"
          };
          position: absolute;
          top: 3px;
          left: 3px;
          right: 3px;
          bottom: 3px;
        }

        svg {
          display: none;
        }
      `
      : ""}

  svg {
    width: 75%;
    height: 75%;
    opacity: ${(props) => (props.checked ? 1 : 0)};
    fill: ${(props) => props.theme.checkbox.iconColor};
    transition: ${(props) => props.theme.checkbox.iconTransition};
  }
`

const StyledCheckboxToggle = styled.span`
  width: ${(props) => `${props.theme.checkbox[props.size].size * 2}rem`};
  height: ${(props) => `${props.theme.checkbox[props.size].size}rem`};
  background: ${(props) =>
    props.checked
      ? props.theme.checkbox.toggleActive
      : props.theme.checkbox.toggleInactive};
  transition: ${(props) => props.theme.checkbox.bgTransition};
  position: relative;
  border-radius: ${(props) => `${props.theme.checkbox[props.size].size}rem`};
  cursor: pointer;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-shadow: ${(props) => `${props.theme.checkbox.toggleShadow}`};
    z-index: 10;
    width: ${(props) => `${props.theme.checkbox[props.size].size * 1}rem`};
    height: ${(props) => `${props.theme.checkbox[props.size].size * 1}rem`};
    background: #fff;
    border-radius: ${(props) => `${props.theme.checkbox[props.size].size}rem`};
    transform: ${(props) =>
      props.checked ? `translate3d(105%, 0, 0)` : `translate3d(-5%, 0, 0)`};
    left: 0;
    top: 0;
    transition: transform ease-out 0.2s;

    svg {
      width: 75%;
      height: 75%;
      position: absolute;
      z-index: 50;
      transition: ${(props) => props.theme.checkbox.iconTransition};
      ${(props) => props.checked && `fill: ${props.theme.colors.success}`};
      // opacity: ${(props) => (props.checked ? 1 : 0.5)};
    }
  }
`

const StyledCheckboxLabel = styled.label`
  cursor: pointer;
  line-height: 1;

  p {
    margin: 0;
  }
`

function Checkbox({
  appearance,
  id,
  children,
  checked,
  onChange,
  icon,
  iconUntoggled,
  size,
  checkboxAppearance,
  disabled,
  ...props
}) {
  // Per default, the check state is initiated with the value we put in. Defaults to false.
  // const [isChecked, setChecked] = useState(checked)
  const cbRef = useRef<HTMLInputElement>(null)

  function onClickHandler(e) {
    // When we click the checkbox, the local state is switched.
    onChange(!cbRef.current?.checked)
  }

  /* function onChangeHandler(e) {
    setChecked(e.target.checked)
  }

  useEffect(() => {
    if (onChange) onChange(isChecked)
  }, [isChecked]) */

  /* useEffect(() => {
    if (cbRef.current && cbRef.current.checked !== checked) setChecked(checked)
  }, [checked]) */

  return (
    <StyledCheckbox size={size} disabled={disabled} {...props}>
      <input
        ref={cbRef}
        type="checkbox"
        checked={checked}
        onChange={() => ({})}
        disabled={disabled}
        /*         defaultChecked={checked}
        onChange={onChangeHandler} */
      />
      {appearance === "toggle" ? (
        <StyledCheckboxToggle
          role="checkbox"
          aria-checked={checked}
          checked={checked}
          size={size}
          onClick={onClickHandler}
        >
          <IconFlip toggled={checked} iconOn={icon} iconOff={iconUntoggled} />
        </StyledCheckboxToggle>
      ) : (
        <StyledCheckboxBox
          role="checkbox"
          aria-checked={checked}
          checked={checked}
          size={size}
          onClick={onClickHandler}
          appearance={checkboxAppearance}
        >
          <Icon icon={icon} />
        </StyledCheckboxBox>
      )}
      <StyledCheckboxLabel htmlFor={id} onClick={onClickHandler}>
        {children}
      </StyledCheckboxLabel>
    </StyledCheckbox>
  )
}

export default Checkbox

Checkbox.defaultProps = {
  icon: "check",
  iconUntoggled: null,
  checked: false,
  size: "md",
  appearance: "checkbox",
}

Checkbox.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  icon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  appearance: PropTypes.oneOf(["checkbox", "toggle"]),
  checkboxAppearance: PropTypes.oneOf(["default", "radio"]),
}
