import React, { useContext, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import styled from "styled-components"
import Content from "components/Content"
import { htmlSerializer } from "./htmlSerializer"
import tw from "twin.macro"
import { useIntersectionObserver } from "hooks/useIntersectionObserver"
import { AppContext, ViewportContext } from "context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"

const Wrapper = styled.div`
  max-width: 60rem;
  ${tw`text-brand-secondary`}
  > *:not(h1, h2, h3, h4, h5, h6) {
    max-width: 60rem;
  }

  img {
    // max-width: 55rem;
  }

  .embed-youtube {
    iframe {
      width: 100%;
      height: 40rem;
    }
  }

  p {
    margin: 0;

    + p {
      margin-top: 2rem;
    }

    &:last-child {
      margin-bottom: 4rem;
    }
  }

  p {
    ${tw`text-ellipsis overflow-hidden max-w-full`}
  }

  p,
  li {
    line-height: 1.6;
    ${tw`text-3xl`}
  }

  h2 {
    ${tw`text-6xl`}
  }
  h3 {
    ${tw`text-5xl`}
  }
  h4 {
    ${tw`text-4xl`}
  }
  h5 {
    ${tw`text-4xl`}
  }
  h6 {
    ${tw`text-4xl`}
  }

  .block-img + p {
    ${tw`text-xl opacity-60 mb-20 mt-4`}
  }
`

function BlogContent({ content: { afsnit, content }, ...props }) {
  const { dispatch } = useContext(AppContext)
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = !!entry?.isIntersecting
  const id = afsnit ? slugify(afsnit, { lower: true, locale: "da" }) : ""

  useEffect(() => {
    if (isVisible) {
      dispatch(
        GlobalAppActions(GlobalAppTypes.VisibleSection, { sectionID: id })
      )
    }

    // onSectionEntersView(isVisible)
  }, [isVisible])

  return (
    <Wrapper id={id} ref={ref} {...props}>
      <Content content={content.richText} serializer={htmlSerializer} />
    </Wrapper>
  )
}

export default BlogContent

BlogContent.propTypes = {
  content: PropTypes.array.isRequired,
}
