import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Container from "components/Container"
import Content from "components/Content"
import SidebarLayout from "./SidebarLayout"
import Feed from "../Feed"
import tw from "twin.macro"
import uniq from "lodash-es/uniq"
import Tag from "../Tag"

const StyledContainer = styled(Container)`
  ${tw`bg-white`}
  ${(props) => props.theme.media.tablet_landscape_up`
    margin-top: 2rem;
  `}
  ${(props) => props.theme.media.desktop_up`
    margin-top: 3rem;
  `}
`

const PreviewsLayout = styled.div`
  margin: 0 2rem;
  ${(props) => props.theme.media.desktop_up`
    margin: 0;
  `}

  hr {
    /* margin-top: 2rem; */
    margin-top: 0;
    height: 2px;
    ${tw`bg-brand-gray-light`}
    border: none;
  }
`

const Sidebar = styled.div`
  position: relative;
`

function BlogFeedSlice({ content, items, currentPage, numPages, ...props }) {
  const { sidebar_content } = content
  const tags = uniq(
    items.filter((x) => x.data.public_publish).flatMap((x) => x.tags)
  ).map((x) => x.toLowerCase())
  return (
    <StyledContainer>
      <SidebarLayout flippedOnPhone>
        <PreviewsLayout>
          <hr />
          <Feed
            items={items}
            currentPage={currentPage}
            numPages={numPages}
            {...props}
          />
        </PreviewsLayout>
        <Sidebar tw="space-y-16">
          <div>
            <h4 tw="mt-0 block">Kategorier</h4>
            <div tw="mt-8">
              {tags.map((tag) => {
                return (
                  <Tag
                    key={`featured_tag_${tag}`}
                    tw="text-xl bg-brand-500 text-white py-2 px-4 mb-3 mr-3"
                  >
                    #{tag}
                  </Tag>
                )
              })}
            </div>
          </div>
          {/*           <div>
            <Content content={sidebar_content.richText} />
          </div> */}
        </Sidebar>
      </SidebarLayout>
    </StyledContainer>
  )
}

export default BlogFeedSlice

BlogFeedSlice.propTypes = {
  children: PropTypes.node.isRequired,
}
