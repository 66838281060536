export const getFontStyle = (size, rhythm, excludeMargins) => {
  // To consistently maintain a nice vertical rhythm in website typography
  // we must make sure a sensible line-height is applied to font-styles
  // based on the font-size of that element.

  // First, calculate in rems.
  const remSize = (size / 10).toFixed(1)

  // Take the font-size, and find the closest divisible factor, based on the rhythm, rounded up.
  const multiplier = Math.ceil(remSize / rhythm)

  const fontSettings = [
    `font-size: ${remSize}rem;`,
    `line-height: ${(rhythm * multiplier).toFixed(1)}rem;`,
  ]

  if (excludeMargins) {
    return fontSettings.join("")
  }

  // Adjust the margin according to the margin settings and the rhythm.
  const mgTop = `margin-top: ${(rhythm * multiplier).toFixed(1)}rem;`
  const mgBottom = `margin-bottom: ${(rhythm).toFixed(1)}rem;`

  fontSettings.push(mgTop, mgBottom)

  return fontSettings.join("")
}
