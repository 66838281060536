import format from "date-fns/format"
import da from "date-fns/locale/da"

const getTopTags = (tags) => tags.filter((x, i) => i < 2)
const getPubDate = (dateStr) =>
  format(Date.parse(dateStr), "d. MMMM yyyy", {
    locale: da,
  })

const slicesToText = (slices) => {
  const text = slices.reduce((acc, slice) => {
    switch (slice.slice_type) {
      case "content":
        return (acc += `${slice.primary.content.text}\n`)
      case "quote":
        return (acc += `"${slice.primary.quote_content.text}"\n`)
    }
  }, "")
  return text
}

export { getTopTags, getPubDate, slicesToText }
