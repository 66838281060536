import { Icon } from "@clevertrack/shared"
import React, { useState } from "react"
import { animated, useSpring } from "react-spring"
import styled from "styled-components"
import "twin.macro"

const StyledWrapper = styled.div`
  position: relative;

  .feature1 {
    position: absolute;
    height: auto;
    width: 34rem;
    top: 75%;
    left: 45%;
    opacity: 0;
    transform: translate3d(-50%, 10rem, 0);
    transition: cubic-bezier(0.03, 0.9, 0.41, 1) 400ms all;
    transition-delay: 250ms;

    ${(props) => props.theme.media.tablet_landscape_up`
      transform: translate3d(0, 10rem, 0);
    `}

    &.ready {
      opacity: 1;
      transform: translate3d(-50%, 0, 0);

      ${(props) => props.theme.media.tablet_landscape_up`
        transform: translate3d(0, 0, 0);
      `}
    }
  }

  .feature2 {
    position: absolute;
    height: auto;
    width: 36rem;
    top: 95%;
    left: 55%;
    opacity: 0;
    transform: translate3d(-50%, 10rem, 0);
    transition: cubic-bezier(0.03, 0.9, 0.41, 1) 400ms all;
    transition-delay: 500ms;

    ${(props) => props.theme.media.tablet_landscape_up`
      transform: translate3d(0, 10rem, 0);
      width: 36rem;
    `}

    &.ready {
      opacity: 1;
      transform: translate3d(-50%, 0, 0);

      ${(props) => props.theme.media.tablet_landscape_up`
        transform: translate3d(0, 0, 0);
      `}
    }
  }

  .feature3 {
    position: absolute;
    height: auto;
    width: 20rem;
    top: 60%;
    left: 40%;
    opacity: 0;
    transform: translate3d(0, 10rem, 0);
    transition: cubic-bezier(0.03, 0.9, 0.41, 1) 400ms all;
    // transition-delay: 500ms;

    &.ready {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .scroller {
    top: 78vh;
    justify-content: center;
    opacity: 0;
    transform: translate3d(0, -5rem, 0);
    transition: cubic-bezier(0.03, 0.9, 0.41, 1) 400ms all;
    transition-delay: 1000ms;

    ${(props) => props.theme.media.desktop_up`
      svg {
        fill: #fff;
      }
    `}

    &.ready {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    .feature1 {
      top: 76%;
      left: 26%;
      width: 40rem;
    }

    .feature2 {
      top: 65%;
      right: 0;
      left: 75%;
      width: 40rem;
    }
  `}

  @media (min-width: 600px) and (orientation: landscape) {
    .feature1 {
      top: 50%;
      left: 20%;
      width: 22rem;
    }

    .feature2 {
      top: 51%;
      right: 0;
      left: 80%;
      width: 26rem;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    .feature1 {
      width: 32rem;
      top: 50%;
      left: -24%;
    }

    .feature2 {
      width: 40rem;
      top: 60%;
      left: 70%;

    }

    .feature3 {
      width: 25rem;
      top: 10%;
      right: 37%;
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    .feature1 {
      width: 40rem;
      top: 50%;
      left: -24%;
    }

    .feature2 {
      width: 50rem;
      top: 51%;
      right: -32%;

    }

    .feature3 {
      width: 25rem;
      top: 10%;
      right: 37%;
    }
  `}
`

export const Desktop: React.FC = ({ ...props }) => {
  const [animationCompleted, setAnimationCompleted] = useState(false)

  const mainStyleProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    onRest: () => setAnimationCompleted(true),
  })

  return (
    <StyledWrapper>
      <animated.img
        src="/devices/desktop.svg"
        style={mainStyleProps}
        {...props}
      />
      <img
        className={`feature1${animationCompleted ? " ready" : ""}`}
        src="/illustrations/history-car.png"
      />
      <img
        className={`feature2${animationCompleted ? " ready" : ""}`}
        src="/illustrations/dashboard.png"
      />
      {/*       <img
        className={`feature3${animationCompleted ? " ready" : ""}`}
        src="/illustrations/liebherr-service.png"
      /> */}
    </StyledWrapper>
  )
}
