import { minimumFontSize } from '../typography'
import { colors } from '../colors'

export const checkbox = {
  borderRadius: `0`,
  border: [`.1rem solid rgba(0, 0, 0, 0.2)`],
  bg: `#ffffff`,
  bgActive: `#000000`,
  bgTransition: `all ease-out .1s`,
  iconColor: `#ffff00`,
  iconTransition: `all ease-out .1s`,
  toggleActive: colors.success,
  toggleInactive: `#eaeaea`,
  toggleInsetShadow: `0 0 6px rgba(0, 0, 0, 0.2)`,
  toggleShadow: `0 0 5px rgba(0, 0, 0, 0.2)`,
  sm: {
    fontSize: (minimumFontSize * 0.8).toFixed(2),
    size: (minimumFontSize * 0.8).toFixed(2),
    marginRight: `1rem`
  },
  md: {
    fontSize: (minimumFontSize).toFixed(2),
    size: (minimumFontSize).toFixed(2),
    marginRight: `1rem`
  },
  lg: {
    fontSize: (minimumFontSize * 1.2).toFixed(2),
    size: (minimumFontSize * 1.2).toFixed(2),
    marginRight: `1rem`
  },
}
