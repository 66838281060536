import { css, createGlobalStyle } from "styled-components";

function createGoogleFonts(fonts) {
  const base = `//fonts.googleapis.com/css?family=`
  const fontDefinitions = []
  fonts.map(({ title, config: { weights } }) => fontDefinitions.push(`${title.replace(" ", "+")}${weights.length > 0 ? `:${weights.join(",")}` : ''}`))
  return css`
    @import url('${base}${fontDefinitions.join("|")}&display=swap');
  `
}

function createStaticFonts(fonts) {
  const base = `/fonts/`
  const fontDefinitions = []

  fonts.map(({ filename, title, suffix, config: { weights } }) =>
    weights.map((weight) =>
      fontDefinitions.push(`
      @font-face {
        font-family: '${title} ${weight}';
        src: url('${base}${filename}-${weight}${
        suffix ? suffix : ``
      }.woff2') format('woff2'),
            url('${base}${filename}-${weight}${
        suffix ? suffix : ``
      }.woff') format('woff');
        font-weight: normal;
        font-style: normal;
      }
    `)
    )
  )
  return css`
    ${fontDefinitions.join('')}
  `
}

function createFontFamilies(fontSources) {
  return fontSources.map(src => {
    if (src.source === `google`) return createGoogleFonts(src.fonts);
    if (src.source === `static`) return createStaticFonts(src.fonts);
    return ``
  })
}

const FontFamilies = createGlobalStyle`
  ${props => createFontFamilies(props.theme.fonts)}
`

export default FontFamilies
