import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

const Facebook = ({ url, name, type, title, desc, children, locale }) => (
  <>
    <Helmet>
      {name && <meta property="og:site_name" content={name} />}
      <meta property="og:locale" content={locale} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      {children}
    </Helmet>

  </>
)

export default Facebook

Facebook.defaultProps = {
  type: "website",
  name: null,
}

Facebook.propTypes = {
  url: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
}

