/**
 * Navigation Children Resolver. Gets the relevant descendants of a parent tree, if defined.
 * @param {Object} navigation_parent The navigation object to get the navigation id from
 * @param {Array} available_navigations The list of available navigations
 */

function navigationChildrenResolver(navigation_parent, available_navigations) {
  const id = navigation_parent.document?.prismicId
  if (!id) return null
  return available_navigations.find(({ node }) => node.prismicId === id).node
}

export { navigationChildrenResolver }
