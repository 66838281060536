import tw from "twin.macro"

const colors = {
  primary: "#00BBB4",
  secondary: "#253746",
  white: "#FFFFFF",
  grayLighter: "#F5F5F5",
  grayLight: "#EAEAEA",
  gray: "#5f6b6d",
  grayDark: "#374649",
  blackOpaque: "#647173",
  blackLight: "#333333",
  black: "#0D0D0D",
  red: "#FF671D",
  yellow: "#FF9E18",
  green: "#205A41",
  blue: "#368ABF",
  purple: "#6E538C",
  success: "#1EA644",
  danger: "#F25C5C",
  offline: "#697375",
  orange: "#FF9E18",
  lightBrown: "#DFBB8B",
}

const colorThemes = {
  White: tw`bg-brand-white`,
  Gray: tw`bg-brand-gray-lighter`,
  Red: tw`bg-brand-red-300 text-white`,
  Yellow: tw`bg-brand-yellow-300`,
  Blue: tw`bg-brand-500 text-white`,
  Black: tw`bg-brand-black-base text-white`,
  Brown: tw`bg-brand-brown-400 text-white`,
  Green: tw`bg-brand-green-200 text-white`,
}

export { colors, colorThemes }
