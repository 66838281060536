import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { linkResolver } from "utils/linkResolver"
import NavItem from "./NavItem"

const StyledNavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
    border: none;
    &.top-level > li > a {
      .home & {
        color: ${props.theme.colors.white};

        &.active,
        &:hover {
          color: ${props.theme.colors.white};
          border-bottom: 5px solid ${props.theme.colors.gray};
        }

        .headroom--scrolled & {
          color: ${props.theme.colors.secondary};

          &.active,
          &:hover {
            color: ${props.theme.colors.secondary};
            border-bottom: 5px solid ${props.theme.colors.primary};
          }
        }
      }
    }
  `}
`

const mapNavItemsUIDs = (items) =>
  items.map((item) => item.navigation_link.document?.uid)

const getChildNav = (navigation) => {
  const nav = navigation?.document?.data
  return !!nav?.navigation_items && mapNavItemsUIDs(nav.navigation_items)
}

function NavList({ top_level, items, ...props }) {
  function renderChildren() {
    return (
      <>
        {items.map((item, i) => {
          const { navigation_children, navigation_link_text, tag } = item
          const { document: navLinkObj } = item.navigation_link
          const navigation_link = navLinkObj?.data

          const childNavUIDmap = top_level && getChildNav(navigation_children)
          const link_text = navigation_link_text || navigation_link?.title.text

          if (!navigation_link) {
            return (
              <NavItem
                key={`nav_link_${i}`}
                text={link_text}
                nav_children={navigation_children}
                childNavUIDmap={childNavUIDmap}
                top_level={top_level}
                tag={tag}
              />
            )
          }

          const key = navLinkObj.id
          const url = linkResolver(navLinkObj)
          return (
            <NavItem
              key={key}
              url={url}
              text={link_text}
              tag={tag}
              top_level={top_level}
              nav_children={navigation_children}
            />
          )
        })}
      </>
    )
  }

  // The 'top_level' property allows us to reuse NavList component recursively, but render an unstyled <ul /> for child-levels, to allow great degree of customisation
  return top_level ? (
    <StyledNavList className="top-level" {...props}>
      {renderChildren()}
    </StyledNavList>
  ) : (
    <ul {...props}>{renderChildren()}</ul>
  )
}

export default NavList

NavList.defaultProps = {
  top_level: false,
}
NavList.propTypes = {
  top_level: PropTypes.bool,
  children: PropTypes.node,
}
