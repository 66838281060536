import { Icon, Responsive } from "@clevertrack/shared"
import Container from "components/Container"
import Content from "components/Content"
import { htmlSerializer } from "components/HeroSection"
import { Link } from "gatsby"
import { IconSizeEnum } from "lib/Icon"
import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

const StyledPageShortcuts = styled.section`
  ${tw`flex flex-col justify-around items-center mx-auto my-8 px-8 space-y-4 md:(flex-row px-0) lg:(flex-row px-0)`}
  article {
    display: flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
    ${tw`bg-white p-4 text-brand-500 lg:(shadow-none)`}

    .link {
      ${tw`absolute inset-0`}
    }

    .link_image {
      height: 8rem;
      width: 8rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      ${(props) => props.theme.media.tablet_landscape_up`
        height: 10rem;
        width: 10rem;
      `}

      ${(props) => props.theme.media.desktop_up`
        height: 14rem;
        width: 14rem;
      `}
    }

    .link_text {
      ${tw`flex items-center text-2xl ml-4 md:(text-2xl) lg:(text-4xl ml-8)`}
    }
  }
`

export const PageShortcuts: React.FC<{ items: any[] }> = ({
  items,
  headline,
  content,
  ...props
}) => {
  return (
    <Container tw="pt-16 lg:(pt-32 pb-16)">
      <div tw="max-w-5xl mx-auto text-center px-8 lg:(px-0)">
        <Content content={headline.richText} serializer={htmlSerializer} />
        <Content content={content.richText} serializer={htmlSerializer} />
      </div>
      <StyledPageShortcuts>
        {items.map(({ link_text, link, link_image }, key) => {
          return (
            <article key={`page-shortcut_${key}`}>
              <div className="link_image">
                <img src={link_image.url} alt={link.document.data.title.text} />
              </div>
              <span className="link_text">
                <span tw="mr-4">{link_text}</span>
                <Responsive
                  phone={<Icon icon="chevron-right" size={IconSizeEnum.SM} />}
                  desktop={<Icon icon="chevron-right" />}
                />
              </span>
              <Link className="link" to={link.document.url} />
            </article>
          )
        })}
      </StyledPageShortcuts>
    </Container>
  )
}
