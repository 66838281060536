import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import Icon from "lib/Icon"
import DefaultCard from "lib/Card"
import ResponsiveImage from "components/ResponsiveImage"
import Product from "components/Product"
import Label from "components/Label"
import { linkResolver } from "utils/linkResolver"
import tw from "twin.macro"

const StyledCard = styled(DefaultCard)`
  position: relative;
  background: ${(props) => props.theme.colors.white};
  padding: 0;

  & + & {
    margin-top: 0;
  }

  &.-link {
    filter: drop-shadow(0 5px 10px rgba(50, 50, 93, 0.5));

    .bg-img {
      position: relative;

      &:before {
        background-image: linear-gradient(
          to top,
          #253746 20%,
          rgb(37 55 70 / 0) 100%
        );
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        content: "";
        z-index: 50;
      }
      img {
        position: relative;
        z-index: 20;
        transform: scale3d(1, 1, 1);
        transition: transform ease-out 0.3s;
      }
    }

    &:hover {
      filter: drop-shadow(0 10px 20px rgba(50, 50, 93, 0.4));
      transition: all ease-out 125ms;
      transform: scale3d(1.02, 1.02, 1.02) translateZ(0);

      .bg-img img {
        transform: scale3d(1.02, 1.02, 1.02);
      }
    }
  }

  .bg-img {
    position: relative;
    object-fit: cover;
    z-index: 25;
    overflow: hidden;
  }

  .content {
    z-index: 50;
    position: absolute;
    text-align: left;
    color: ${(props) => props.theme.colors.white};
    bottom: 2rem;
    left: 2rem;
  }

  .card-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 75;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    ${
      props.horizontal
        ? `
            grid-column: 1 / span 3;
            // height: 30rem;
            overflow: hidden;
          `
        : ``
    }
  `}
`

const StyledLabel = styled(Label)`
  display: inline-block;
  ${tw`ml-4`}

  &.-title {
    ${tw`block pl-4 py-0 relative text-3xl md:(text-4xl)`}
    line-height: 1.2;
    font-family: ${(props) => props.theme.fontStacks.bold};
    &:before {
      ${tw`absolute -left-4 bg-brand-500 rounded-full`}
      top: 0.3rem;
      // transform: translateY(-50%);
      width: 1rem;
      height: 1rem;

      content: "";
    }

    .-link:hover & {
    }
  }

  &.-link {
    font-family: ${(props) => props.theme.fontStacks.light};
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    ${tw`text-2xl md:(text-3xl)`}

    svg {
      margin-left: 1rem;
    }

    &:before {
      background: ${(props) => props.theme.colors.white};
    }
  }

  &.-title,
  &.-link {
    transition: all ease-out 0.3s;
    transform: translateY(0);
    .-link:hover & {
      transform: translateY(-1rem);
    }
  }
`

function Card({ data, children, columns, ...props }) {
  const { display_style, emphasize, content, cta_text } = data
  const title = content.document?.data?.title ?? null
  const type = content.document?.type ?? null
  const url = type === "page" ? linkResolver(content.document) : null

  const { background_image } = data
  let background_image_adapted = background_image
  if (background_image?.thumbnails) {
    const { thumbnails, ...rest } = background_image
    background_image_adapted = {
      ...thumbnails,
      ...rest,
    }
  }

  const renderProductCard = () => {
    return (
      <Product
        product={content.document}
        data={data}
        image={background_image_adapted}
        columns={columns}
      />
    )
  }

  const renderPageCard = () => {
    return (
      <StyledCard
        className={`${type === "page" ? "-link" : ""}`}
        horizontal={display_style}
        {...props}
      >
        <div className="content">
          <StyledLabel className="-title">{title.text}</StyledLabel>
          <StyledLabel className="-link">
            {`${cta_text ?? "Læs mere"}`}{" "}
            <Icon icon="chevron-right" size="sm" />
          </StyledLabel>
        </div>
        {background_image_adapted && !display_style && (
          <div className="bg-img">
            <ResponsiveImage img={background_image_adapted} />
          </div>
        )}
        {background_image_adapted && display_style && (
          <div className="bg-img">
            <ResponsiveImage horizontal img={background_image_adapted} />
          </div>
        )}
        <Link to={url} className="card-link" />
      </StyledCard>
    )
  }

  return type === "product" ? renderProductCard() : renderPageCard()
}

export default Card

Card.defaultProps = {}
Card.propTypes = {
  children: PropTypes.node.isRequired,
}
