/**
 *
 * @param {string} lang The short language code corresponding to the default validation messages object
 */

const getDefaultValidationMessagesByLang = (lang) => {
  const defaultMessages = {
    da: {
      badInput: `Ugyldig værdi`,
      customError: ``,
      patternMismatch: `Feltets værdi passer ikke til det forventede`,
      rangeOverflow: `Værdien er højere end det højst tilladte værdi`,
      rangeUnderflow: `Værdien er lavere end den mindst tilladte værdi`,
      stepMismatch: `Værdien er ikke delelig med feltets tilladte trin (f.eks. 0.5 er ikke delelig med 0.2)`,
      tooLong: `Feltets værdi er for lang`,
      tooShort: `Feltets værdi er for kort`,
      typeMismatch: `Feltets værdi passer ikke til den forventede type`,
      valueMissing: `Feltet kræver en værdi`,
    },
    en: {
      badInput: `Invalid input value`,
      customError: ``,
      patternMismatch: `The input value doesn't match its expected pattern`,
      rangeOverflow: `The value is higher than the maximum allowed value`,
      rangeUnderflow: `The value is lower than the minimum allowed value`,
      stepMismatch: `The value is not divisble with the allowed step value (e.g. 0.5 is not divisble with 0.2)`,
      tooLong: `The value is too long`,
      tooShort: `The value is too short`,
      typeMismatch: `The value is not of the expected type`,
      valueMissing: `This input requires a value`,
    },
  }
  return defaultMessages[lang]
}

export { getDefaultValidationMessagesByLang }
