import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import CTA from "./"

const StyledCTARepeater = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${tw`mt-16 space-x-8 space-y-0`}

  ${(props) => props.theme.media.tablet_landscape_up`
    justify-content: ${props.start ? `flex-start` : `center`};
  `}
`

function CTARepeater({ items, start, ...props }) {
  if (
    items.length === 0 ||
    (items.length === 1 && items[0].cta_link.document === null)
  )
    return null
  return (
    <StyledCTARepeater start={start} {...props}>
      {items.map((cta, i) => (
        <CTA
          key={`cta_${i}`}
          primary={i === 0}
          bg={i === 0 ? "primary" : null}
          border={i === 0 ? "primary" : null}
          color={i === 0 ? "white" : null}
          {...cta}
        />
      ))}
    </StyledCTARepeater>
  )
}

export default CTARepeater

CTARepeater.defaultProps = {}
CTARepeater.propTypes = {}
