import React from "react"
import styled from "styled-components"
import { bulletsSerializer, htmlSerializer } from "."
import Content from "components/Content"
import tw from "twin.macro"
import CTARepeater from "components/CTA/Repeater"
import Container from "components/Container"
import Label from "components/Label"
import { colorThemes } from "theme/colors"

const StyledHeroV2Section = styled.section`
  overflow: hidden;
  // box-shadow: inset 0 -5px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  ${tw`mb-8 md:(pt-12)`}

  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 4;

    img {
      width: auto;
      height: 100%;
      object-fit: cover;
      opacity: 0.4;
      ${(props) => props.theme.media.tablet_landscape_up`
        width: 100%;
        height: 100%;
        opacity: 0.4;
      `}
    }
  }

  .container {
    max-width: 90%;
    margin: 0 auto;
    > div {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      ${(props) => props.theme.media.tablet_landscape_up`
        flex-direction: row;
        align-items: center;
      `}
    }
  }

  .content {
    position: relative;
    z-index: 100;
    ${tw`flex pt-8 md:(pb-20 pt-16 pb-24 pr-8) items-center`}
    flex: 0 1 55%;

    h1 {
      margin-top: 0;
    }
    > div:first-child {
      position: relative;
      // z-index: 100;
      ${tw`text-2xl md:(text-4xl)`}
    }
  }

  .content_media {
    position: relative;
    flex: 0 1 45%;
    line-height: 0;

    &.with-video {
      align-self: center;
      ${tw`rounded-full overflow-hidden`}
    }

    .bg_video {
      position: relative;
      &:before {
        position: absolute;
        z-index: 30;
        width: 100%;
        height: 100%;
        display: block;
        content: "";
        ${tw`bg-brand-500 opacity-60`}
      }

      video {
        position: relative;
        z-index: 20;
        width: 100%;
        height: 100%;
      }
    }

    > img {
      position: relative;
      z-index: 50;
      width: 100%;
      height: auto;
    }
  }
`

export const HeroV2Section: React.FC = ({
  content,
  bullets,
  bullet_color,
  bullet_background_color,
  background_image,
  background_video,
  image,
  icon,
  ctas,
  pre_headline,
  headline,
  theme,
  ...props
}) => {
  return (
    <StyledHeroV2Section css={colorThemes[theme]}>
      <Container className="container">
        <div className="content">
          <div>
            {pre_headline !== "" && (
              <Label tw="bg-brand-500 text-white inline-block py-1 px-4 mb-8">
                <span>{pre_headline}</span>
              </Label>
            )}
            <Content content={headline.richText} serializer={htmlSerializer} />
            <Content content={content.richText} serializer={htmlSerializer} />
            <Content
              content={bullets.richText}
              serializer={(type, element, c, children) =>
                bulletsSerializer(
                  type,
                  element,
                  c,
                  children,
                  bullet_color,
                  bullet_background_color,
                  icon
                )
              }
            />
            <CTARepeater start items={ctas} tw="mb-16 md:(mb-0)" />
          </div>
        </div>
        <div
          className={
            background_video.url ? "content_media with-video" : "content_media"
          }
        >
          {background_video.url && (
            <div className="bg_video">
              <video
                src={background_video.url}
                autoPlay={true}
                muted={true}
                loop={true}
                controls={false}
                playsInline={true}
              />
            </div>
          )}
          <img src={image.url} alt="" />
        </div>
      </Container>
      {background_image.url && (
        <div className="bg-img">
          <img src={background_image.url} alt="" />
        </div>
      )}
    </StyledHeroV2Section>
  )
}
