import React from "react"
import styled from "styled-components"
import Icon from "lib/Icon"
import { siteTheme as theme } from "theme"

const StyledLogo = styled.span`
  display: block;
  font-size: 2.4rem;
  line-height: 1;
  letter-spacing: 1px;
  font-weight: bold;
  color: ${(props) => theme.colors[props.color]};

  svg {
    width: 20rem;
    height: auto;
  }
`

interface ILogoProps {
  colored: boolean
}

const Logo: React.FC<ILogoProps> = ({ colored, ...props }) => {
  return (
    <StyledLogo {...props}>
      {colored ? <Icon icon="logo-colored" /> : <Icon icon="logo" />}
    </StyledLogo>
  )
}

export { Logo }
