import React, { useRef, useEffect } from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import SEO from "components/SEO"
import styled from "styled-components"
import Container from "components/Container"

const StyledWrapper = styled(Container)`
  ${tw`px-8`}

  ${(props) => props.theme.media.tablet_landscape_up`
    ${tw`px-20`}
  `}
`

const Cookies = ({ ...props }) => {
  const { data: site_settings } = props.data.prismicSiteSettings

  function createMarkup() {
    return {
      __html:
        '<script id="CookieDeclaration" src="https://consent.cookiebot.com/859afa55-df6a-4946-847e-ef081acc94e0/cd.js" type="text/javascript"></script>',
    }
  }

  useEffect(() => {
    if (typeof window !== "undefind") {
      const cd = document.createElement("script")
      cd.src =
        "https://consent.cookiebot.com/859afa55-df6a-4946-847e-ef081acc94e0/cd.js"
      document.body.appendChild(cd)
    }
  }, [])

  return (
    <>
      <SEO
        metaTitle="Cookies Policy"
        metaDescription="Read our cookie policy"
        slug="/cookies"
      />
      <Layout site_settings={site_settings} lang="da-dk">
        <StyledWrapper>
          <h1 tw="font-bold inline-block text-6xl md:text-6xl">Cookies</h1>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </StyledWrapper>
      </Layout>
    </>
  )
}

export default Cookies

export const query = graphql`
  query CookiesPage {
    prismicSiteSettings {
      _previewable
      ...SiteNavigation
      ...FooterInformation
      ...FooterNavigation
      ...SocialMediaLinks
      ...UtilityNavigation
    }
  }
`
