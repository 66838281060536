import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import tw from "twin.macro"
import Image from "components/Image"
import Content from "components/Content"
import { linkResolver } from "utils/linkResolver"
import Tag from "../Tag"
import { Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"

const StyledBlogPostGrid = styled.div`
  .inner {
    ${(props) => props.theme.media.tablet_landscape_up`
      ${tw`flex space-x-8`}
    `}
  }

  .blogShortcut {
    position: relative;
    ${tw`text-left shadow-card mb-12 flex flex-col md:(flex-1)`}

    .blogImage {
      height: 20rem;
      overflow: hidden;
      ${(props) => props.theme.media.tablet_landscape_up`
        margin-bottom: 0;
        flex: 0 0 auto;

        img {
          height: 100%;
          width: 100%;
        }
      `}
    }

    .blogTeaser {
      ${tw`p-4 px-8 flex flex-col flex-grow`}

      h2 {
        line-height: 1.5;
        font-family: ${(props) => props.theme.fontStacks.bold};
        ${tw`text-3xl mt-4`}
      }

      p {
        ${tw`mt-0 text-xl`}
      }

      .blogReadLink {
        ${tw`mt-auto`}
      }

      .tags {
        height: 2.4rem;
        width: 100%;
        white-space: nowrap;
        ${tw`text-lg text-brand-500`}
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        z-index: 40;
        span {
          line-height: 1rem;
        }
        a {
          ${tw`text-lg`}
        }
      }
    }

    &:only-of-type {
      ${tw`p-4 md:(flex-row)`}

      .blogImage {
        margin-bottom: 2rem;
        ${(props) => props.theme.media.tablet_landscape_up`
          margin-bottom: 0;
          margin-right: 2rem;
          flex: 0 0 30rem;
        `}
      }

      .blogTeaser {
        ${tw`p-4 flex flex-col place-content-between`}

        h2 {
          line-height: 1.5;
          ${tw`text-3xl m-0`}
        }

        .tags {
          position: relative;
          z-index: 40;
          span {
            line-height: 1rem;
          }
          a {
            ${tw`text-lg`}
          }
        }
      }
    }

    > a {
      position: absolute;
      z-index: 4;
      width: 100%;
      height: 100%;
    }
  }
`

const StyledHeroImage = styled(Image)`
  ${(props) => props.theme.media.tablet_landscape_up`

  `}
`

export const BlogPostGrid: React.FC = ({ content, items, ...props }) => {
  return (
    <StyledBlogPostGrid>
      {content.content.richText && (
        <Content content={content.content.richText} />
      )}

      <div className="inner">
        {items
          .filter(
            ({
              post: {
                document: { data },
              },
            }) => data.public_publish
          )
          .map(({ post: { document } }) => {
            const { post_type, hero_image } = document.data
            const { readingTime } = document
            const isCasePost = post_type.toLowerCase() === "case post"
            const link = linkResolver(document)
            return (
              <article className="blogShortcut" key={document.uid}>
                <Link to={link} />
                <div className="blogImage">
                  <StyledHeroImage fluid={hero_image.fluid} />
                </div>
                <div className="blogTeaser">
                  <div className="tags">
                    {document.tags.map((tag, i) => {
                      return (
                        <Tag key={`post_tags_${i}`} filled>
                          #{tag}
                        </Tag>
                      )
                    })}
                  </div>
                  <Content content={document.data.title.richText} />
                  {document.data?.summary && <p>{document.data?.summary}</p>}
                  <div
                    className="blogReadLink"
                    tw="py-2 flex items-baseline justify-between"
                  >
                    <Link
                      to={link}
                      tw="flex items-center text-xl font-bold text-brand-500"
                    >
                      <span tw="mr-4">Læs artikel</span>
                      <Icon icon="chevron-right" size={IconSizeEnum.SM} />
                    </Link>
                    <span tw="block text-lg mb-2">
                      <Icon
                        icon="clock"
                        tw="mr-2 w-2 h-2"
                        size={IconSizeEnum.SM}
                      />
                      {`Læsetid ca. ${Math.ceil(readingTime.minutes).toFixed(
                        0
                      )} min.`}
                    </span>
                  </div>
                </div>
              </article>
            )
          })}
      </div>
    </StyledBlogPostGrid>
  )
}
