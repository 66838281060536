import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { default as DefaultGridParagraph } from "lib/Paragraphs/GridParagraph"
import Content from "components/Content"
import Container from "components/Container"
import CTARepeater from "components/CTA/Repeater"
import ResponsiveImage from "components/ResponsiveImage"
import tw from "twin.macro"
import { bulletsSerializer, htmlSerializer } from "components/HeroSection"
import { getIconFromUrl } from "utils/iconResolver"

/*
  White
  Gray
  Red
  Yellow
  Blue
  Black
  Brown
  Green
*/

const colorThemes = {
  White: tw`bg-brand-white`,
  Gray: tw`bg-brand-gray-lighter`,
  Red: tw`bg-brand-red-300 text-white`,
  Yellow: tw`bg-brand-yellow-300`,
  Blue: tw`bg-brand-500 text-white`,
  Black: tw`bg-brand-black-base text-white`,
  Brown: tw`bg-brand-brown-400 text-white`,
  Green: tw`bg-brand-green-200 text-white`,
}

const imageStyles = {
  None: tw``,
  Encircled: tw`rounded-full border-solid border-8 border-white shadow-card`,
  Border: tw`border-solid border-8 border-white shadow-card`,
}

const StyledParagraphImage = styled.section`
  align-items: center;
  padding: 4rem 0 6rem;
  position: relative;
  z-index: 20;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 8rem 0;
  `}

  ${(props) => props.theme.media.desktop_up`
    padding: 8rem 0;
  `}
`

const StyledContainer = styled(Container)`
  position: relative;
  max-width: 90%;
  margin: 0 auto;
  .inner {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    position: relative;

    ${(props) => props.theme.media.tablet_landscape_up`
      max-width: auto;
      display: flex;
      align-items: center;
      flex-direction: ${!props.flip ? "row-reverse;" : "row;"}
    `}
  }
`

const StyledParagraph = styled.article`
  position: relative;
  ${tw`text-brand-secondary mb-14 md:(mb-0)`}
  z-index: 20;
  max-width: 60rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    margin: 0;
    padding: ${props.flip ? "0 4rem 0 0" : "0 0 0 4rem"};
    flex: 0 1 60rem;
    ${props.flip ? "margin-right: auto;" : "margin-left: auto;"}
  `}
`

const StyledImageContainer = styled.div`
  // max-width: 80vw;
  height: auto;
  margin: 0 auto;

  ${(props) => props.theme.media.tablet_landscape_up`
    // transform: ${!props.flip ? "translateX(-2rem);" : "translateX(2rem);"}
    height: auto;
    max-width: 45%;
  `};
`

const StyledResponsiveImage = styled(ResponsiveImage)`
  width: 100%;
  height: auto;
  ${tw`md:(px-0)`}
  ${(props) => props.theme.media.tablet_landscape_up`
    height: auto;
    width: 100%;
  `};
`

function ParagraphImage({
  paragraph_image: image,
  content,
  theme,
  image_position,
  bullet_color,
  bullet_background_color,
  bullet_icon_alt,
  ctas,
  paragraph_image_style,
  ...props
}) {
  const icon = getIconFromUrl(bullet_icon_alt?.url)
  return (
    <StyledParagraphImage
      css={colorThemes[theme ?? "White"]}
      useFractions
      {...props}
    >
      <StyledContainer flip={image_position}>
        <StyledParagraph flip={image_position}>
          <Content
            content={content.richText}
            serializer={(type, element, c, children) =>
              bullet_color && bullet_background_color && icon
                ? bulletsSerializer(
                    type,
                    element,
                    c,
                    children,
                    bullet_color,
                    bullet_background_color,
                    icon
                  )
                : htmlSerializer(type, element, c, children)
            }
          />
          <CTARepeater start items={ctas} />
        </StyledParagraph>
        {image && (
          <StyledImageContainer flip={image_position}>
            <StyledResponsiveImage
              img={image}
              css={imageStyles[paragraph_image_style]}
            />
          </StyledImageContainer>
        )}
      </StyledContainer>
    </StyledParagraphImage>
  )
}

export default ParagraphImage

ParagraphImage.defaultProps = {}
ParagraphImage.propTypes = {
  content: PropTypes.array.isRequired,
  image: PropTypes.shape({
    fixed: PropTypes.object,
    fluid: PropTypes.object,
  }),
}
