import { post } from "axios"

const createHubspotLeadModel = (fields, context, process, communications) => {
  return {
    submittedAt: Date.now(),
    fields: fields,
    context: context,
    legalConsentOptions: {
      consent: {
        consentToProcess: process,
        text:
          "Jeg samtykker til at Clevertrack ApS må opbevare og behandle mine personlige data.",
        communications: [
          {
            value: communications,
            subscriptionTypeId: 999,
            text:
              "Jeg samtykker til at modtage kommunikation fra Clevertrack ApS.",
          },
        ],
      },
    },
  }
}

const addLeadToHubspot = async (portalId, formGuid, data) => {
  try {
    const response = await post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      data
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export { createHubspotLeadModel, addLeadToHubspot }
