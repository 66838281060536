import React from "react"
import styled from "styled-components"

const StyledIcon = styled.svg<IIconSettingsProps>`
  fill: currentColor;
  display: inline-block;
  line-height: ${(props) => props.theme.verticalRhythm}rem;

  ${(props) => {
    switch (props.size) {
      case "xl":
        return `
          height: ${props.theme.verticalRhythm * 1.6}rem;
          width: ${props.theme.verticalRhythm * 1.6}rem;
        `
      case "lg":
        return `
          height: ${props.theme.verticalRhythm * 1.25}rem;
          width: ${props.theme.verticalRhythm * 1.25}rem;
        `
      case "sm":
        return `
          height: ${props.theme.verticalRhythm * 0.5}rem;
          width: ${props.theme.verticalRhythm * 0.5}rem;
        `
      case "xs":
        return `
          height: ${props.theme.verticalRhythm * 0.25}rem;
          width: ${props.theme.verticalRhythm * 0.25}rem;
        `
      case "fill":
        return `
          height: 100%;
          width: 100%;
        `
      case "auto":
        return `
          height: auto;
          width: auto;
        `
      default:
        return `
          height: ${props.theme.verticalRhythm * 0.75}rem;
          width: ${props.theme.verticalRhythm * 0.75}rem;
        `
    }
  }}
`

export enum IconSizeEnum {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
  AUTO = "auto",
  FILL = "fill",
}

interface IIconSettingsProps {
  title?: string
  size?: IconSizeEnum
}

interface IIconProps extends IIconSettingsProps {
  icon: string
}

const Icon: React.FC<IIconProps> = ({ icon, ...props }) => {
  return (
    <StyledIcon {...props}>
      <use xlinkHref={`/icons.svg#icon-${icon}`} />
    </StyledIcon>
  )
}

export default Icon
