/**
 * ES5 compatible Link Resolver for Prismic.
 * Docs: https://prismic.io/docs/reactjs/beyond-the-api/link-resolving
 */

const dict = {
  baseUrl: {
    "da-dk": "",
    "en-gb": "/en",
  },
  articles: {
    "da-dk": "viden",
    "en-gb": "viden", //learn
  },
}

const linkResolver = (doc) => {
  // The "doc" object contains three things: lang, type and uid
  // The "dict" is an object that enables us to look up parts of the url, based on the type.
  // The "baseUrl" is the i18n root url.

  if (!doc) return `/`

  const baseUrl = dict.baseUrl[doc.lang] // Is either empty string or 'en'

  let urlParts = ""
  switch (doc.type) {
    case "lead_module":
      // Link to on-page lead module
      return `#${doc.uid}`
    // Add a URL pattern for each doc.type that should result in a page.
    // E.g. if blog posts should be accessible on /blog
    case "page":
      urlParts = `${doc.uid}`
      break
    case "blog_post":
      urlParts = `${dict.articles[doc.lang]}/${doc.uid}`
      break
    case "index":
    default:
      urlParts = ``
  }

  return `${baseUrl === "" ? "/" : `${baseUrl}/`}${urlParts}`
}

exports.linkResolver = linkResolver
// export { linkResolver }
