import { createGlobalStyle } from "styled-components"
import { darken } from 'polished'

const TypographyStyles = createGlobalStyle`
  /* Headings */
  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.fontStacks.heading};
  }

  h1 {
    ${props => props.theme.utils.getFontStyle(props.theme.fontSizes.h2, props.theme.verticalRhythm)}

    ${props => props.theme.media.tablet_portrait_up`
      ${props.theme.utils.getFontStyle(props.theme.fontSizes.h1, props.theme.verticalRhythm)}
    `}
  }

  h2 {
    ${props => props.theme.utils.getFontStyle(props.theme.fontSizes.h3, props.theme.verticalRhythm)}

    ${props => props.theme.media.tablet_portrait_up`
      ${props.theme.utils.getFontStyle(props.theme.fontSizes.h2, props.theme.verticalRhythm)}
    `}
  }

  h3 {
    ${props => props.theme.utils.getFontStyle(props.theme.fontSizes.h4, props.theme.verticalRhythm)}

    ${props => props.theme.media.tablet_portrait_up`
      ${props.theme.utils.getFontStyle(props.theme.fontSizes.h3, props.theme.verticalRhythm)}
    `}
  }

  h4 {
    ${props => props.theme.utils.getFontStyle(props.theme.fontSizes.h5, props.theme.verticalRhythm)}

    ${props => props.theme.media.tablet_portrait_up`
      ${props.theme.utils.getFontStyle(props.theme.fontSizes.h4, props.theme.verticalRhythm)}
    `}
  }

  h5 {
    ${props => props.theme.utils.getFontStyle(props.theme.fontSizes.h5, props.theme.verticalRhythm)}

    ${props => props.theme.media.tablet_portrait_up`
      ${props.theme.utils.getFontStyle(props.theme.fontSizes.h5, props.theme.verticalRhythm)}
    `}
  }

  h6 {
    ${props => props.theme.utils.getFontStyle(props.theme.fontSizes.h6, props.theme.verticalRhythm)}
  }

  /* Text content */

  blockquote {}

  dl {}
  dt {}
  dd {}

  figcaption {}
  figure {}

  hr {}

  ol {
    margin: ${props => `${props.theme.verticalRhythm}rem`} 0;
  }

  ul {
    margin: ${props => `${props.theme.verticalRhythm}rem`} 0;
  }

  li {
    ${props => props.theme.utils.getFontStyle(props.theme.fontSizes.p, props.theme.verticalRhythm, true)}
  }

  p {
    ${props => props.theme.utils.getFontStyle(props.theme.fontSizes.p, props.theme.verticalRhythm, true)}

    + p {
      ${props => {
        if (props.theme.paragraphRhythm === 'indent') {
          return `
            text-indent: 1em;
            ${props.theme.utils.getFontStyle(props.theme.fontSizes.p, props.theme.verticalRhythm)}
          `
        }
        else if (props.theme.paragraphRhythm === 'margin') {
          return `
            ${props.theme.utils.getFontStyle(props.theme.fontSizes.p, props.theme.verticalRhythm)}
          `
        } else {
          return props.theme.utils.getFontStyle(props.theme.fontSizes.p, props.theme.verticalRhythm)
        }
      }}
    }
  }

  /* Inline text semantics */
  a {
    color: ${props => props.theme.linkColor};
    text-decoration: none;
    transition: color ease-out .15s;
    line-height: ${props => `${props.theme.verticalRhythm}rem`};

    &:hover {
      color: ${props => darken(0.3, props.theme.linkColor)};
    }
  }

  abbr {
    &[title] {
      text-decoration: dotted underline;
    }
  }

  b, strong {}

  cite {}

  code {}

  em, i {}

  kbd {}

  mark {}

  q {}

  s {}

  small {}

  span {}

  sub {}

  sup {}

  time {}

`

export default TypographyStyles
