import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Link } from "gatsby"
import { linkResolver } from "utils/linkResolver"
import Content from "components/Content"
import Image from "components/Image"
import Tag from "components/Blog/Tag"
import { getTopTags, slicesToText } from "components/Blog/util"

import { Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"

const StyledHeroImage = styled(Image)`
  height: 45vw;
  ${(props) => props.theme.media.tablet_landscape_up`
    height: 25rem;
  `}
`

const StyledFeatured = styled.div`
  ${tw`shadow-card`}
  position: relative;
  display: flex;
  flex-direction: column;
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    ${tw`text-brand-secondary relative z-40`}
    padding: 2rem 2rem 1rem;
    padding-top: 0;
    > h2 {
      font-family: ${(props) => props.theme.fontStacks.bold};
      ${tw`mb-0`}
      font-size: 1.8rem;
      line-height: 1.4;
      ${(props) => props.theme.media.tablet_landscape_up`
        font-size: 2.1rem;
        max-width: 90%;
      `}
    }
    ${(props) => props.theme.media.tablet_landscape_up`
      pointer-events: all;
    `}
  }
  .thumbnail {
    position: relative;
    > a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }
  .tags {
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
    z-index: 40;
    span {
      line-height: 1rem;
    }
    a {
      ${tw`text-lg`}
    }
  }
  .summary {
    white-space: pre-wrap; // preserve line breaks
    margin-top: 1rem;
    margin-bottom: 1rem;
    ${tw`text-xl md:(text-2xl)`}
    color: ${(props) => props.theme.colors.gray};
    line-height: 1.5;
    /* ------- multiline ellipsis ------- */
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    /* ---------------------------------- */
  }
  .readmore {
    font-family: ${(props) => props.theme.fontStacks.bold};
    /* text-align: center; */
    > * {
      display: inline-block;
      padding: 5px 0;
      padding-right: 2rem;
    }
  }
`

function Featured({ post: post_obj, ...props }) {
  const { document: post } = post_obj
  const { hero_image } = post.data
  const { body: post_body } = post.data
  const { readingTime } = post
  const postTags = getTopTags(post.tags)
  const bodyText = slicesToText(post_body)
  const postUrl = linkResolver(post)
  return (
    <StyledFeatured {...props}>
      <div className="thumbnail">
        <StyledHeroImage fluid={hero_image?.fluid} />
        <Link to={postUrl} />
      </div>
      <div className="content">
        <div className="tags">
          {post.tags.map((tag, i) => {
            return (
              <Tag key={`post_tags_${i}`} filled>
                #{tag}
              </Tag>
            )
          })}
        </div>
        <Content content={post.data.title.richText} />
        <div className="summary">{post.data.summary}</div>
        <div tw="py-2 mt-auto flex items-baseline justify-between">
          <Link
            to={postUrl}
            tw="flex items-center text-2xl font-bold text-brand-500"
          >
            <span tw="mr-4">Læs artikel</span>
            <Icon icon="chevron-right" size={IconSizeEnum.SM} />
          </Link>
          <span tw="text-xl text-brand-gray-base">
            <Icon icon="clock" tw="mr-2 w-2 h-2" size={IconSizeEnum.SM} />
            {`Læsetid ca. ${Math.ceil(readingTime.minutes).toFixed(0)} min.`}
          </span>
        </div>
      </div>
    </StyledFeatured>
  )
}

export default Featured
