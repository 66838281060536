import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Icon from "lib/Icon"
import Content from "components/Content"
import Rhombus from "components/Rhombus"
import { getIconFromUrl } from "utils/iconResolver"

const StyledFeature = styled.li`
  padding: 2rem 0;
  h3 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 2rem;
    line-height: 1.4;

    + p {
      margin-top: 1rem;
      font-size: 1.7rem;
      line-height: 1.5;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 3rem 2rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    h3 {
      margin-bottom: 0;
    }
  `}
`

function Feature({ feature_description, feature_icon, ...props }) {
  const icon = getIconFromUrl(feature_icon.url)
  return (
    <StyledFeature {...props}>
      <Rhombus>
        <Icon icon={icon} size="sm" />
      </Rhombus>
      <Content content={feature_description.richText} />
    </StyledFeature>
  )
}

export default Feature

Feature.defaultProps = {}
Feature.propTypes = {}
