import { valuable } from "./worthy"

const getIconFromUrl = (url) => {
  // Find the filename from the url and slice the guid and appended underscore to get the icon name
  return valuable(url)
    ? url.substring(url.lastIndexOf("_") + 1, url.lastIndexOf(".svg"))
    : null
}

export { getIconFromUrl }
