import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Container from "components/Container"
import Content from "components/Content"

const StyledTitle = styled.div`
  margin: 4rem;
  margin-top: 8rem;
  font-size: 150%;
  ${(props) => props.theme.media.tablet_landscape_up`
    margin: 10rem 7.5rem;
    margin-bottom: -5rem;
  `}
`

function Title({ ...props }) {
  const { content_title: title, content } = props
  return (
    <Container>
      <StyledTitle {...props}>
        <Content content={title.richText} />
        <Content content={content.richText} />
      </StyledTitle>
    </Container>
  )
}

export default Title

Title.defaultProps = {}
Title.propTypes = {}
