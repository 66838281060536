import React from "react"
import styled from "styled-components"

const StyledIcon = styled.svg<any>`
  fill: currentColor;
  display: inline-block;
  line-height: 2rem;

  ${({ size }) => {
    switch (size) {
      case EIconSize.xl:
        return `
          height: ${2 * 1.6}rem;
          width: ${2 * 1.6}rem;
        `
      case EIconSize.lg:
        return `
          height: ${2 * 1.25}rem;
          width: ${2 * 1.25}rem;
        `
      case EIconSize.sm:
        return `
          height: 1rem;
          width: 1rem;
        `
      case EIconSize.xs:
        return `
          height: ${2 * 0.25}rem;
          width: ${2 * 0.25}rem;
        `
      case EIconSize.auto:
        return `
          height: auto;
          width: auto;
        `
      default:
        return `
          height: 2rem;
          width: 2rem;
        `
    }
  }}
`

enum EIconSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  auto = "auto",
}

interface IIcon {
  size: EIconSize
  icon: string
}

function Icon({ icon, size = EIconSize.md, ...props }: IIcon) {
  return (
    <StyledIcon size={size} {...props}>
      <use xlinkHref={`/icons.svg#icon-${icon}`} />
    </StyledIcon>
  )
}

export { Icon }
