import { createGlobalStyle } from "styled-components"
import normalize from "polished/lib/mixins/normalize"

const GlobalStyles = createGlobalStyle`
  ${normalize()}

  * {
    -webkit-font-smoothing: antialiased;
    min-height: 0;
    min-width: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  ${(props) => `
    body {
      background: ${props.theme.baseBackgroundColor};
      color: ${props.theme.baseBodyColor};
      font-family: ${props.theme.fontStacks.body};
      font-size: ${props.theme.baseFontSize}rem;
      line-height: ${props.theme.lineHeight};
      min-height: 100vh;
    }

    h1 { font-size: ${props.theme.fontSizes.h1}rem; }
    h2 { font-size: ${props.theme.fontSizes.h2}rem; }
    h3 { font-size: ${props.theme.fontSizes.h3}rem; }
    h4 { font-size: ${props.theme.fontSizes.h4}rem; }
    h5 { font-size: ${props.theme.fontSizes.h5}rem; }
    h6 { font-size: ${props.theme.fontSizes.h6}rem; }
    p, li, pre, code, em, strong, span, blockquote, cite, footer, address { font-size: ${
      props.theme.fontSizes.p
    }rem; }
  `}

  address {}

  article {}

  aside {}

  footer {}

  header {}

  main {}

  nav {}

  section {}

  img { width: 100%; }
`

export default GlobalStyles
