import React from "react"
import styled from "styled-components"
import DefaultCard, { ICardProps } from "lib/Card"
import { siteTheme as theme } from "theme"

const StyledCard = styled(DefaultCard)`
  background: ${theme.colors.white};
`

const Card: React.FC<ICardProps> = ({ children, ...props }) => {
  return <StyledCard {...props}>{children}</StyledCard>
}

export { Card }
