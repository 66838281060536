import React from "react"
import styled from "styled-components"

const StyledCard = styled.div<ICardProps>`
  box-shadow: ${(props) => props.theme.card.boxShadow};
  border-radius: ${(props) => props.theme.card.borderRadius};

  ${(props) =>
    props.theme.utils.getBoxModel(
      props.theme.card[props.size].padding,
      props.theme.card[props.size].border,
      props.theme.card[props.size].margin
    )};

  & + & {
    margin-top: ${(props) => props.theme.verticalRhythm}rem;
  }
`

export enum CardSize {
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
}

interface IStyledCardProps {
  size?: CardSize
}

export interface ICardProps extends IStyledCardProps {
  children: React.ReactNode
  forwardRef?: React.MutableRefObject<any>
}

const Card: React.FC<ICardProps> = ({
  children,
  size = CardSize.MEDIUM,
  forwardRef,
  ...props
}) => {
  return (
    <StyledCard size={size} ref={forwardRef} {...props}>
      {children}
    </StyledCard>
  )
}

export default Card
