import { Icon } from "@clevertrack/shared"
import React, { useMemo, useState } from "react"
import { animated, useSpring } from "react-spring"
import styled from "styled-components"
import "twin.macro"

const StyledWrapper = styled.div`
  position: relative;

  .feature1 {
    position: absolute;
    height: auto;
    width: 30rem;
    top: 100%;
    left: 45%;
    opacity: 0;
    transform: translate3d(-50%, 10rem, 0);
    transition: cubic-bezier(0.03, 0.9, 0.41, 1) 400ms all;
    transition-delay: 0;

    ${(props) => props.theme.media.tablet_landscape_up`
      transform: translate3d(0, 10rem, 0);
    `}

    &.ready {
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
      ${(props) => props.theme.media.tablet_landscape_up`
        transform: translate3d(0, 0, 0);
      `}
    }
  }

  .feature2 {
    position: absolute;
    height: auto;
    width: 18rem;
    top: 108%;
    left: 55%;
    opacity: 0;
    transform: translate3d(0, 10rem, 0);
    transition: cubic-bezier(0.03, 0.9, 0.41, 1) 400ms all;
    transition-delay: 500ms;

    &.ready {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      ${(props) => props.theme.media.tablet_landscape_up`
        transform: translate3d(0, 0, 0);
      `}
    }
  }

  .feature3 {
    position: absolute;
    height: auto;
    width: 24rem;
    top: 124%;
    left: 65%;
    opacity: 0;
    transform: translate3d(-50%, 10rem, 0);
    transition: cubic-bezier(0.03, 0.9, 0.41, 1) 400ms all;
    transition-delay: 250ms;

    ${(props) => props.theme.media.tablet_landscape_up`
      transform: translate3d(0, 10rem, 0);
    `}

    &.ready {
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
      ${(props) => props.theme.media.tablet_landscape_up`
        transform: translate3d(0, 0, 0);
      `}
    }
  }

  .scroller {
    top: 78vh;
    justify-content: center;
    opacity: 0;
    transform: translate3d(0, -5rem, 0);
    transition: cubic-bezier(0.03, 0.9, 0.41, 1) 400ms all;
    transition-delay: 1000ms;

    &.ready {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    .feature1 {
      top: 80%;
      left: 28%;
      width: 40rem;
    }

    .feature3 {
      top: 75%;
      right: 0;
      left: 80%;
      width: 36rem;
    }
  `}

  @media (min-width: 600px) and (orientation: landscape) {
    .feature1 {
      top: 67%;
      left: 20%;
      width: 22rem;
    }

    .feature3 {
      top: 63%;
      right: 0;
      left: 86%;
      width: 20rem;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    .feature1 {

      width: 34rem;
      top: 68%;
      left: -20%;
      right: auto;
    }

    .feature2 {
      width: 30rem;
      top: 20%;
      right: -27%;
      left: auto;
    }

    .feature3 {
      width: 34rem;
      top: 55%;
      right: -30%;
      left: auto;
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    .feature1 {

      width: 33rem;
      top: 68%;
      left: -16%;
      right: auto;
    }

    .feature2 {
      width: 30rem;
      top: 20%;
      right: -27%;
      left: auto;
    }

    .feature3 {
      width: 34rem;
      top: 60%;
      right: -20%;
      left: auto;
    }
  `}

  ${(props) => props.theme.media.big_desktop_up`
    .feature1 {

      width: 40rem;
      top: 68%;
      left: -18%;
      right: auto;
    }

    .feature2 {
      width: 30rem;
      top: 20%;
      right: -27%;
      left: auto;
    }

    .feature3 {
      width: 34rem;
      top: 60%;
      right: -20%;
      left: auto;
    }
  `}
`

export const Tablet: React.FC = ({ ...props }) => {
  const [animationCompleted, setAnimationCompleted] = useState(false)

  const mainStyleProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    onRest: () => setAnimationCompleted(true),
  })

  return (
    <StyledWrapper>
      <animated.img src="/devices/ipad.svg" style={mainStyleProps} {...props} />
      <img
        className={`feature1${animationCompleted ? " ready" : ""}`}
        src="/illustrations/historik-maskine.png"
      />
      {/* <img
        className={`feature2${animationCompleted ? " ready" : ""}`}
        src="/illustrations/service.png"
      /> */}
      <img
        className={`feature3${animationCompleted ? " ready" : ""}`}
        src="/illustrations/co2-rapport.png"
      />
    </StyledWrapper>
  )
}
