/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { GlobalStyles, FontFamilies } from "lib/ui/theme"
import styled, { ThemeProvider } from "styled-components"
import NavToggle from "lib/Navigation/Toggle"
import NavUntoggle from "lib/Navigation/Untoggle"
import { siteTheme, CustomGlobalStyles } from "theme"

import { AppContext, ViewportContext } from "context"
import { isViewport } from "helpers"
import useViewport from "hooks/useViewport"

import Header from "components/Header"
import Navigation from "components/Navigation"
import OffCanvasNav from "components/OffCanvasNav"
import UtilityBar from "components/Navigation/UtilityBar"
import Footer from "components/Footer"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import tw from "twin.macro"

const StyledMain = styled.main`
  position: relative;
  z-index: 1;
`

const StyledNavToggle = styled(NavToggle)`
  border: none;
  background: transparent;
  transition: all ease-out 0.2s;
  ${tw`text-brand-black-base`}
  margin: 0;

  .headroom--pinned & {
    ${tw`text-brand-500`}
  }

  &:active {
    background: none;
  }

  &:focus {
    outline: none;
    background: none;
  }

  .home & {
    color: #fff;

    .headroom--scrolled & {
      ${tw`text-brand-black-base`}
    }
  }
`

const StyledNavUntoggle = styled(NavUntoggle)`
  border: none;
  background: transparent;
  transition: all ease-out 0.2s;
  ${tw`text-lg text-brand-gray-dark ml-auto my-0`}
  padding: 0;

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }

  &:focus {
    outline: none;
  }
`

const NavigationArea = styled.div`
  display: flex;
  flex-direction: column;
`

const Layout = ({
  children,
  lang,
  isHome,
  site_settings: { main_navigation, utility_navigation, ...footer },
  ...props
}) => {
  const {
    state: { navToggled },
    dispatch,
  } = useContext(AppContext)
  const [isMounted, setIsMounted] = useState(false)
  const viewport = useViewport()

  function handleNavToggle() {
    dispatch(
      GlobalAppActions(GlobalAppTypes.ToggleNav, { toggled: !navToggled })
    )
  }

  function onConversationsAPIReady() {}

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.HubSpotConversations) {
        onConversationsAPIReady()
      } else {
        /*
          Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
          These callbacks will be called once the external API has been initialized.
        */
        window.hsConversationsOnReady = [onConversationsAPIReady]
      }
    }
  })

  useEffect(() => {
    if (!isMounted) setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  const browser = typeof window !== "undefined" && window

  return (
    <ThemeProvider theme={siteTheme}>
      <FontFamilies />
      <GlobalStyles />
      <CustomGlobalStyles />
      <ViewportContext.Provider value={viewport}>
        {isMounted ? (
          <>
            <Header lang={lang} isHome={isHome}>
              {browser &&
              !isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) ? (
                <NavigationArea>
                  <UtilityBar nav={utility_navigation} />
                  <nav>
                    <Navigation nav={main_navigation} lang={lang} {...props} />
                  </nav>
                </NavigationArea>
              ) : (
                <>
                  <nav>
                    <StyledNavToggle onToggle={handleNavToggle} />
                  </nav>
                </>
              )}
            </Header>
            <StyledMain>{children}</StyledMain>
            <Footer lang={lang} {...footer} />
            {
              /* isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) && (*/
              <OffCanvasNav
                navToggle={
                  <>
                    <UtilityBar nav={utility_navigation} />
                    <StyledNavUntoggle
                      withText={props.lang === "en-gb" ? "Close" : "Luk"}
                      withIcon="close"
                      onToggle={handleNavToggle}
                    />
                  </>
                }
                nav={
                  <>
                    <nav>
                      <Navigation nav={main_navigation} lang={lang} />
                    </nav>
                  </>
                }
              />
              /* )*/
            }
          </>
        ) : null}
      </ViewportContext.Provider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
