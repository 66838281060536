const scrollElementIntoView = (event, id, offset = 200) => {
  if (typeof window !== `undefined`) {
    event.preventDefault()
    const elm = document.querySelector(id)
    elm.scrollIntoView({ behavior: "smooth", block: "center" })
  }
  return
}

export { scrollElementIntoView }
