import React from "react"
import styled from "styled-components"
import Accordion from "lib/Accordion"
import { siteTheme as theme } from "theme"

const StyledDropdown = styled(Accordion)`
  position: absolute;
  right: 0;
  top: 100%;
  width: 24rem;
  background: ${theme.colors.white};
  box-shadow: ${theme.mapButtonShadow};
`

function Dropdown({ children, ...props }) {
  return <StyledDropdown {...props}>{children}</StyledDropdown>
}

export { Dropdown }
