import { colors } from "./colors"
import tw from "twin.macro"

const buttons = {
  primary: {
    background: colors.primary,
    color: colors.white,
  },
  secondary: {
    background: colors.secondary,
    color: colors.white,
  },
  danger: {
    background: colors.red,
    color: colors.white,
  },
  default: {
    background: colors.secondary,
    color: colors.white,
  },
  transparent: {
    background: `rgba(255, 255, 255, 0)`,
    color: "currentColor",
    border: tw`border border-gray-200 hover:border-transparent`,
  },
}

export { buttons }
