import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { linkResolver } from "utils/linkResolver"
import { formatCurrency } from "utils"
import tw from "twin.macro"
import { Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"
import { scrollElementIntoView } from "components/CTA/helper"

const StyledProductPrice = styled.span`
  font-family: ${(props) => props.theme.fontStacks.bold};
  ${tw`flex items-center justify-center`}
  font-size: 2.4rem;
  background-color: ${(props) => props.product_color};
  color: ${(props) => props.theme.colors.white};
  position: relative;
  // position: absolute;
  // margin-top: auto;

  .prepend {
    font-weight: normal;
    margin-right: 0.5rem;
    font-size: 1.6rem;
  }

  .append {
    font-weight: normal;
    margin-left: 0.5rem;
  }

  .price {
    padding: 0.5rem 1rem;
  }

  .price-value {
    font-family: ${(props) => props.theme.fontStacks.bold};

    ${(props) => props.theme.media.tablet_landscape_up`
      font-size: 3.6rem;
    `}
  }
`

function ProductPrice({
  text_before_price,
  text_after_price,
  current_price,
  currency,
  quote_link,
  children,
  ...props
}) {
  const current_price_formatted = formatCurrency(
    current_price / 100,
    currency,
    "da-dk",
    0
  )
  return (
    <StyledProductPrice {...props}>
      {quote_link && (
        <a
          href={quote_link.url}
          onClick={(e) => scrollElementIntoView(e, quote_link.url)}
          tw="absolute z-50 cursor-pointer inset-0"
        />
      )}
      <span className="price">
        <span className="prepend">{text_before_price}</span>
        <span className="price-value">{current_price_formatted}</span>
        <small className="append">{text_after_price}</small>
      </span>
      <Icon
        tw="self-center mt-2 ml-4"
        icon="chevron-right"
        size={IconSizeEnum.MD}
      />
    </StyledProductPrice>
  )
}

export default ProductPrice

ProductPrice.defaultProps = {}
ProductPrice.propTypes = {
  children: PropTypes.node,
}
