import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import tw from "twin.macro"
import { linkResolver } from "utils/linkResolver"
import Content from "components/Content"
import Image from "components/Image"
import Tag from "components/Blog/Tag"
import { getTopTags, slicesToText } from "components/Blog/util"
import _ from "lodash"
import { Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"

const StyledHeroImage = styled(Image)`
  /* height: 60vw; */
  height: 25rem;
  ${(props) => props.theme.media.tablet_landscape_up`
    height: 20rem;
  `}
`

const StyledFeedView = styled.div`
  position: relative;
  margin-top: 0;
  display: flex;
  align-items: center;
  > * {
    margin-bottom: 2rem;
  }
  .content-area {
    position: static;
    pointer-events: all;
    margin-left: 1rem;
    flex: 1 0 60%;
  }
  .thumbnail {
    width: 8rem;
    height: 6rem;
    position: relative;
    ${tw`mr-4`}

    ${(props) => props.theme.media.tablet_landscape_up`
      width: auto;
      height: auto;
      flex: 1 0 40%;
    `}
    > a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }
  .title {
    > * {
      margin: 0;
      font-size: 1.4rem;
      line-height: 1.2;
      font-family: ${(props) => props.theme.fontStacks.bold};
    }
    word-break: break-word;
    ${(props) => props.theme.media.tablet_landscape_up`
      word-break: normal;
      color: ${(props) => props.theme.colors.grayDark};
      padding: 0;
      > * {
        font-size: 2.2rem;
        line-height: 2.7rem;
      }
    `}
  }
  .tags {
    position: relative;
    z-index: 40;
    span {
      line-height: 1rem;
    }
    a {
      ${tw`text-lg`}
    }
  }
  .summary {
    white-space: pre-wrap; // preserve line breaks
    margin-top: 1rem;
    color: ${(props) => props.theme.colors.gray};
    line-height: 1.2;
    display: none;
    font-size: 1.2rem;
    /* ------- multiline ellipsis ------- */
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    /* ---------------------------------- */
    ${(props) => props.theme.media.tablet_landscape_up`
      display: -webkit-box;
      font-size: 1.6rem;
      line-height: 1.5;
    `}
  }
  .readmore {
    margin-top: 1rem;
    /* text-align: center; */
    > * {
      display: inline-block;
      width: 100%;
      padding: 5px 0;
      padding-right: 2rem;
    }
    ${(props) => props.theme.media.tablet_landscape_up`
      display: initial;
    `}
  }
`

function FeedView({ post, ...props }) {
  const { body: post_body, hero_image } = post.data
  const postTags = getTopTags(post.tags)
  const { readingTime } = post
  const bodyText = slicesToText(post_body)
  const postUrl = linkResolver(post)
  return (
    <StyledFeedView {...props}>
      <div className="thumbnail">
        {hero_image?.fluid && <StyledHeroImage fluid={hero_image.fluid} />}
        <Link to={postUrl} />
      </div>
      <div className="content-area">
        <div className="tags">
          {postTags.map((tag, i) => {
            return (
              <Tag key={`post_tags_${i}`} filled>
                #{tag}
              </Tag>
            )
          })}
        </div>
        <div className="title">
          <Content content={post.data.title.richText} />
        </div>
        <div className="summary">{post.data.summary}</div>
        <div tw="ml-auto text-lg inline-block flex items-baseline justify-between md:(text-2xl ml-auto mt-4)">
          <Link to={postUrl} tw="flex items-center text-brand-500 font-bold">
            <span tw="mr-4">Læs artikel</span>
            <Icon icon="chevron-right" size={IconSizeEnum.SM} />
          </Link>
          <span tw="text-xl text-brand-gray-base">
            <Icon icon="clock" tw="mr-2 w-2 h-2" size={IconSizeEnum.SM} />
            {`Læsetid ca. ${Math.ceil(readingTime.minutes).toFixed(0)} min.`}
          </span>
        </div>
      </div>
    </StyledFeedView>
  )
}

export default FeedView
