import React, { useReducer, createContext } from "react"
import { MenuTypeEnum } from "./app.types"
import { GlobalAppTypes, GlobalAppActionMap } from "./actions"
import { toggleItemInEnumArray } from "utils/array"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

type InitialAppStateType = {
  toggledMenus: MenuTypeEnum[]
  sidebarToggled: boolean
  settingsToggled: boolean
  navToggled: boolean
  currentBlogArticleSectionID: string | null
  disableMenuPin: boolean
}

const initialState: InitialAppStateType = {
  toggledMenus: [],
  navToggled: false,
  sidebarToggled: false,
  settingsToggled: false,
  currentBlogArticleSectionID: null,
  disableMenuPin: false,
}

const AppContext = createContext<{
  state: InitialAppStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

const mainReducer = (
  state: InitialAppStateType,
  action: GlobalAppActionMap
) => {
  switch (action.type) {
    case GlobalAppTypes.ToggleMenu:
      return {
        ...state,
        toggledMenus: toggleItemInEnumArray<MenuTypeEnum>(
          state.toggledMenus,
          action.payload.key
        ),
      }
    case GlobalAppTypes.ToggleNav:
      return {
        ...state,
        navToggled: action.payload.toggled
          ? action.payload.toggled
          : !state.navToggled,
      }
    case GlobalAppTypes.ToggleSettings:
      return {
        ...state,
        settingsToggled: action.payload.toggled
          ? action.payload.toggled
          : !state.settingsToggled,
      }
    case GlobalAppTypes.ToggleSidebar:
      return {
        ...state,
        sidebarToggled: action.payload.toggled,
      }
    case GlobalAppTypes.VisibleSection:
      return {
        ...state,
        currentBlogArticleSectionID: action.payload.sectionID,
      }
    case GlobalAppTypes.DisableMenuPin:
      return {
        ...state,
        disableMenuPin: action.payload,
      }
    case GlobalAppTypes.Reset:
      return initialState
    default:
      return state
  }
}

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
