import React from "react"
import styled from "styled-components"
import { Elements } from "prismic-reactjs"
import { linkResolver } from "utils/linkResolver"
import tw from "twin.macro"
import { Link } from "gatsby"

// for future, grouped images:
// https://community.prismic.io/t/htmlserializer-grouped-images/2863

// const RichImage = styled.img`
//   max-width: 50rem;
// `

const StyledHeadline = styled.h1`
  // margin-top: 0;

  span {
    ${tw`text-brand-500`}
  }
  ${(props) => props.theme.media.tablet_landscape_up`
    line-height: 1.3;
  `}
`

const StyledHyperlink = styled.a`
  ${tw`text-brand-500`}
  text-decoration: underline;
  max-width: 100%;
  text-overflow: ellipsis;
`

export const htmlSerializer = (type, element, content, children) => {
  let childrenMap
  switch (type) {
    case Elements.heading1:
      childrenMap = children
        .flatMap((x) => x)
        .map(
          (x) =>
            typeof x === "string" &&
            x.replace("*", "<span>").replace("*", "</span>")
        )
        .filter(Boolean)
        .join(" ")
      return (
        <StyledHeadline
          key="h1"
          dangerouslySetInnerHTML={{ __html: childrenMap }}
        />
      )
    case Elements.heading2:
      childrenMap = children
        .flatMap((x) => x)
        .map(
          (x) =>
            typeof x === "string" &&
            x.replace("*", "<span>").replace("*", "</span>")
        )
        .filter(Boolean)
        .join(" ")
      return (
        <StyledHeadline
          key="h2"
          as="h2"
          dangerouslySetInnerHTML={{ __html: childrenMap }}
        />
      )
    case Elements.heading3:
      childrenMap = children
        .flatMap((x) => x)
        .map(
          (x) =>
            typeof x === "string" &&
            x.replace("*", "<span>").replace("*", "</span>")
        )
        .filter(Boolean)
        .join(" ")
      return (
        <StyledHeadline
          key="h1"
          as="h3"
          dangerouslySetInnerHTML={{ __html: childrenMap }}
        />
      )
    case Elements.hyperlink:
      if (element.data?.type === "page") {
        const url = linkResolver(element.data)
        return (
          <StyledHyperlink as={Link} to={url}>
            {content}
          </StyledHyperlink>
        )
      }
      return (
        <StyledHyperlink
          href={element.data?.url}
          rel="noopener"
          target="_blank"
        >
          {content}
        </StyledHyperlink>
      )
    default:
      return null
  }
}
