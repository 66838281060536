/**
 * International Number Format string helper
 * @category numbers
 * @param {String} amount The amount to be formatted.
 * @param {String} currency The currency to use.
 * @param {String} lang The language to format by.
 */

export function formatCurrency(amount, currency, lang, digits) {
  const options = {
    style: "currency",
    currency: currency,
  };
  if (typeof digits !== `undefined`) {
    options.minimumFractionDigits = 0;
    options.maximumFractionDigits = digits;
  }
  return new Intl.NumberFormat(lang || "en-US", options).format(amount);
}

/**
 * International Number Format string helper for numbers with radix
 * @category numbers
 * @param {String} number The number to be formatted.
 * @param {String} lang The language to format by.
 */

export function formatFloat(number, lang) {
  return new Intl.NumberFormat(lang || "en-US").format(number);
}
