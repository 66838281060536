import React from "react"
import styled from "styled-components"
import Content from "components/Content"
import tw from "twin.macro"

const StyledLegalFooter = styled.div`
  ${tw`bg-brand-gray-light mt-16`}
  > * {
    opacity: 0.7;
    // letter-spacing: 0.05rem;
    ${tw`text-xl m-0`}
  }

  padding: 2rem;
  max-width: 64rem;
  font-family: ${(props) => props.theme.fontStacks.light};

  ${(props) => props.theme.media.tablet_landscape_up`
    text-align: center;
    margin: 0 auto;
    ${tw`mt-16`}
  `}
`

function LegalFooter({ content, ...props }) {
  console.log("content", content)
  if (!content.text || content.text === "") return null
  return (
    <StyledLegalFooter {...props}>
      <Content content={content.richText} />
    </StyledLegalFooter>
  )
}

export default LegalFooter
