import React, { useLayoutEffect, useRef, useState } from "react"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import { Responsive } from "@clevertrack/shared"
import Accordion from "lib/Accordion"
import tw from "twin.macro"
import NavList from "./NavList"
import NavLink from "./NavLink"
import { navigationChildrenResolver } from "./helper"
import { NavigationTag } from "./NavigationTag"

const StyledNavItem = styled.li`
  font-size: 1.6rem;
  position: relative;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0;
  `};
`

const StyledNavLink = styled(NavLink)`
  // font-family: ${(props) => props.theme.fontStacks.bold};
  ${tw`font-bold`}
  text-decoration: none;
  color: ${(props) => props.theme.colors.blackLight};

  ${(props) => props.theme.media.tablet_landscape_up`
    min-height: 6rem;
    font-weight: normal;
    font-size: initial;
  `};
`

const StyledSubNavigation = styled((props) => <NavList {...props} />)`
  list-style: none;
  margin: 0;
  padding: 0.5rem 0;

  li {
    padding: 0.5rem 1.5rem 0.5rem 3rem;
  }

  a {
    font-weight: normal;
    font-size: 1.4rem;
    padding: 0;

    &.active,
    &:hover {
      padding-left: 1.5rem;

      &:before {
        left: 0;
      }
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
    flex-direction: column;

    li {
      padding: 0.5rem 1.5rem;
    }

    a {
      min-height: 0;
    }
  `}
`

const StyledDropdown = styled(Accordion)`
  position: absolute;
  right: 0;
  top: 100%;
  width: 24rem;
  ${tw`bg-white shadow-mapButton`}

  ${(props) => props.theme.media.tablet_landscape_up`
    left: 2rem;
    right: auto !important;
    width: auto;
    min-width: 25rem;
  `}
`

function NavItemComponent({
  data,
  url,
  text,
  nav_children,
  childNavUIDmap,
  tag,
  top_level,
  ...props
}) {
  const {
    allPrismicNavigation: { navs },
  } = data

  const [dropdownToggled, setDropdownToggled] = useState(false)
  const navChildren =
    nav_children && navigationChildrenResolver(nav_children, navs)?.data

  function onMouseEnter() {
    setDropdownToggled(true)
  }

  function onMouseLeave() {
    setDropdownToggled(false)
  }

  const location = useLocation()
  const matchChildUID =
    !!childNavUIDmap &&
    childNavUIDmap.some(
      (uid) =>
        `/${uid}` === decodeURI(location.pathname) ||
        `/${uid}/` === decodeURI(location.pathname)
    )

  useLayoutEffect(() => {
    return () => {
      setDropdownToggled(false)
    }
  }, [])

  return (
    <StyledNavItem
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      {...props}
    >
      <StyledNavLink
        to={url}
        activeClassName="active"
        // partiallyActive={}
        className={
          matchChildUID || (matchChildUID && location.href.indexOf("#") > 0)
            ? "active"
            : ""
        }
        tw="md:(flex items-center justify-between)"
      >
        <span tw="flex-shrink">{text}</span>
        {tag && (
          <NavigationTag tw="flex-1" isTopLevel={top_level} content={tag} />
        )}
      </StyledNavLink>
      {navChildren && (
        <Responsive
          phone={<StyledSubNavigation items={navChildren.navigation_items} />}
          tabletLandscape={
            <StyledDropdown toggled={dropdownToggled}>
              <StyledSubNavigation items={navChildren.navigation_items} />
            </StyledDropdown>
          }
        />
      )}
    </StyledNavItem>
  )
}

const NavItem = (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicNavigation {
          navs: edges {
            node {
              prismicId
              id
              data {
                navigation_items {
                  navigation_link {
                    document {
                      ... on PrismicPage {
                        data {
                          title {
                            richText
                            text
                          }
                        }
                        id
                        lang
                        uid
                        type
                      }
                    }
                  }
                  navigation_children {
                    link_type
                    document {
                      ... on PrismicNavigation {
                        data {
                          navigtion_title {
                            richText
                            text
                          }
                        }
                        id
                        lang
                        type
                      }
                    }
                  }
                  navigation_link_text
                  tag
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <NavItemComponent data={data} {...props} />}
  />
)

export default NavItem

NavItemComponent.defaultProps = {}
NavItemComponent.propTypes = {
  children: PropTypes.node,
}
