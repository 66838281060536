import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import Content from "components/Content"
import Icon from "lib/Icon"
import { linkResolver } from "utils/linkResolver"
import { getIconFromUrl } from "utils/iconResolver"
import { StyledHyperlink, bulletsSerializer } from "components/HeroSection"

const StyledAddonFeature = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 9rem auto;
  ${tw`shadow-card`}
`

const StyledBackgroundIcon = styled(Icon)`
  opacity: 5%;
  ${tw`w-96 h-96 absolute -right-20 bottom-10 z-1`}
`

const StyledHeader = styled.header`
  position: relative;
  display: grid;
  grid-template-columns: 5rem auto;
  grid-column-gap: 2rem;
  align-items: center;
  line-height: 1.2;
  ${tw`border-0 p-8 pt-0 pb-6 border-b`}

  h3 {
    margin: 0;
    font-size: 2.1rem;
    ${tw`text-brand-secondary`}
  }
`

const IconContainer = styled.div`
  transform: translateY(-4px);
  position: relative;
  ${tw`text-white flex place-content-center bg-brand-500 shadow-card`}
  width: 5rem;
  height: 7rem;
`

const StyledIcon = styled(Icon)`
  ${tw`text-current`}
  position: absolute;
  // top: 0.5rem;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.6);
`

const ContentContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${tw`px-8 pb-8 text-xl border-0 border-b-2 border-solid border-brand-500`}
  h3 {
  }

  .benefits {
    > ul {
      ${tw`p-0`}
    }
  }

  a {
    ${tw`text-brand-500 mb-8 flex items-center justify-center`}
  }
`

const BottomContainer = styled.div`
  ${tw`mt-auto border-0 border-t border-solid border-brand-gray-light p-4 pb-0`}

  button {
  }
`

const Price = styled.span`
  ${tw`text-center text-brand-secondary pt-0 block`}
  /* font-weight: 600; */

  .prepend {
    ${tw`mr-4 text-2xl`}
  }

  .price {
    font-size: 3.2rem;
    font-family: ${(props) => props.theme.fontStacks.heading};
  }

  .month {
    // letter-spacing: 0.1rem;
    ${tw`block text-lg`}
  }
`

function AddonFeature({ feature, buttonText, ...props }) {
  const { addon_title, addon_benefits, icon, link, price, payment_term } =
    feature
  const { image } = feature

  return (
    <>
      <StyledAddonFeature {...props}>
        {/* <ImageContainer>
        <Image fluid={image.localFile.childImageSharp.fluid} />
      </ImageContainer> */}
        <StyledHeader>
          <IconContainer>
            <StyledIcon icon={getIconFromUrl(icon.url)} size="lg" />
          </IconContainer>
          <Content content={addon_title.richText} />
        </StyledHeader>

        <ContentContainer>
          <div className="benefits">
            <Content
              content={addon_benefits.richText}
              htmlSerializer={(type, element, c, children) =>
                bulletsSerializer(
                  type,
                  element,
                  c,
                  children,
                  "#fff",
                  "#00B8B4",
                  "check"
                )
              }
            />
          </div>
          {link.document && (
            <StyledHyperlink
              tw="relative cursor-pointer z-10"
              href={linkResolver(link.document)}
            >
              {buttonText}
              <Icon icon="long-arrow-right" />
            </StyledHyperlink>
          )}
          <StyledBackgroundIcon icon={getIconFromUrl(icon.url)} />
          {price && (
            <BottomContainer>
              <Price>
                <span className="prepend">fra</span>
                <span className="price">{price} kr.</span>
                <span className="month">{payment_term}</span>
              </Price>
            </BottomContainer>
          )}
        </ContentContainer>
      </StyledAddonFeature>
    </>
  )
}

export default AddonFeature

AddonFeature.defaultProps = {}
AddonFeature.propTypes = {}
