import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import Inner from "components/Container/Inner"
import Content from "components/Content"
import Container from "components/Container"
import Feature from "./"
import { colorThemes } from "components/Paragraph"
import { htmlSerializer } from "components/HeroSection"

const StyledContainer = styled(Container)`
  padding: 4rem 2rem;
`

const StyledFeatureRepeater = styled(Inner)`
  padding: 2rem 0.3rem;
  text-align: center;
  .feature__list {
    margin: 0;
    padding: 0;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        margin-bottom: 0;
      }
    }
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    .feature__list {
      flex-wrap: wrap;
      flex-direction: row;

      li {
        margin: 0 1rem;
        flex: 0 0 40%;
      }
    }
  `}

  ${(props) => props.theme.media.tablet_landscape_up`

    .feature__list {
      padding-top: 4rem;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      li {
        flex: 0 0 25%;
      }
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    .feature__list {
      > * {
        flex: 1 0 20%;
        max-width: 25%;
      }
    }
  `}
`

function FeatureRepeater({
  features_title,
  items,
  features_content,
  theme,
  ...props
}) {
  return (
    <StyledContainer css={colorThemes[theme ?? ""]}>
      <StyledFeatureRepeater>
        <Content
          content={features_title.richText}
          htmlSerializer={htmlSerializer}
        />
        <div tw="md:(w-3/4 mx-auto)">
          <Content content={features_content.richText} />
        </div>
        <ul className="feature__list">
          {items.map((item, i) => {
            return <Feature key={`feauture_${i}`} {...item} />
          })}
        </ul>
      </StyledFeatureRepeater>
    </StyledContainer>
  )
}

export default FeatureRepeater

FeatureRepeater.defaultProps = {}
FeatureRepeater.propTypes = {}
