import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Container from "components/Container"
import Content from "components/Content"
import AddonFeature from "./AddonFeature"
import LegalFooter from "components/LegalFooter"
import CTARepeater from "components/CTA/Repeater"

const StyledAddonProducts = styled(Container)`
  padding: 4rem 2rem;
  margin: auto;

  max-width: ${(props) => (props.columns === 2 ? "90rem" : "110rem")};

  h2 {
    text-align: center;
    font-size: 200%;
  }
`

const FeatureContainer = styled.div`
  margin-top: 4rem;
  margin-bottom: 5rem;
  display: grid;
  gap: 8rem;
  grid-template-columns: ${(props) =>
    props.columns === 2
      ? "repeat(auto-fit, minmax(31rem, 1fr))"
      : "repeat(auto-fit, minmax(21rem, 1fr))"};
`

function AddonProducts({ content, items, ...props }) {
  const { addons_title, button_text, legal_footer, cta_link, cta_link_text } =
    content

  const getColumnCount = (featureCount) => {
    if (featureCount < 3 || featureCount === 4) {
      return 2
    } else {
      return 3
    }
  }

  const columnCount = getColumnCount(items.length)

  return (
    <StyledAddonProducts columns={columnCount} {...props}>
      <Content content={addons_title.richText} />
      <FeatureContainer columns={columnCount}>
        {items.map((feature) => {
          return <AddonFeature feature={feature} buttonText={button_text} />
        })}
      </FeatureContainer>
      <CTARepeater items={[{ cta_link, cta_link_text }]} />
      <LegalFooter content={legal_footer} />
    </StyledAddonProducts>
  )
}

export default AddonProducts

AddonProducts.defaultProps = {}
AddonProducts.propTypes = {}
