import { css } from "styled-components"

const tablet_portrait_up = 600
const tablet_landscape_up = 900
const desktop_up = 1200
const big_desktop_up = 1500
const xxl_desktop_up = 1800
const xxxl_desktop_up = 2400

const sizes = {
  tablet_portrait_up,
  tablet_landscape_up,
  desktop_up,
  big_desktop_up,
  xxl_desktop_up,
  xxxl_desktop_up
}

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export { media }
