import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"
import Image from "components/Image"
import Container from "components/Container"
import { useScrollPosition } from "hooks/useScrollPosition"
import useViewport from "hooks/useViewport"
import { isViewport } from "helpers"
import tw from "twin.macro"

const StyledDevices = styled.div`
  padding: 8rem 2rem;
  margin: 2rem 0;
  text-align: center;
  ${tw`bg-brand-gray-lighter`}

  h2 {
    font-size: 170%;
    margin-top: 0;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 8rem 2rem;
    margin: 2rem 0;
    h2 {
      font-size: 250%;
    }
  `}
`

const DeviceLayoutWrapper = styled.div`
  position: relative;
  margin-bottom: -180px;

  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.media.tablet_portrait_up`
    flex-direction: row;
    top: 100px;
  `}
  ${(props) => props.theme.media.tablet_landscape_up`
    margin-bottom: auto;
    top: 0px;
  `}
  ${(props) => props.theme.media.big_desktop_up`
    top: 140px;
  `}
`

const DeviceImage = styled.div`
  position: relative;
  img {
    height: auto !important;
  }

  z-index: ${(props) => props.mobileTransform?.zIndex || `auto`};
  order: ${(props) => props.mobileTransform?.order || `initial`};
  align-self: ${(props) => props.mobileTransform?.selfAlign || `auto`};
  top: ${(props) => props.mobileTransform?.top || `auto`};
  left: ${(props) => props.mobileTransform?.left || `auto`};
  right: ${(props) => props.mobileTransform?.right || `auto`};
  bottom: ${(props) => props.mobileTransform?.bottom || `auto`};

  ${(props) => props.theme.media.tablet_portrait_up`
    z-index: ${(props) => props.desktopTransform?.zIndex || `auto`};
    order: ${(props) => props.desktopTransform?.order || `initial`};
    align-self: ${(props) => props.desktopTransform?.selfAlign || `auto`};
    top: ${(props) => props.desktopTransform?.top || `auto`};
    left: ${(props) => props.desktopTransform?.left || `auto`};
    right: ${(props) => props.desktopTransform?.right || `auto`};
    bottom: ${(props) => props.desktopTransform?.bottom || `auto`};
  `}
`

const StyledImage = styled(Image)`
  /* height: 100% !important; */
`

const clamp = (number, min, max) => Math.min(Math.max(number, min), max)

const RenderDevice = (
  imageObj,
  viewport,
  desktopTransform,
  mobileTransform
) => {
  const image = imageObj.localFile
  return (
    <DeviceImage
      desktopTransform={desktopTransform}
      mobileTransform={mobileTransform}
      style={
        !isViewport(viewport, ["PHONE_ONLY"])
          ? {
              transform: `translateY(${
                desktopTransform?.scrollSpeed || 0
              }) scale(${desktopTransform?.size || 1})`,
            }
          : {
              transform: `translateY(${
                mobileTransform?.scrollSpeed || 0
              }) scale(${mobileTransform?.size || 1})`,
            }
      }
    >
      <StyledImage
        fixed={
          !isViewport(viewport, ["PHONE_ONLY"])
            ? image.childImageSharp.desktop
            : image.childImageSharp.mobile
        }
      />
    </DeviceImage>
  )
}

function Devices({ ...props }) {
  const { content, desktop_image, tablet_image, mobile_image } = props

  const scrollPosition = useScrollPosition()
  const viewport = useViewport()

  const desktopTransforms = {
    monitor: {
      zIndex: 3,
      size: 1.3,
      right: "6.5%",
      top: "60px",
      scrollSpeed: `-${clamp(scrollPosition * 0.25 - 180, 0, 150)}px`,
    },
    tablet: {
      zIndex: 2,
      size: 1.02,
      top: "60px",
      right: "1.5%",
      scrollSpeed: `-${clamp(scrollPosition * 0.3 - 300, 0, 150)}px`,
    },
    mobile: {
      zIndex: 3,
      size: 1.3,
      top: "100px",
      left: "0.5%",
      scrollSpeed: `-${clamp(scrollPosition * 0.4 - 300, 0, 150)}px`,
    },
  }

  const mobileTransforms = {
    monitor: {
      zIndex: 1,
      size: 1,
    },
    tablet: {
      zIndex: 2,
      order: 2,
      selfAlign: "flex-end",
      size: 1,
      bottom: "20px",
    },
    mobile: {
      zIndex: 3,
      size: 1,
      order: 3,
      selfAlign: "flex-start",
      bottom: "120px",
    },
  }

  return (
    <StyledDevices {...props}>
      <Container>
        <Content content={content.richText} />
        <DeviceLayoutWrapper>
          {RenderDevice(
            mobile_image,
            viewport,
            desktopTransforms.mobile,
            mobileTransforms.mobile
          )}
          {RenderDevice(
            tablet_image,
            viewport,
            desktopTransforms.tablet,
            mobileTransforms.tablet
          )}
          {RenderDevice(
            desktop_image,
            viewport,
            desktopTransforms.monitor,
            mobileTransforms.monitor
          )}
        </DeviceLayoutWrapper>
      </Container>
    </StyledDevices>
  )
}

export default Devices

Devices.defaultProps = {}
Devices.propTypes = {}
