import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledLabel = styled.span`
  position: relative;
  padding: 0.6rem 1.2rem;

  span {
    position: relative;
    z-index: 20;
    color: ${(props) => props.theme.colors.white};
    font-family: ${(props) => props.theme.fontStacks.heading};
    letter-spacing: 0.06rem;
    font-size: 1.4rem;
  }
`

function Label({ children, ...props }) {
  return <StyledLabel {...props}>{children}</StyledLabel>
}

export default Label

Label.defaultProps = {}
Label.propTypes = {
  children: PropTypes.node.isRequired,
}
