import { colors } from "./colors"

const tags = {
  default: {
    background: colors.gray,
    color: colors.white,
  },
  success: {
    background: colors.green,
    color: colors.white,
  },
  info: {
    background: colors.blue,
    color: colors.white,
  },
  danger: {
    background: colors.red,
    color: colors.white,
  },
  warning: {
    background: colors.yellow,
    color: colors.black,
  },
}

export { tags }
