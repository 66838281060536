import React from "react"
import styled from "styled-components"
import { siteTheme as theme } from "theme"

const TooltipTriangleSize = ".5rem"

const StyledTooltipBase = styled.div<any>`
  opacity: ${(props) => props.infoCss.opacity};
  transition: opacity ${(props) => props.infoCss.transition}s;
  transition-delay: ${(props) => props.infoCss.delay}s;
  display: block;
  position: absolute;
  z-index: 2050;
  // font-weight: bold;
  pointer-events: none;

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
  }
`

const StyledTooltipLeft = styled(StyledTooltipBase)`
  top: 50%;
  right: calc(100% + 2rem);
  transform: translateY(-50%);

  &:after {
    border-left: ${TooltipTriangleSize} solid ${theme.colors.white};
    border-top: ${TooltipTriangleSize} solid transparent;
    border-bottom: ${TooltipTriangleSize} solid transparent;
    right: -${TooltipTriangleSize};
    top: 50%;
    transform: translateY(-50%);
  }
`

const StyledTooltipRight = styled(StyledTooltipBase)`
  opacity: ${(props) => props.infoCss.opacity};
  transition: opacity ${(props) => props.infoCss.transition}s;
  transition-delay: ${(props) => props.infoCss.delay}s;
  display: block;
  position: absolute;
  z-index: 2050;
  // font-weight: bold;
  pointer-events: none;

  &:after {
    border-left: ${TooltipTriangleSize} solid transparent;
    border-right: ${TooltipTriangleSize} solid transparent;
    border-top: ${TooltipTriangleSize} solid ${theme.colors.white};
    bottom: -${TooltipTriangleSize};
    left: 50%;
    transform: translateX(-50%);
  }
`

function Tooltip({ toggled, omitToggleCss, position, children, ...props }) {
  if (omitToggleCss) {
    return (
      <StyledTooltipRight
        infoCss={{
          opacity: 1,
          transition: 0,
          delay: 0,
        }}
        {...props}
      >
        {children}
      </StyledTooltipRight>
    )
  }

  const toggledCss = {
    opacity: toggled ? 1 : 0,
    transition: toggled ? 0.2 : 0.4,
    delay: toggled ? 0.1 : 0,
  }
  return position === "left" ? (
    <StyledTooltipLeft infoCss={toggledCss} position={position} {...props}>
      {children}
    </StyledTooltipLeft>
  ) : (
    <StyledTooltipRight infoCss={toggledCss} position={position} {...props}>
      {children}
    </StyledTooltipRight>
  )
}

export { Tooltip }

/* Tooltip.defaultProps = {}
Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
}
 */
