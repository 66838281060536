// path is the current page path (url excluding the domain name)
const paginationResolver = (path) => {
  const urlParts = path.split("/").filter(Boolean)
  let urlBase = urlParts.join("/")
  if (+urlParts[urlParts.length - 1]) {
    urlBase = urlParts.slice(0, urlParts.length - 2).join("/")
  }
  const isTagFeed = urlBase.split("/").filter(Boolean).length > 1
  return { urlBase, isTagFeed }
}

export { paginationResolver }
