// const dump = require("./dump")
// console.log("-----------------------------------------------------------------")

const columnsFromProducts = (data) => {
  const products = data.map((item) => {
    const { products } = item.document.data
    return products
      .map((product) => {
        return product.product.document?.data
          ? { [product.product.document.id]: product }
          : null
      })
      .filter(Boolean)
  })

  let output = {}
  products.map((item) =>
    item.map((sub) => {
      for (const key in sub) {
        const value = sub[key]
        // extractDocument(value.product) // MUTATIVE!
        Object.assign(output, {
          [key]: value.product.document.data,
        })
      }
    })
  )
  return output
}

export { columnsFromProducts }
