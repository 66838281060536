import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

const StyledNavigationTag = styled.span<{ isTopLevel: boolean }>`
  font-size: 1.1rem;
  padding: 0.1rem 0.5rem;
  margin-left: 2rem;
  // font-family: ${(props) => props.theme.fontStacks.bold};
  ${tw`bg-brand-500 font-bold text-white`}

  ${(props) => props.theme.media.tablet_landscape_up`
    /* position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    right: 0;
    margin-left: 0; */
    ${
      props.isTopLevel
        ? `position: absolute; top: -0.3rem;
            right: auto;
            left: 3rem;
            transform: none; margin-left: auto;`
        : ``
    }

  `};
`

export const NavigationTag: React.FC = ({ isTopLevel, content, ...props }) => {
  return (
    <StyledNavigationTag isTopLevel={isTopLevel}>{content}</StyledNavigationTag>
  )
}
