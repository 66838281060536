import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"
import { Responsive } from "@clevertrack/shared"
import CTARepeater from "components/CTA/Repeater"
import tw from "twin.macro"

const headerSize = "12rem"

const StyledHeroSection = styled.div`
  position: relative;
  padding: 0;
`

const StyledContentContainer = styled.div`
  position: absolute;
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding: 2rem 2rem 4rem;
  width: 100%;
  color: ${(props) => props.theme.colors.grayLighter};
  text-align: center;
  h1 {
    font-size: 3.4rem;
    line-height: 1.2;
    //font-family: ${(props) => props.theme.fontStacks.heading};
  }
  p {
    font-size: 1.8rem;
    line-height: 1.4;
    color: ${(props) => props.theme.colors.grayLight};
  }
  br {
    display: none;
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    h1 {
      letter-spacing: 0;
      font-size: 4.2rem;
      line-height: 1.25em;
    }
    p {
      font-size: 2.4rem;
      line-height: 1.5em;
    }
    br {
      display: inline;
    }
  `}

  @media (min-width: 600px) and (orientation: landscape) {
    h1 {
      letter-spacing: 0;
      font-size: 2.6rem;
      line-height: 1.25em;
    }
    p {
      font-size: 1.8rem;
      line-height: 1.5em;
    }
    br {
      display: inline;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: 70vw;

    h1 {
      letter-spacing: 0;
      font-size: 4.2rem;
      line-height: 1.25em;
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    text-align: center;
    max-width: 100rem;
    h1 {
      font-size: 6.25rem;
    }
    p {
      font-size: 2.4rem;
    }
  `}
`

const BackgroundImageContainer = styled.div`
  height: 80vh;
  overflow: hidden;
  position: relative;

  ${(props) => props.theme.media.tablet_portrait_up`
    height: 80vh;
  `}

  ${(props) => props.theme.media.desktop_up`
    height: 74rem;
  `}
`

const BgVideo = styled.div`
  position: relative;
  z-index: -10;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;

    ${(props) => props.theme.media.tablet_landscape_up`
      // margin-top: -15rem;
    `}
  }
`

const Overlay = styled.div`
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  ${tw`bg-brand-black-base opacity-30`}
`

function HeroSection({ ctas, ...props }) {
  const { headline, content, desktop_image, mobile_image } = props

  return (
    <StyledHeroSection>
      <BackgroundImageContainer>
        <Responsive
          phone={
            <>
              <BgVideo>
                <Overlay />
                <video
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  controls={false}
                  playsInline={true}
                >
                  <source src="https://firebasestorage.googleapis.com/v0/b/clevertrack-282807.appspot.com/o/web%2Fmovies%2Fclevertrack-hero-video-mobile.mp4?alt=media&token=bd3309fa-60bc-4fe3-8c50-dd188670e075" />
                </video>
              </BgVideo>
            </>
          }
          tabletLandscape={
            <>
              <BgVideo>
                <Overlay />
                <video autoPlay={true} muted={true} loop={true}>
                  <source src="https://firebasestorage.googleapis.com/v0/b/clevertrack-282807.appspot.com/o/web%2Fmovies%2Fclevertrack-hero-video.mp4?alt=media&token=389e57b3-0003-4ce3-a18b-d31d1e7e53b0" />
                </video>
              </BgVideo>
            </>
          }
        />
      </BackgroundImageContainer>
      <StyledContentContainer>
        <Content content={headline.richText} />
        <Content content={content.richText} />
        <Responsive
          phone={<CTARepeater items={ctas} position="center" />}
          tabletLandscape={<CTARepeater items={ctas} position="center" />}
        />
      </StyledContentContainer>
    </StyledHeroSection>
  )
}

export default HeroSection

HeroSection.defaultProps = {}
HeroSection.propTypes = {
  children: PropTypes.node,
}
