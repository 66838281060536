import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import { graphql } from "gatsby"
import styled from "styled-components"
import SliceZone from "./SliceZone"
import Content from "components/Content"
import Container from "components/Container"
import { htmlSerializer } from "components/HeroSection"
import ResponsiveImage from "components/ResponsiveImage"

const StyledLeadModule = styled.section`
  text-align: center;
  ${tw`mt-16`}

  &.aside {
    text-align: left;
    ${(props) => props.theme.media.tablet_landscape_up`
      .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // max-width: 50rem;
      }

      .content {
        max-width: 50rem;
        ${tw`p-12`}
      }

      .form {
        margin-right: 2rem;
      }
    `}

    ${(props) => props.theme.media.desktop_up`
      .form {
        margin-right: 1rem;
      }
    `}
  }

  .content {
    ${tw`px-8 md:(px-0)`}
    ${(props) => props.theme.media.tablet_landscape_up`
      .inner {
        max-width: 50rem;
      }
    `}
  }
`

function LeadModule({
  lead_module,
  lead_title,
  lead_content,
  layout,
  person,
  ...props
}) {
  const { uid } = lead_module.document
  return layout === "Centered" ? (
    <StyledLeadModule id={uid} {...props}>
      <Container className="content">
        <Content content={lead_title.richText} />
        <Content content={lead_content.richText} />
      </Container>
      <SliceZone slices={lead_module.document.data.body} {...props} />
    </StyledLeadModule>
  ) : (
    <StyledLeadModule className="aside" id={uid} {...props}>
      <Container>
        <div className="content">
          <Content content={lead_title.richText} serializer={htmlSerializer} />
          <Content content={lead_content.richText} />
          {person.document?.data && (
            <div tw="flex items-center mt-8 pt-8 border-solid border-brand-gray-light border-0 border-t">
              <span tw="w-36 h-36 overflow-hidden flex place-content-center rounded-full">
                <ResponsiveImage
                  tw="object-cover"
                  img={person.document.data.image}
                />
              </span>
              <span tw="ml-8">
                <span tw="flex font-bold text-2xl items-center">
                  <span>{`${person.document.data.first_name} ${person.document.data.last_name}`}</span>
                </span>
                <span tw="flex items-center text-xl">
                  {person.document.data.role}
                </span>
                <span tw="flex items-center text-xl mt-4 text-brand-500">
                  <a
                    href={`tel:${person.document.data.phone}`}
                  >{`Ring til mig på ${person.document.data.phone}`}</a>
                </span>
              </span>
            </div>
          )}
        </div>
        <div className="form">
          <SliceZone slices={lead_module.document.data.body} {...props} />
        </div>
      </Container>
    </StyledLeadModule>
  )
}

export default LeadModule

LeadModule.defaultProps = {}
LeadModule.propTypes = {}

export const query = graphql`
  fragment LeadModule on PrismicLeadModule {
    uid
    data {
      title {
        richText
      }
      privacy_policy_link {
        document {
          ... on PrismicPage {
            id
            type
            uid
            lang
          }
        }
      }
      body {
        ... on PrismicLeadModuleDataBodyHubspot {
          ...HubspotModuleFragment
        }
      }
    }
  }
`
