import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"

const StyledRelatedPosts = styled.div`
  .more {
    background: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.white};
    padding: 1rem;
    margin: 1rem 0;
    font-family: ${(props) => props.theme.fontStacks.bold};
    display: inline-block;
  }
`

const PreviewsLayout = styled.div`
  /* grid-template-columns: repeat(auto-fit, minmax(min(30rem, 100%), 1fr)); */
  > * {
    margin-bottom: 3rem;
  }
  ${(props) => props.theme.media.tablet_landscape_up`
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
    > * {
      margin-bottom: 0rem;
    }
  `}
`

function RelatedPosts({ children, ...props }) {
  return (
    <StyledRelatedPosts {...props}>
      <span class="more">Mere fra clevertrack</span>
      <PreviewsLayout>{children}</PreviewsLayout>
    </StyledRelatedPosts>
  )
}

export default RelatedPosts

RelatedPosts.propTypes = {
  children: PropTypes.node.isRequired,
}
