/**
 * Collection helpers
 * @category collection
 * @param {array} collection Collection to iterate on
 * @param {number} itemId The ID to look for
 * @param {function} newItem function to perform on the found item
 */

export function updateCollectionObjectByItemId(collection, itemId, newItem) {
  const updatedCollection = collection.map((item) => {
    // Return items that we don't want to change
    if (item.id !== itemId) return item;
    // Match!
    return Object.assign(
      {},
      item,
      {
        ...newItem,
      }
    );
  });

  return updatedCollection;
}

/**
 * Replace item in an immutable array
 * @category collection
 * @param {array} items Array of items to update
 * @param {object} item New item
 * @param {number} index The index of the item to update
 */
export function replaceItemAtIndex(items, item, index) {
  return [
    ...items.slice(0, index),
    item,
    ...items.slice(index + 1),
  ];
}

/**
 * Removes an item in a collection that matches the ID, and returns a new collection
 * @category collection
 * @param {array} collection Collection in which to find the item to remove
 * @param {number} id ID to remove
 */

export function removeItemById(collection, id) {
  const index = collection.findIndex(x => x.id === id)
  return [
    ...collection.slice(0, index),
    ...collection.slice(index + 1)
  ];
}
