import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { ViewportContext } from "context"
import { isViewport } from "helpers"
import Image from "components/Image"
// import ImageData from "components/Image/ImageData"

const StyledImg = styled.img`
  width: 100%;
  object-fit: cover;
  line-height: 0;
`

function ResponsiveImage({ img, alt, horizontal, ...props }) {
  const viewport = useContext(ViewportContext)

  let imgSrc =
    img && img.localFile ? img.localFile.childImageSharp?.square : img.url

  if (typeof imgSrc === "string") {
    return <StyledImg src={imgSrc} alt={alt} {...props} />
  }

  if (!imgSrc) {
    // Handle scenarios where the image is not square
    imgSrc = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
      ? img.hasOwnProperty("mobile")
        ? img.mobile.url
        : img.url
      : img.url

    if (
      horizontal &&
      !isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
    ) {
      imgSrc = img.Horizontal.url
    }
    return <StyledImg src={imgSrc} alt={alt} {...props} />
  }

  // Image is square
  return (
    <StyledImg srcSet={imgSrc.images.sources[0].srcSet} alt={alt} {...props} />
  )

  if (img && img.hasOwnProperty("childImageSharp")) {
    imgSrc = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
      ? img.childImageSharp.mobile
      : img.childImageSharp.desktop

    if (
      horizontal &&
      !isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
    ) {
      imgSrc = img.childImageSharp.horizontal
    }

    return <Image fixed={imgSrc} alt={alt} {...props} />
  } else if (img) {
    imgSrc = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
      ? img.hasOwnProperty("mobile")
        ? img.mobile.url
        : img.url
      : img.url

    if (
      horizontal &&
      !isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
    ) {
      imgSrc = img.Horizontal.url
    }
    return <StyledImg src={imgSrc} alt={alt} {...props} />
  }
  return null
}

export default ResponsiveImage

ResponsiveImage.defaultProps = {
  alt: "",
}
ResponsiveImage.propTypes = {
  img: PropTypes.object,
  alt: PropTypes.string,
}
