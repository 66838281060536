import React from 'react'
import { Elements } from "prismic-reactjs"

export const htmlSerializer = (type, element, content, children) => {
  switch (type) {
    case Elements.heading2:
      return <h2 key="h2" className="h2">{children}</h2>
    default:
      return null
  }
}

export const htmlFooterSerializer = (type, element, content, children) => {
  switch (type) {
    case Elements.heading3:
      return <h3 key="h3" className="h3">{children}</h3>
    default:
      return null
  }
}
