import React, { useContext, useLayoutEffect } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import "twin.macro"
import styled from "styled-components"
import OffCanvas from "lib/OffCanvas"
import Icon from "lib/Icon"
import { AppContext, NavigationContext } from "context"
import { useLockBodyScroll } from "hooks/useLockBodyScroll"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"

const StyledOffCanvas = styled(OffCanvas)`
  z-index: 20000;
`

const StyledOffCanvasNavigation = styled.div`
  overflow-y: auto;
  padding-bottom: 8rem;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  position: relative;

  a {
    color: ${(props) => props.theme.colors.secondary};
  }
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 0 2rem;
  }
`

const StyledFooter = styled.footer`
  border-top: 5px solid ${(props) => props.theme.colors.primary};
  margin-top: 8rem;
  padding: 2rem;

  a {
    color: ${(props) => props.theme.colors.white};
  }
`

const StyledNavToggle = styled.div`
  z-index: 100;
  width: 100%;
  padding: 3rem 1.5rem 2rem 1.5rem;

  svg:last-child {
    margin-right: 0;
  }
`

const StyledNavInner = styled.nav``

const StyledLogo = styled.div`
  svg {
    width: 17.05rem;
    height: 2.2rem;
    fill: ${(props) => props.theme.colors.secondary};
  }
`

function OffCanvasNav({ navToggle, nav, children }) {
  const {
    state: { navToggled },
    dispatch,
  } = useContext(AppContext)
  useLockBodyScroll(navToggled)
  const location = useLocation()

  useLayoutEffect(() => {
    if (navToggled)
      dispatch(GlobalAppActions(GlobalAppTypes.ToggleNav, { toggled: false }))
  }, [location.pathname])

  return (
    <StyledOffCanvas selector="#___offcanvas" show={navToggled} fromRight>
      <StyledOffCanvasNavigation>
        <StyledHeader>
          <StyledNavToggle tw="pb-4 border-solid border-0 border-b border-brand-gray-lighter flex items-center">
            {navToggle}
          </StyledNavToggle>
        </StyledHeader>
        <StyledNavInner>{nav}</StyledNavInner>
      </StyledOffCanvasNavigation>
    </StyledOffCanvas>
  )
}

export default OffCanvasNav

OffCanvasNav.defaultProps = {
  children: null,
}

OffCanvasNav.propTypes = {
  navToggle: PropTypes.node.isRequired,
  nav: PropTypes.node.isRequired,
  children: PropTypes.node,
}
