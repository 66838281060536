import { minimumFontSize, verticalRhythm } from '../typography'
import { getShorthand } from '../utils/boxmodel'

export const button = {
  getSize: (sizes, iconPos) => {
    // sizes is an array of four values. Map over the array, and add 'rem' to the value, if it isn't 0.
    let [t, r, b, l] = getShorthand(sizes)

    if (iconPos === 'left') {
      l = l - (l * 0.75).toFixed(2)
      r = r - (r * 0.25).toFixed(2)
    } else if (iconPos === 'right') {
      r = r - (r * 0.75).toFixed(2)
      l = l - (l * 0.4).toFixed(2)
    } else if (iconPos) {
      l = t
      r = t
      b = t
    }

    return [t, r, b, l]
  },
  bg: "#000000",
  color: "#FFFFFF",
  sm: {
    padding: [(verticalRhythm * .25).toFixed(2), 1.5],
    margin: [(verticalRhythm * .25).toFixed(2), 0],
    fontSize: (minimumFontSize * 0.8).toFixed(2),
    lineHeight: verticalRhythm
  },
  md: {
    padding: [(verticalRhythm * .5).toFixed(2), 3],
    margin: [(verticalRhythm * .5).toFixed(2), 0],
    fontSize: minimumFontSize,
    lineHeight: verticalRhythm
  },
  lg: {
    padding: [(verticalRhythm * .75).toFixed(2), 4],
    margin: [(verticalRhythm * .25).toFixed(2), 0],
    fontSize: (minimumFontSize * 1.25).toFixed(2),
    lineHeight: verticalRhythm
  },
}
