import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"
import Card from "./Card"
import Container from "components/Container"
import Content from "components/Content"
import LegalFooter from "components/LegalFooter"
import { htmlSerializer } from "components/HeroSection"
import { colorThemes } from "components/Paragraph"

const StyledCardRepeater = styled.section`
  padding: 0 2rem 4rem;
  position: relative;
  z-index: 200;

  > .content {
    max-width: 60rem;
    font-family: ${(props) => props.theme.fontStacks.light};

    h1 {
      ${(props) => props.theme.media.tablet_landscape_up`
        font-size: 4.2rem;
      `}
    }
  }

  .card-container {
    display: grid;
    grid-row-gap: 2rem;
    ${(props) => (props.columns === 2 ? `max-width: 100rem;` : ``)}

    margin: 0 auto;
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    .card-container {
      grid-column-gap: 2rem;
      grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    }
  `}

  ${(props) => props.theme.media.tablet_landscape_up`

    > .content {
      text-align: center;
      margin: 0 auto;
      max-width: 80rem;
    }

    .card-container {
      padding: 2rem 0;
      grid-template-columns: repeat(${props.columns}, 1fr);
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    padding: 2rem 0;
  `}
`

function CardRepeater({
  card_repeater_title,
  card_repeater_content,
  card_repeater_content_after,
  columns,
  items,
  children,
  ...props
}) {
  return (
    <Container css={colorThemes["White"]}>
      <StyledCardRepeater columns={columns} {...props}>
        <div className="content">
          <Content content={card_repeater_title.richText} />
          <Content content={card_repeater_content.richText} />
        </div>
        <div className="card-container">
          {items.map((item, i) => {
            return <Card key={`card_${i}`} data={item} columns={columns} />
          })}
        </div>
        <LegalFooter content={card_repeater_content_after} />
      </StyledCardRepeater>
    </Container>
  )
}

export default CardRepeater

CardRepeater.defaultProps = {}
CardRepeater.propTypes = {
  children: PropTypes.node,
}
