import React, { useMemo, useState } from "react"
import { animated, useSpring } from "react-spring"
import styled from "styled-components"

const StyledWrapper = styled.div`
  position: relative;

  .feature1 {
    position: absolute;
    height: auto;
    width: 16rem;
    top: 25%;
    right: -15%;
    opacity: 0;
    transform: translate3d(0, 10rem, 0);
    transition: cubic-bezier(0.03, 0.9, 0.41, 1) 400ms all;
    transition-delay: 250ms;

    &.ready {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .feature2 {
    position: absolute;
    height: auto;
    width: 18rem;
    top: 70%;
    right: -15%;
    opacity: 0;
    transform: translate3d(0, 10rem, 0);
    transition: cubic-bezier(0.03, 0.9, 0.41, 1) 400ms all;
    transition-delay: 500ms;

    &.ready {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .feature3 {
    position: absolute;
    height: auto;
    width: 20rem;
    top: 16%;
    left: -5%;
    opacity: 0;
    transform: translate3d(0, 10rem, 0);
    transition: cubic-bezier(0.03, 0.9, 0.41, 1) 400ms all;
    // transition-delay: 500ms;

    &.ready {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    .feature1 {
      width: 24rem;
      right: -20%;
      left: auto;
    }

    .feature2 {
      width: 30rem;
      top: 65%;
      right: -25%;
    }

    .feature3 {
      width: 30rem;
      left: -20%;
    }
  `}

  @media (min-width: 600px) and (orientation: landscape) {
    .feature1 {
      width: 24rem;
      right: -50%;
      left: auto;
    }

    .feature2 {
      width: 30rem;
      right: -40%;
    }

    .feature3 {
      width: 30rem;
      left: -20%;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    .feature1 {
      width: 18rem;
      top: 25%;
      right: -40%;
      left: auto;
    }

    .feature2 {
      width: 24rem;
      top: 65%;
      right: -50%;
      left: auto;

    }

    .feature3 {
      width: 24rem;
      top: 20%;
      left: -25%;
      right: auto;
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    .feature1 {
      width: 24rem;
      top: 25%;
      right: -70%;
      left: auto;
    }

    .feature2 {
      width: 40rem;
      top: 60%;
      left: -80%;
      right: auto;

    }

    .feature3 {
      width: 32rem;
      top: 20%;
      left: -40%;
      right: auto;
    }
  `}

  ${(props) => props.theme.media.big_desktop_up`
    .feature1 {
      width: 30rem;
      top: 25%;
      right: -70%;
      left: auto;
    }

    .feature2 {
      width: 40rem;
      top: 60%;
      left: -80%;
      right: auto;

    }

    .feature3 {
      width: 32rem;
      top: 20%;
      left: -40%;
      right: auto;
    }
  `}
`

export const Phone: React.FC = ({ ...props }) => {
  const [animationCompleted, setAnimationCompleted] = useState(false)

  const mainStyleProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    onRest: () => setAnimationCompleted(true),
  })

  return (
    <StyledWrapper>
      <animated.img
        src="/devices/iphone.svg"
        style={mainStyleProps}
        {...props}
      />
      <img
        className={`feature1${animationCompleted ? " ready" : ""}`}
        src="/illustrations/service.png"
      />
      <img
        className={`feature2${animationCompleted ? " ready" : ""}`}
        src="/illustrations/alarm-udloest.png"
      />
      <img
        className={`feature3${animationCompleted ? " ready" : ""}`}
        src="/illustrations/find-your-way.png"
      />
    </StyledWrapper>
  )
}
