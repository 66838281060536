import React from "react"
import useViewport, { ViewportEnum } from "hooks/useViewport"

interface IResponsiveProps {
  phone: React.ReactElement | false
  tabletPortrait?: React.ReactElement | false
  tabletLandscape?: React.ReactElement | false
  desktop?: React.ReactElement | false
  largeDesktop?: React.ReactElement | false
  debug?: boolean
}

const Responsive: React.FC<IResponsiveProps> = ({
  phone,
  tabletPortrait,
  tabletLandscape,
  desktop,
  largeDesktop,
  ...rest
}) => {
  const viewport = useViewport()

  function renderComponent(vp: ViewportEnum) {
    if (vp === ViewportEnum.Phone) {
      if (phone === false) return null
      if (phone) {
        return <phone.type {...phone.props} {...rest} />
      } else return null
    } else if (vp === ViewportEnum.TabletPortrait) {
      if (tabletPortrait === false) return null
      if (tabletPortrait) {
        return <tabletPortrait.type {...tabletPortrait.props} {...rest} />
      } else if (phone) {
        return <phone.type {...phone.props} {...rest} />
      } else return null
    } else if (vp === ViewportEnum.TabletLandscape) {
      if (tabletLandscape === false) return null
      if (tabletLandscape) {
        return <tabletLandscape.type {...tabletLandscape.props} {...rest} />
      } else if (tabletPortrait) {
        return <tabletPortrait.type {...tabletPortrait.props} {...rest} />
      } else if (phone) {
        return <phone.type {...phone.props} {...rest} />
      } else return null
    } else if (vp === ViewportEnum.Desktop) {
      if (tabletLandscape === false || desktop === false) return null
      if (desktop) {
        return <desktop.type {...desktop.props} {...rest} />
      } else if (tabletLandscape) {
        return <tabletLandscape.type {...tabletLandscape.props} {...rest} />
      } else if (tabletPortrait) {
        return <tabletPortrait.type {...tabletPortrait.props} {...rest} />
      } else if (phone) {
        return <phone.type {...phone.props} {...rest} />
      } else return null
    } else if (vp === ViewportEnum.LargeDesktop) {
      if (
        tabletLandscape === false &&
        desktop === false &&
        largeDesktop === false
      )
        return null
      if (largeDesktop) {
        return <largeDesktop.type {...largeDesktop.props} {...rest} />
      } else if (desktop) {
        return <desktop.type {...desktop.props} {...rest} />
      } else if (tabletLandscape) {
        return <tabletLandscape.type {...tabletLandscape.props} {...rest} />
      } else if (tabletPortrait) {
        return <tabletPortrait.type {...tabletPortrait.props} {...rest} />
      } else if (phone) {
        return <phone.type {...phone.props} {...rest} />
      } else return null
    }

    return null
  }

  return renderComponent(viewport)
}

export { Responsive }
