import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"
import ErrorMessage from "../ErrorMessage"

const StyledFormLabel = styled.label`
  transition: all ease-out 0.1s;
  white-space: nowrap;
  ${tw`text-lg`}
  ${(props) => {
    if (typeof props.error === "boolean") {
      return props.error
        ? `color: ${props.theme.colors.danger};`
        : `color: currentColor;`
    }
    return `color: currentColor;`
  }}
`

function FormLabel({ error, errorMessage, children, ...props }) {
  return (
    <StyledFormLabel error={error} {...props}>
      {children}
      <ErrorMessage show={error}>{` - ${errorMessage}`}</ErrorMessage>
    </StyledFormLabel>
  )
}

export default FormLabel

FormLabel.defaultProps = {
  error: null,
  errorMessage: null,
  children: null,
}

FormLabel.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
}
