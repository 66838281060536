export const baseFontSize = 1 // 1rem = 10px
export const minimumFontSize = 1.6 // 1.6rem = 16px
export const verticalRhythm = 2 // 2rem = 2px
export const lineHeight = 1.5

/* Headings scale */
export const responsiveFontScale = 1.250

export const fontSizes = { // in rems
  p: 1.6,
  h1: 4.8,
  h2: 3.6,
  h3: 2.8,
  h4: 2.2,
  h5: 1.8,
  h6: 1.6,
}

export const paragraphRhythm = 'margin' // Applies to <p>-elements preceeded by another p-element. Can be 'indent', 'margin' or 'none'.
