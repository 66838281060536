import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledErrorMessage = styled.span`
  display: inline-block;
  padding-left: 0.25em;
  opacity: ${props => (props.show ? 1 : 0)};
  transform: ${props => (props.show ? `translateY(0)` : `translateY(-100%)`)};
  transition: all ease-out 0.1s;
`;

function ErrorMessage({ children, ...props }) {
  return <StyledErrorMessage {...props}>{children}</StyledErrorMessage>;
}

export default ErrorMessage;

ErrorMessage.defaultProps = {};
ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired
};
