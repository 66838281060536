import React, { useReducer, createContext } from "react"
import { updateCollectionObjectByItemId } from "utils/collection"
import {
  FormActionMap,
  FormTypes,
  FormValidationType,
  FormInputErrorType,
} from "./actions"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

type FormFieldValidationModel = {
  id: string
  error: FormInputErrorType
  errorMessage: string
}

type InitialFormStateType = {
  fields: FormFieldValidationModel[]
}

const initialState: InitialFormStateType = {
  fields: [],
}

const FormContext = createContext<{
  state: InitialFormStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

const addField = (state, action: FormValidationType) => {
  const newFields = state.fields.slice()
  return [
    ...newFields,
    {
      id: action.fieldId,
      error: action.error,
    },
  ]
}

const removeField = (state, action: FormValidationType) => {
  const newFields = state.fields.slice()
  return newFields.filter((field) => field.id !== action.fieldId)
}

const updateField = (state, action: FormValidationType) => {
  const newFields = updateCollectionObjectByItemId(
    state.fields,
    action.fieldId,
    action.error
  )
  return newFields
}

const formReducer = (state: InitialFormStateType, action: FormActionMap) => {
  switch (action.type) {
    case FormTypes.AddFieldError:
      return {
        ...state,
        fields: addField(state, action.payload),
      }
    case FormTypes.UpdateFieldError:
      return {
        ...state,
        fields: updateField(state, action.payload),
      }
    case FormTypes.RemoveFieldError:
      return {
        ...state,
        fields: removeField(state, action.payload),
      }
    case FormTypes.ResetFields:
      return initialState
    default:
      return state
  }
}

const FormProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialState)

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  )
}

export { FormContext, FormProvider }
