

const mapPersonCTAs = (person, ctas) => {
  const returnValue = ctas.map((cta, index) => {
    switch (cta.person_cta_action) {
      case "Email":
        if (person && person.email) {
          return {
            primary: index === 0,
            value: `mailto:${person.email}`,
            text: cta.person_cta_link_text || person.email,
          }
        }
        return null
      case "Phone":
        if (person && person.phone) {
          return {
            primary: index === 0,
            value: `tel:${person.phone}`,
            text: cta.person_cta_link_text || person.phone,
          }
        }
        return null
      default:
        return null
    }
  }).filter(Boolean)
  return returnValue
}


export { mapPersonCTAs }
