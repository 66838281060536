import React from "react"
import PropTypes from "prop-types"
import BlogContent from "components/Blog/Post/BlogContent"
import Testimonial from "components/Testimonial"
import { BlogPostGrid } from "./Previews/Grid"

function SliceZone({ ...props }) {
  const { slices, pageInfo } = props
  if (!slices || slices === null) return null
  const slice = slices.map((s, index) => {
    switch (s.slice_type) {
      case "content":
        return (
          <BlogContent
            key={`${s.slice_type}_${index}`}
            content={s.primary}
            items={s.fields}
          />
        )
      case "testimonial":
        return (
          <Testimonial
            key={`${s.slice_type}_${index}`}
            content={s.primary}
            items={s.fields}
          />
        )
      case "blogposts":
        return (
          <BlogPostGrid
            key={`${s.slice_type}_${index}`}
            content={s.primary}
            items={s.items}
          />
        )
      default:
        console.warn(`No support for slice type ${s.slice_type}`)
        return null
    }
  })
  return <>{slice}</>
}

SliceZone.propTypes = {
  slices: PropTypes.array.isRequired,
}

export default SliceZone
