import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

const Twitter = ({ type, username, title, desc, image, siteUrl }) => {
  return (
    <Helmet>
      {username && <meta name="twitter:creator" content={username} />}
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      {image && image.image && (
        <meta name="twitter:image" content={`${image?.image.url}`} />
      )}
      {image && image.image && (
        <meta name="twitter:image:alt" content={image?.image.alt} />
      )}
    </Helmet>
  )
}

export default Twitter

Twitter.propTypes = {
  type: PropTypes.string,
  username: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  siteUrl: PropTypes.string.isRequired,
  image: PropTypes.string,
}

Twitter.defaultProps = {
  type: "summary_large_image",
  username: null,
}
