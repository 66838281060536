import React from "react"
import styled from "styled-components"
import { Elements } from "prismic-reactjs"
import { graphql } from "gatsby"
import Content from "components/Content"
import tw from "twin.macro"
import ResponsiveImage from "components/ResponsiveImage"
import Container from "components/Container"
import { htmlSerializer } from "components/HeroSection"
import { colorThemes } from "theme/colors"

const StyledWrapper = styled.div`
  ${tw`my-12 `}
`

const StyledQuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${tw`md:pl-0 lg:pl-8`}

  h2 {
    ${tw`md:(mt-4 mb-0)`}
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    flex-direction: row;
    align-items: center;
  `}
`

const StyledQuote = styled.blockquote`
  font-family: ${(props) => props.theme.fontStacks.light};
  font-size: 1.5rem;
  line-height: 1.6;
  position: relative;
  ${tw`italic mr-0 mt-8 mb-6 ml-0 block lg:(my-10 mr-4)`}

  p {
    ${tw`m-0`}
  }

  p + p {
    ${tw`mt-6 md:mt-4 lg:mt-6`}
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    font-size: 1.4rem;
  `}

  ${(props) => props.theme.media.desktop_up`
    font-size: 1.6rem;
  `}
`

const StyledCite = styled.div`
  margin-right: auto;
  position: relative;
  z-index: 50;
  display: grid;
  grid-template-columns: 6rem auto;
  align-items: center;
  column-gap: 2rem;

  .person {
  }

  p {
    ${tw`m-0`}
  }

  a {
    ${tw`text-brand-500`}
  }
`

const StyledTestimonialMedia = styled.div`
  position: relative;
  ${(props) => props.theme.media.tablet_landscape_up`
    flex: 1 1 50%;
  `}
`

const StyledTestimonialContent = styled.div`
  ${tw`px-8 py-8 md:(px-0 pl-4 pr-16) relative`}
  ${(props) => props.theme.media.tablet_landscape_up`
    flex: 1 1 50%;
  `}

  ${(props) => props.theme.media.desktop_up`
  `}

  h2 {
    ${tw`text-5xl w-4/5`}
  }

  a {
    ${tw`text-brand-500`}
  }

  .cite {
    position: absolute;
    font-size: 80rem;
    top: 60%;
    text-align: left;
    right: 10%;
    left: auto;
    line-height: 0;
    opacity: 0.08;
  }
`

const StyledEyebrow = styled.span`
  font-family: ${(props) => props.theme.fontStacks.bold};
  ${tw`text-lg bg-brand-500 py-1 px-2 md:(text-xl) text-white`}
`

const quoteSerializer = (type, element, content, children, key) => {
  switch (type) {
    case Elements.paragraph:
      return <p>{children}</p>
    default:
      return null
  }
}

const citeSerializer = (type, element, content, children, key) => {
  switch (type) {
    case Elements.paragraph:
      return <span>{children}</span>
    default:
      return null
  }
}

function PageTestimonial({ content, ...props }) {
  const {
    quote,
    name,
    company_name,
    role_position,
    profile_pic,
    client_logo,
    eyebrow_headline,
    title,
    theme,
  } = content
  return (
    <Container>
      <StyledWrapper css={colorThemes[theme]}>
        <StyledQuoteWrapper>
          <StyledTestimonialContent>
            <span className="cite">”</span>
            {eyebrow_headline.text !== "" && (
              <StyledEyebrow>{eyebrow_headline.text}</StyledEyebrow>
            )}
            <Content
              content={title.richText}
              serializer={htmlSerializer}
              {...props}
            />
            <StyledQuote>
              <Content
                content={quote.richText}
                serializer={quoteSerializer}
                {...props}
              />
            </StyledQuote>

            <StyledCite>
              <span tw="w-24 h-24 bg-white flex place-content-center shadow-card rounded-full">
                <ResponsiveImage
                  tw="w-20 h-20 my-auto object-contain"
                  img={client_logo}
                />
              </span>
              <div className="person" tw="flex flex-col text-left">
                <span tw="flex items-center font-bold">
                  <Content
                    content={name.richText}
                    htmlSerializer={citeSerializer}
                    {...props}
                  />
                </span>
                <span tw="text-xl space-x-px">
                  <Content
                    content={role_position.richText}
                    htmlSerializer={citeSerializer}
                    {...props}
                  />
                  {", "}
                  <Content
                    content={company_name.richText}
                    htmlSerializer={citeSerializer}
                    {...props}
                  />
                </span>
              </div>
            </StyledCite>
          </StyledTestimonialContent>

          <StyledTestimonialMedia>
            <ResponsiveImage img={profile_pic} />
          </StyledTestimonialMedia>
        </StyledQuoteWrapper>
      </StyledWrapper>
    </Container>
  )
}

export { PageTestimonial }

export const query = graphql`
  fragment TestimonialPageFragment on PrismicPageDataBodyTestimonial {
    slice_type
    id
    primary {
      theme
      title {
        richText
      }
      role_position {
        richText
      }
      quote {
        richText
      }
      name {
        richText
      }
      eyebrow_headline {
        text
      }
      description {
        richText
      }
      company_name {
        richText
      }
      client_logo {
        url
      }
      profile_pic {
        url
      }
    }
  }
`
