import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import Seo from "components/SEO"
import SliceZone from "components/SliceZone"
import { linkResolver } from "utils/linkResolver"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

function Home({ data, ...props }) {
  if (!data) return null
  const { site, prismicSiteSettings, prismicIndex: index } = data
  const { data: site_settings } = prismicSiteSettings
  const { body } = index.data
  const { meta_title, meta_description, meta_images } = index.data
  const { siteUrl } = site.siteMetadata
  const uri = linkResolver(index)

  return (
    <>
      <Seo
        lang={index.lang}
        slug={uri}
        title={meta_title}
        description={meta_description}
        images={meta_images}
      />
      <Layout site_settings={site_settings} lang={index.lang}>
        <SliceZone
          slices={body}
          pageInfo={{ uri, host: siteUrl, title: index.data.title.text }}
          isHome
        />
      </Layout>
    </>
  )
}

export { Home }
export default withPrismicPreview(Home)

export const query = graphql`
  # query PrismicIndex($lang: String, $uid: String!, $isProduction: Boolean!) {
  query PrismicIndex($lang: String, $uid: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicSiteSettings {
      _previewable
      ...SiteNavigation
      ...FooterInformation
      ...FooterNavigation
      ...SocialMediaLinks
      ...UtilityNavigation
    }
    prismicIndex(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      type
      lang
      uid
      data {
        title {
          richText
          text
        }
        meta_title
        meta_description
        meta_images {
          image {
            dimensions {
              width
              height
            }
            alt
            copyright
            url
            localFile {
              childImageSharp {
                id
                main: fixed(width: 1200) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        show_in_sitemap
        body {
          ... on PrismicIndexDataBodyParagraph {
            slice_type
            primary {
              paragraph_title {
                richText
              }
              paragraph_content {
                richText
              }
            }
          }
          ... on PrismicIndexDataBodyBlogposts {
            ...IndexRelatedBlogposts
          }
          ... on PrismicIndexDataBodyProductPresentation {
            ...ProductPresentationFragment
          }
          ... on PrismicIndexDataBodyParagraphImage {
            slice_type
            primary {
              theme
              image_position
              content {
                richText
              }
              bullet_color
              bullet_background_color
              bullet_icon_alt {
                url
              }
              paragraph_image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    id
                    desktop: gatsbyImageData(layout: FIXED, width: 600)
                    mobile: gatsbyImageData(layout: FIXED, width: 400)
                    wide: gatsbyImageData(
                      layout: FIXED
                      width: 1920
                      height: 1200
                    )
                    square: gatsbyImageData(
                      layout: FIXED
                      width: 960
                      height: 960
                    )
                  }
                }
              }
            }
            items {
              cta_link {
                link_type
                document {
                  ... on PrismicLeadModule {
                    id
                    type
                    uid
                    lang
                  }
                  ... on PrismicPage {
                    id
                    type
                    uid
                    lang
                  }
                }
                url
              }
              cta_link_text
            }
          }
          ... on PrismicIndexDataBodyIllustratedUsp {
            slice_type
            primary {
              svg_graphics
              illustration_position
              illustration {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    id
                    desktop: gatsbyImageData(layout: FIXED, width: 600)
                    mobile: gatsbyImageData(layout: FIXED, width: 400)
                    wide: gatsbyImageData(
                      layout: FIXED
                      width: 1920
                      height: 1200
                    )
                    square: gatsbyImageData(
                      layout: FIXED
                      width: 960
                      height: 960
                    )
                  }
                }
              }
              usp {
                document {
                  ... on PrismicUsp {
                    ...USPFragment
                  }
                }
              }
            }
            items {
              cta_link {
                link_type
                document {
                  ... on PrismicLeadModule {
                    id
                    type
                    uid
                    lang
                  }
                  ... on PrismicPage {
                    id
                    uid
                    type
                    lang
                  }
                }
                url
              }
              cta_link_text
            }
          }
          ... on PrismicIndexDataBodyUspRepeater {
            slice_type
            primary {
              columns
              use_cards
            }
            items {
              usp {
                document {
                  ... on PrismicUsp {
                    ...USPFragment
                  }
                }
              }
              usp_icon {
                url
              }
            }
          }
          ... on PrismicIndexDataBodyFrontpageHero {
            slice_type
            primary {
              content {
                richText
              }
              headline {
                richText
              }
              desktop_image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, webpQuality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              mobile_image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    fluid(webpQuality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            items {
              cta_link {
                link_type
                document {
                  ... on PrismicLeadModule {
                    id
                    type
                    uid
                    lang
                  }
                  ... on PrismicPage {
                    id
                    uid
                    type
                    lang
                  }
                }
                url
              }
              cta_link_text
            }
          }
          ... on PrismicIndexDataBodyHeroSection {
            slice_type
            primary {
              pre_headline
              headline {
                richText
              }
              content {
                richText
              }
              image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    id
                    desktop: gatsbyImageData(
                      layout: FIXED
                      width: 960
                      formats: [AUTO, WEBP]
                    )
                    mobile: gatsbyImageData(
                      layout: FIXED
                      width: 480
                      formats: [AUTO, WEBP]
                    )
                    wide: gatsbyImageData(
                      layout: FIXED
                      width: 1920
                      height: 1200
                    )
                    square: gatsbyImageData(
                      layout: FIXED
                      width: 960
                      height: 960
                    )
                  }
                }
              }
            }
            items {
              cta_link {
                link_type
                document {
                  ... on PrismicLeadModule {
                    id
                    type
                    uid
                    lang
                  }
                  ... on PrismicPage {
                    id
                    uid
                    type
                    lang
                  }
                }
                url
              }
              cta_link_text
            }
          }
          ... on PrismicIndexDataBodyLeadCta {
            slice_type
            primary {
              lead_title {
                richText
              }
              lead_content {
                richText
              }
              lead_module {
                document {
                  ... on PrismicLeadModule {
                    ...LeadModule
                  }
                }
              }
              person {
                document {
                  ... on PrismicPerson {
                    ...PersonFragment
                  }
                }
              }
            }
          }
          ... on PrismicIndexDataBodyFeatureRepeater {
            slice_type
            primary {
              features_content {
                richText
              }
              features_title {
                richText
              }
            }
            items {
              feature_description {
                richText
              }
              feature_icon {
                url
              }
            }
          }
          ... on PrismicIndexDataBodyPersonCta {
            slice_type
            primary {
              person {
                document {
                  ... on PrismicPerson {
                    ...PersonFragment
                  }
                }
              }
              person_cta_content {
                richText
              }
              person_cta_title {
                richText
              }
            }
            items {
              person_cta_action
              person_cta_link_text
            }
          }
          ... on PrismicIndexDataBodyPersonRepeater {
            slice_type
            primary {
              persons_content {
                richText
              }
              persons_title {
                richText
              }
            }
            items {
              person {
                document {
                  ... on PrismicPerson {
                    ...PersonFragment
                  }
                }
              }
            }
          }
          ... on PrismicIndexDataBodyParagraph {
            slice_type
            primary {
              paragraph_title {
                richText
              }
              paragraph_content {
                richText
              }
            }
          }
          ... on PrismicIndexDataBodyImageRepeater {
            slice_type
            primary {
              images_content {
                richText
              }
              images_title {
                richText
              }
            }
            items {
              image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    id
                    desktop: gatsbyImageData(
                      layout: FIXED
                      width: 300
                      formats: [WEBP]
                    )
                    mobile: gatsbyImageData(
                      layout: FIXED
                      width: 300
                      formats: [WEBP]
                    )
                    wide: gatsbyImageData(
                      layout: FIXED
                      width: 1920
                      height: 1200
                    )
                    square: gatsbyImageData(
                      layout: FIXED
                      width: 960
                      height: 960
                    )
                  }
                }
              }
              description {
                richText
              }
            }
          }
          ... on PrismicIndexDataBodyCardRepeater {
            slice_type
            primary {
              columns
              card_repeater_title {
                richText
              }
              card_repeater_content_after {
                richText
                text
              }
              card_repeater_content {
                richText
              }
            }
            items {
              content {
                document {
                  ... on PrismicPage {
                    data {
                      title {
                        richText
                        text
                      }
                    }
                    uid
                    lang
                    id
                    type
                  }
                  ... on PrismicProduct {
                    ...ProductFragment
                  }
                }
              }
              cta_text
              emphasize: emphasize_
              display_style
              background_image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                thumbnails {
                  mobile {
                    url
                  }
                  Horizontal {
                    url
                  }
                }
                localFile {
                  childImageSharp {
                    id
                    desktop: fixed(width: 300) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                    mobile: fixed(width: 300) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                    horizontal: fixed(width: 1920) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                    wide: gatsbyImageData(
                      layout: FIXED
                      width: 1920
                      height: 1200
                    )
                    square: gatsbyImageData(
                      layout: FIXED
                      width: 960
                      height: 960
                    )
                    # desktop: gatsbyImageData(
                    #   layout: FIXED
                    #   width: 300
                    #   formats: [WEBP]
                    # )
                    # mobile: gatsbyImageData(
                    #   layout: FIXED
                    #   width: 300
                    #   formats: [WEBP]
                    # )
                    # horizontal: gatsbyImageData(
                    #   layout: FIXED
                    #   width: 1920
                    #   formats: [WEBP]
                    # )
                  }
                }
              }
            }
          }
          ... on PrismicIndexDataBodyContentImage {
            slice_type
            primary {
              content {
                richText
              }
              image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    # fluid(quality: 90) {
                    #   ...GatsbyImageSharpFluid_withWebp
                    # }
                    fluid(quality: 90) {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on PrismicIndexDataBodyClientQuotations {
            id
            slice_type
            primary {
              headline: heading {
                richText
              }
            }
            items {
              client_quotation {
                document {
                  ... on PrismicClientQuotation {
                    ...ClientQuotationFragment
                  }
                }
              }
            }
          }
          ... on PrismicIndexDataBodyDevices {
            slice_type
            primary {
              content {
                richText
              }
              desktop_image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    desktop: fixed(quality: 100, height: 512) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                    mobile: fixed(quality: 90, height: 272) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                    wide: gatsbyImageData(
                      layout: FIXED
                      width: 1920
                      height: 1200
                    )
                    square: gatsbyImageData(
                      layout: FIXED
                      width: 960
                      height: 960
                    )
                    # desktop: gatsbyImageData(
                    #   layout: FIXED
                    #   height: 512
                    #   formats: [WEBP]
                    #   quality: 100
                    # )
                    # mobile: gatsbyImageData(
                    #   layout: FIXED
                    #   height: 272
                    #   formats: [WEBP]
                    #   quality: 90
                    # )
                  }
                }
              }
              tablet_image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    desktop: fixed(quality: 100, height: 412) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                    mobile: fixed(quality: 90, height: 172) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              mobile_image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    desktop: fixed(quality: 100, height: 312) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                    mobile: fixed(quality: 90, height: 276) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicIndexDataBodyPartnerRepeater {
            slice_type
            primary {
              partners_title {
                richText
                text
              }
              content {
                richText
              }
              shuffle_text
            }
            items {
              partner_name
              partner_link {
                url
                target
                link_type
              }
              partner_logo_image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    desktop: gatsbyImageData(
                      layout: FIXED
                      width: 412
                      formats: [WEBP]
                    )
                    mobile: gatsbyImageData(
                      layout: FIXED
                      width: 270
                      formats: [WEBP]
                    )
                  }
                }
              }
            }
          }
          ... on PrismicIndexDataBodyPageShortcuts {
            id
            slice_type
            primary {
              headline {
                richText
              }
              content {
                richText
              }
            }
            items {
              link_text
              link {
                document {
                  ... on PrismicPage {
                    url
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
              link_image {
                dimensions {
                  width
                  height
                }
                alt
                copyright
                url(imgixParams: { width: 200 })
              }
            }
          }
        }
      }
    }
  }
`
