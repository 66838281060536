import React, { useRef, useLayoutEffect } from "react"
import styled from "styled-components"
import { useOnScreen } from "hooks/useOnScreen"

const StyledTextarea = styled.textarea`
  padding: 0 1rem 1rem;
  margin-top: 3rem;
  width: 100%;
  border: none;
  box-shadow: none;
  transition: all ease-out 0.15s;
  height: 100%;
  max-height: calc(${`${17 * 6}px`} + 6rem);
`

function Textarea({ forwardRef, onChange, ...props }) {
  const ref = useRef<HTMLTextAreaElement>(forwardRef ?? null)
  const isOnScreen = useOnScreen(ref)

  const onChangeHandler = (e) => {
    if (onChange) onChange(e)
    if (ref.current) onHeightHandler()
  }

  const onHeightHandler = () => {
    if (ref.current) {
      ref.current.style.height =
        ref.current.value === "" ? "51px" : ref.current.scrollHeight + "px"
    }
  }

  useLayoutEffect(() => {
    if (ref.current) onHeightHandler()
  }, [ref.current, props.value])

  return (
    <StyledTextarea
      style={{ resize: "vertical" }}
      ref={ref}
      tabIndex={!isOnScreen ? -1 : 0}
      onChange={onChangeHandler}
      {...props}
    />
  )
}

export { Textarea }

/* Textarea.defaultProps = {
  focusShadow: true,
}
Textarea.propTypes = {
  focusShadow: PropTypes.bool,
}
 */
